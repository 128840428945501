<div class="loader">
  <img src="/assets/images/loader/loader.gif?v=2" class="logo-loader" *ngIf="!bgColor"  alt="Logo chargement">
  <img src="/assets/images/loader/loader-beige.gif?v=2" class="logo-loader" *ngIf="bgColor"  alt="Logo chargement">
  <img src="/assets/images/loader/arrow_loader.svg?v=2" class="arrow-loader" alt="Flêche">
  <p class="text-loader">
    oui, on est en train<br/>
    de charger…
  </p>

</div>
