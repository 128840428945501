import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Page} from 'src/app/core/model/page.model';
import {createRequestOption} from '../util/request-util';
import {Address, IAddress} from '../../../core/model/address/address';
import {PatchAddress} from 'src/app/core/model/address/patch-address.model';
import {CreateAddress} from '../../../core/model/address/create-address.model';

@Injectable({providedIn: 'root'})
export class AddressService {
    public resourceUrl = environment.SERVER_API_URL + 'api/users';

    constructor(private http: HttpClient) {
    }

    query(req: any): Observable<Page<Address>> {
        const options = createRequestOption(req);
        return this.http.get<Page<Address>>(`${this.resourceUrl}/${req.userId}/addresss/`, {params: options});
    }

    create(userId: string, createAddress: CreateAddress): Observable<IAddress> {
        return this.http.post<IAddress>(`${this.resourceUrl}/${userId}/addresss/`, createAddress);
    }

    find(userId: string, id: string | number): Observable<IAddress> {
        return this.http.get<IAddress>(`${this.resourceUrl}/${userId}/addresss//${id}`);
    }

    patch(userId: string, patchAddress: PatchAddress): Observable<Address> {
        return this.http.patch<Address>(`${this.resourceUrl}/${userId}/addresss/${patchAddress.id}`, patchAddress);
    }

    delete(userId: string, addressId: string | number): Observable<Address> {
        return this.http.delete<Address>(`${this.resourceUrl}/${userId}/addresss/${addressId}`);
    }
}
