import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AppState} from '../../../core/store/app.state';
import {Store} from '@ngrx/store';
import {getVisibilityBgFooter, getVisibilityStreamerRegistration} from '../../../core/store/footer/footer.selectors';
import {setVisibilityOpinion, setVisibilityStreamerRegistration} from '../../../core/store/footer/footer.actions';

@Component({
  selector: 'app-footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  showBg: Observable<boolean>;
  showStreamer: Observable<boolean>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.showBg = this.store.select(getVisibilityBgFooter);
    this.showStreamer = this.store.select(getVisibilityStreamerRegistration);
  }

  getCurrentYear(): number {
    return (new Date()).getFullYear();
  }

  closeStreamer(): void {
    this.store.dispatch(setVisibilityOpinion({visibility: true}));
    this.store.dispatch(setVisibilityStreamerRegistration({visibility: false}));
  }
}
