import {NgModule} from '@angular/core';
import {NavbarUserComponent} from './navbar-user.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared.module';
import {ListSelectCategoryModule} from '../../category/list-select-category/list-select-category.module';
import {DropdownModule} from '../../../../core/directive/dropdown/dropdown.module';
import {ModalModule} from '../../modal/modal.module';

@NgModule({
  declarations: [
    NavbarUserComponent
  ],
    imports: [
        SharedModule,
        RouterModule,
        ListSelectCategoryModule,
        DropdownModule,
        ModalModule
    ],
  exports: [
    NavbarUserComponent
  ],
  providers: [],
})
export class NavbarUserModule {
}
