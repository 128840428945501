import {Component, Input} from '@angular/core';
import {User} from '../../../../core/model/user/user.model';
import {AuthService} from '../../../services/auth/auth.service';
import {StateStorageService} from '../../../services/auth/state-storage.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-nav-bar-user-component',
  templateUrl: './navbar-user.component.html',
  styleUrls: ['./navbar-user.component.scss'],
})
export class NavbarUserComponent {
  @Input() user: User;

  constructor(protected authService: AuthService,
              private stateStorageService: StateStorageService,
              protected router: Router) {
  }

  saveUrl(): void {
    this.stateStorageService.storeUrl(this.router.url);
  }

  signOut(): void {
    this.authService.signOut();
  }
}
