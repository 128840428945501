import {DomainState, selectAll} from './domain.reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const domainFeatureSelector = createFeatureSelector<DomainState>('domains');

export const getAllDomains = createSelector(
  domainFeatureSelector,
  selectAll
);

export const getCurrentDomain = createSelector(
  domainFeatureSelector,
  state => state.domain
);
