import {createAction, props} from '@ngrx/store';
import {MeansPayment} from '../../model/means-payment/means-payment';
import {FilterMeansPayment} from '../../model/means-payment/filter-means-payment.model';
import {Page} from '../../model/page.model';
import {CreateMeansPayment} from '../../model/means-payment/create-means-payment.model';
import {PatchMeansPayment} from '../../model/means-payment/patch-means-payment.model';


export const loadListMeansPayment = createAction(
  '[MeansPayment Effect] Load MeansPayments via Service',
  props<{ filters: FilterMeansPayment }>()
);

export const meansPaymentListLoadedDone = createAction(
  '[MeansPayment Effect] MeansPayments Loaded Successfully',
  props<{ meansPayments: Page<MeansPayment> }>()
);

export const meansPaymentListLoadedFail = createAction(
  '[MeansPayment Effect] MeansPayments Loaded failed',
  props<{ message: string }>()
);

export const loadOneMeansPayment = createAction(
  '[MeansPayments List] Load MeansPayment via Service',
  props<{ id: string | number, userId: string }>()
);

export const meansPaymentOneLoadedDone = createAction(
  '[MeansPayment Effect] MeansPayment Loaded Successfully',
  props<{ meansPayment: MeansPayment }>()
);

export const meansPaymentOneLoadedFail = createAction(
  '[MeansPayment Effect] MeansPayment Loaded Fail',
  props<{ message: string }>()
);

export const createMeansPayment = createAction(
  '[MeansPayment Effect] Create MeansPayment',
  props<{ createMeansPayment: CreateMeansPayment, userId: string }>()
);

export const meansPaymentCreatedDone = createAction(
  '[MeansPayment Effect] MeansPayment Created Successfully',
  props<{ meansPayment: MeansPayment }>()
);

export const meansPaymentCreatedFail = createAction(
  '[MeansPayment Effect] MeansPayment Created failed',
  props<{ message: string }>()
);

export const patchMeansPayment = createAction(
  '[MeansPayment Effect] Update MeansPayment',
  props<{ patchMeansPayment: PatchMeansPayment, userId: string }>()
);

export const meansPaymentPatchDone = createAction(
  '[MeansPayment Effect] MeansPayment Updated Successfully',
  props<{ meansPayment: MeansPayment }>()
);

export const meansPaymentPatchFail = createAction(
  '[MeansPayment Effect] MeansPayment Updated fail',
  props<{ message: string }>()
);


export const deleteMeansPayment = createAction(
  '[MeansPayment Effect] Delete MeansPayment',
  props<{ id: string | number, userId: string }>()
);

export const meansPaymentDeletedDone = createAction(
  '[MeansPayment Effect] MeansPayment Deleted Successfully',
  props<{ meansPayment: MeansPayment }>()
);

export const meansPaymentDeletedFail = createAction(
  '[MeansPayment Effect] MeansPayment Deleted fail',
  props<{ message: string }>()
);

export const clearMeansPayment = createAction(
  '[MeansPayment Effect] MeansPayment clear all'
);


export const meansPaymentActionTypes = {
  loadListMeansPayment,
  meansPaymentListLoadedDone,
  meansPaymentListLoadedFail,
  loadOneMeansPayment,
  meansPaymentOneLoadedDone,
  meansPaymentOneLoadedFail,
  createMeansPayment,
  meansPaymentCreatedDone,
  meansPaymentCreatedFail,
  patchMeansPayment,
  meansPaymentPatchDone,
  meansPaymentPatchFail,
  deleteMeansPayment,
  meansPaymentDeletedDone,
  meansPaymentDeletedFail,
  clearMeansPayment,
};
