import {DateAgoPipe} from './date-ago.pipe';
import {NgModule} from '@angular/core';

@NgModule({
  declarations: [
    DateAgoPipe
  ],
  exports: [
    DateAgoPipe
  ]
})
export class DateAgoModule {
}
