import {ingredientActionTypes} from './ingredient.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Ingredient} from '../../model/ingredient/ingredient.model';
import {IngredientService} from '../../../shared/services/ingredient.service';
import {TransferState} from '@angular/platform-browser';
import {STATE_LIST_BLOGS, STATE_ONE_BLOG} from '../../transfert-state/state';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';
import {getErrorRequest} from '../../../shared/services/util/request-util';

@Injectable()
export class IngredientEffects {

  loadIngredients$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(ingredientActionTypes.loadIngredients),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_LIST_BLOGS, this.ingredientService.query(action.filters))),
        map(ingredients => ingredientActionTypes.loadIngredientsDone({ingredients})),
        catchError((err, caught) => {
          this.store.dispatch(ingredientActionTypes.loadIngredientsFail());
          return caught;
        })
      );
    }
  );

  loadIngredient$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(ingredientActionTypes.loadIngredient),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_ONE_BLOG, this.ingredientService.find(action.metaTitle))),
        map(ingredient => ingredientActionTypes.loadIngredientDone({ingredient})),
        catchError((err, caught) => {
          this.store.dispatch(ingredientActionTypes.loadIngredientFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  constructor(
    private ingredientService: IngredientService,
    private actions$: Actions,
    private router: Router,
    private state: TransferState,
    private utilsTransfertsState: UtilsTransfertsState,
    private store: Store<Ingredient>) {
  }
}
