import {AlgoliaState} from './algolia.reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const algoliaFeatureSelector = createFeatureSelector<AlgoliaState>('algolia');

export const getAlgoliaMultiResults = createSelector(
  algoliaFeatureSelector,
  state => state.algoliaMultiResult
);

export const isLoadingMultiAlgolia = createSelector(
  algoliaFeatureSelector,
  state => state.isLoadingAlgoliaMulti
);

export const getAlgoliaMultiResultsPage = createSelector(
  algoliaFeatureSelector,
  state => state.algoliaMultiResultPage
);

export const isLoadingMultiAlgoliaPage = createSelector(
  algoliaFeatureSelector,
  state => state.isLoadingAlgoliaMultiPage
);

