import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Directive({
  selector: '[appAutoComplete]'
})
export class AutoCompleteDirective {
  constructor(private renderer: Renderer2,
              protected route: ActivatedRoute,
              private elementRef: ElementRef) {
  }

  private static clickInContent(targetElement: HTMLElement): boolean {
    let element = targetElement;
    while (true) {

      if (element && element.className.toString().includes('auto-complete-content')) {
        return true;
      }

      if (!element || element.className.toString().includes('auto-complete')) {
        return false;
      }

      element = element.parentElement;
    }
  }

  private static closeInTarget(targetElement: HTMLElement): boolean {
    let element = targetElement;
    while (true) {

      if (element && element.className.includes('close')) {
        return true;
      }

      if (!element) {
        return false;
      }

      element = element.parentElement;
    }
  }

  @HostListener('document:mousedown', ['$event', '$event.target'])
  public onMouseDown(event: MouseEvent, targetElement: HTMLElement): void {
    const element = this.elementRef.nativeElement;
    const clickedInside = element.contains(targetElement);
    const clickInContent = AutoCompleteDirective.clickInContent(targetElement);
    if (clickedInside) {
      if (!clickInContent && !element.className.includes('show')) {
        this.renderer.addClass(element, 'show');
      }
    } else {
      this.renderer.removeClass(element, 'show');
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    const element = this.elementRef.nativeElement;
    const clickedInside = element.contains(targetElement);
    if (clickedInside && AutoCompleteDirective.closeInTarget(targetElement)) {
      this.renderer.removeClass(element, 'show');
    }
  }
}
