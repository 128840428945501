export interface IConversationFilter {
  userId?: string;
  lastMessageTimeStamp?: string;
  lastActionTimeStamp?: string;
  limitToLast?: number;
}

export class ConversationFilter implements IConversationFilter {
  constructor(
    public userId?: string,
    public lastMessageTimeStamp?: string,
    public lastActionTimeStamp?: string,
    public limitToLast = 15,
  ) {
  }
}
