import {NgModule} from '@angular/core';
import {NavbarNotificationComponent} from './navbar-notification.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../../shared.module';
import {ListSelectCategoryModule} from '../../../category/list-select-category/list-select-category.module';
import {DropdownModule} from '../../../../../core/directive/dropdown/dropdown.module';
import {ModalModule} from '../../../modal/modal.module';
import {DateAgoModule} from '../../../../../core/pipe/date-ago.module';
import {TooltipModule} from '../../../../../core/pipe/tooltip.module';

@NgModule({
  declarations: [
    NavbarNotificationComponent
  ],
    imports: [
        SharedModule,
        RouterModule,
        ListSelectCategoryModule,
        DropdownModule,
        ModalModule,
        DateAgoModule,
        TooltipModule
    ],
  exports: [
    NavbarNotificationComponent
  ],
  providers: [],
})
export class NavbarNotificationModule {
}
