import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {


  transform(value: any, content?: any, typeDate?: any): any {
    if (value) {
      let seconds;
      let intervals;
      let counter;

      if (typeDate && typeDate === 'time') {
        intervals = {
          an: 31536000,
          mois: 2592000,
          j: 86400,
          h: 3600,
          min: 60,
          s: 1
        };
        seconds = Math.floor((+new Date(new Date().toISOString()) - +new Date().setTime(value)) / 1000);
      } else {
        intervals = {
          an: 31536000,
          mois: 2592000,
          semaine: 604800,
          jour: 86400,
          heure: 3600,
          minute: 60,
          seconde: 1
        };
        seconds = Math.floor((+new Date(new Date().toISOString()) - +new Date(value)) / 1000);
      }

      if (seconds < 29) {
        return 'A l\'instant';
      }

      // tslint:disable-next-line:forin
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          let thereAre = 'Il y a ';
          if (content === 'short') {
            thereAre = '';
          }
          if (counter === 1 || i === 'mois' || i.length <= 1) {
            return thereAre + counter + ' ' + i; // singular (1 day ago)
          } else {
            return thereAre + counter + ' ' + i + 's'; // plural (2 days ago)
          }
        }
      }
    }
    return value;
  }
}
