import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, Subject} from 'rxjs';
import {Category} from '../../../../core/model/category/category.model';
import {TransferState} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import {getAllCategories, getCurrentCategory, isLoadingListCategory} from '../../../../core/store/category/category.selectors';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {selectCategory} from '../../../../core/store/category/category.actions';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {clearTags} from '../../../../core/store/tag/tag.actions';
import {Domain} from '../../../../core/model/domain/domain.model';
import {getCurrentDomain} from '../../../../core/store/domain/domain.selectors';
import {selectDomain} from '../../../../core/store/domain/domain.actions';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-list-select-category-component',
  templateUrl: './list-select-category.component.html',
  styleUrls: ['./list-select-category.component.scss'],
})
export class ListSelectCategoryComponent implements OnInit, OnDestroy {

  @Input() domain: Domain;
  @Input() addClass: string;

  categories: Observable<Category[]>;
  isLoading: Observable<boolean>;
  currentCategory: Observable<Category>;
  selectedIndexTypeCategory: number = null;
  private _unsubscribeAll: Subject<any>;
  titleDomain: string;

  constructor(private state: TransferState,
              protected router: Router,
              protected route: ActivatedRoute,
              private location: Location,
              public seoService: SEOService,
              private store: Store<Category>) {
    this._unsubscribeAll = new Subject();

    this.isLoading = this.store.select(isLoadingListCategory);
    this.currentCategory = this.store.select(getCurrentCategory);

    combineLatest([this.store.select(getAllCategories), this.route.queryParams])
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(([allCategories]) => allCategories.length > 0),
      ).subscribe(([allCategories, paramMap]) => {
      let category: Category = null;
      allCategories.forEach(categoryInList => {
        if (categoryInList.type === paramMap.categorie) {
          if (paramMap.sousCategorie == null) {
            category = {...categoryInList};
            category.subCategory = null;
            category.subCategoryLabel = null;

          } else if (paramMap.sousCategorie === categoryInList.subCategory) {
            category = categoryInList;
          }
        }
      });

      this.store.dispatch(selectCategory({category}));
    });
  }

  ngOnInit(): void {
    if (this.domain) {
      this.titleDomain = this.domain.title;
      this.categories = this.store.select(getAllCategories)
        .pipe(map(categories => categories.filter(category => category.domain === this.domain.title)));
    } else {
      this.categories = combineLatest([this.store.select(getAllCategories), this.store.select(getCurrentDomain)])
        .pipe(
          filter(([categories, domain]) => categories != null && domain != null),
          map(([categories, domain]) => {
            this.titleDomain = domain.title;
            return categories.filter(category => category.domain === domain.title);
          })
        );
    }

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next({});
    this._unsubscribeAll.complete();
  }

  getTypeCategory(categories: Category[]): string[] {
    return !categories ? [] : [...new Set(categories.map(value => value.typeLabel))];
  }

  getCategoriesByTypeCategory(categories: Category[], typeLabel: string): Category[] {
    return [...new Set(categories.filter(value => value.typeLabel === typeLabel))];
  }

  toggleMobile(index: number): void {
    this.selectedIndexTypeCategory = this.selectedIndexTypeCategory === index ? null : index;
  }

  chooseSubCategory(category: Category): void {
    const queryParams: any[] = [];
    queryParams.push({sousCategorie: category.subCategory});
    queryParams.push({categorie: category.type});

    this.setUrl(queryParams);
  }

  chooseCategory(category: Category): void {
    const queryParams: any[] = [];
    queryParams.push({categorie: category.type});
    const newCategory: Category = {...category};
    newCategory.subCategory = null;
    newCategory.subCategoryLabel = null;
    this.setUrl(queryParams);
  }

  private setUrl(queryParams: any[]): void {
    let commands = [];

    if (this.domain && this.domain.title && !this.location.path().startsWith('/recette/liste/' + this.domain.title)) {
      commands = ['/recette/liste/' + this.domain.title];

      this.store.select(getCurrentDomain)
        .pipe(
          take(1),
          filter((currentDomain) => currentDomain.title !== this.domain.title)
        )
        .subscribe({
          next: () => {
            this.store.dispatch(selectDomain({domain: this.domain}));
          },
          error: (err) => {
            console.log(err);
          }
        });

    }

    this.store.dispatch(clearTags());

    this.clearQueryParamAndReplace(commands, queryParams);
  }

  clearQueryParamAndReplace(commands, listParams: any[]): void {
    const clearAndReplaceQueryParam: any = {};

    clearAndReplaceQueryParam.tags = null;
    clearAndReplaceQueryParam.sousCategorie = null;
    clearAndReplaceQueryParam.categorie = null;

    listParams.forEach(value => {
      Object.keys(value).forEach((key) => {
        clearAndReplaceQueryParam[key] = value[key];
      });
    });

    this.router.navigate(commands, {
      queryParams: clearAndReplaceQueryParam,
      queryParamsHandling: 'merge'
    }).then();
  }

  isSelectedCategory(currentCategories: Category[], typeCategory: string, currentCategory: Category): string {
    if (currentCategory != null &&
      this.getCategoriesByTypeCategory(currentCategories, typeCategory)[0].type === currentCategory.type &&
      currentCategory.subCategory == null) {
      return 'selected';
    }
    return '';
  }

  isSelectedSubCategory(category: Category, currentCategory: Category): string {
    if (currentCategory != null &&
      category.subCategory === currentCategory.subCategory &&
      category.type === currentCategory.type) {
      return 'selected';
    }
    return '';
  }
}
