import {EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {notificationActionTypes} from './notification.actions';
import {NotificationFirebase} from '../../model/notification/notification-firebase.model';
import {NotificationFirebaseFilter} from '../../model/notification/notification-firebase-filter.model';

export interface NotificationState extends EntityState<NotificationFirebase> {
  isLoadingList: boolean;
  isLoadingCount: boolean;
  notificationFilter: NotificationFirebaseFilter;
  noMoreNotificationInFirebase: boolean;
  notifications: NotificationFirebase[];
  count: number;
}

export const initialState = {
  isLoadingList: false,
  isLoadingCount: false,
  notificationFilter: new NotificationFirebaseFilter(),
  noMoreNotificationInFirebase: false,
  notifications: [],
  count: null,
};

function getNotificationFirebaseFilter(
  notificationFilter: NotificationFirebaseFilter,
  notifications: NotificationFirebase[]): NotificationFirebaseFilter {
  const newNotificationFirebaseFilter = new NotificationFirebaseFilter();
  if (notifications.length > 0) {
    newNotificationFirebaseFilter.lastCreationDate = notifications[notifications.length - 1].creationDate;
    newNotificationFirebaseFilter.userId = notificationFilter.userId;
  }
  return newNotificationFirebaseFilter;
}

function getNotificationFirebases(action, state): NotificationFirebase[] {
  if (action.notifications.length > 0) {
    const oldNotificationFirebases = [...state.notifications];
    const newNotificationFirebases = [...action.notifications];

    newNotificationFirebases.forEach(newNotification => {
      const tmpNewNotification = {...newNotification};
      tmpNewNotification.url = getUrlNotification(newNotification.url);
      tmpNewNotification.queryParams = getQueryParansNotification(newNotification.url);
      oldNotificationFirebases.push(tmpNewNotification);
    });

    return oldNotificationFirebases;
  }
  return action.notifications;
}

function getUrlNotification(url: string): string {
  if (url.includes('?')) {
    return url.split('?')[0];
  }
  return url;
}

function getQueryParansNotification(url: string): any {
  if (url.includes('?')) {
    const queryParamsStr = url.split('?')[1];
    const queryParams = {};
    const splitQueryParams = queryParamsStr.split('&');
    splitQueryParams.forEach(pairQueryParam => {
      const value = pairQueryParam.split('=');
      value.forEach((queryParam, idx) => {
        if (idx % 2 === 0) {
          queryParams[value[idx]] = value[idx + 1];
        }
      });
    });
    return queryParams;
  }
  return '';
}

function addNotificationFirebase(action, state): NotificationFirebase[] {
  const currentNotifications = [...state.notifications];

  if (action.notification) {
    const newNotifications = {...action.notification};
    newNotifications.queryParams = getQueryParansNotification(newNotifications.url);
    newNotifications.url = getUrlNotification(newNotifications.url);

    currentNotifications.unshift(newNotifications);
  }
  return currentNotifications;
}

function resetCountNotifications(state): NotificationFirebase[] {
  const currentNotificationFirebases = JSON.parse(JSON.stringify(state.notifications));

  currentNotificationFirebases.forEach(currentNotification => {
    currentNotification.read = true;
  });

  return currentNotificationFirebases;
}

function removeNotificationFirebase(action, state): NotificationFirebase[] {
  if (action.notification) {
    return state.notifications.filter(notification => notification.id !== action.notification.id);
  }

  return state.notifications;
}

export const notificationReducer = createReducer(
  initialState,

  on(notificationActionTypes.loadNotifications, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.notificationFilter
  })),

  on(notificationActionTypes.notificationsLoadedDone, (state, action) => ({
    ...state,
    isLoadingList: false,
    noMoreNotificationInFirebase: action.notifications.length < state.notificationFilter.limitToLast,
    notificationFilter: getNotificationFirebaseFilter(state.notificationFilter, action.notifications),
    notifications: getNotificationFirebases(action, state)
  })),

  on(notificationActionTypes.notificationsLoadedFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(notificationActionTypes.countNotifications, (state) => ({
    ...state,
    isLoadingCount: true
  })),

  on(notificationActionTypes.notificationsCountDone, (state, action) => ({
    ...state,
    isLoadingList: false,
    count: action.count
  })),

  on(notificationActionTypes.notificationsCountFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(notificationActionTypes.notificationsResetCountDone, (state) => ({
    ...state,
    isLoadingList: false,
    count: 0,
    notifications: resetCountNotifications(state)
  })),

  on(notificationActionTypes.clearNotifications, () => ({
    ...initialState
  })),

  on(notificationActionTypes.addNotification, (state, action) => ({
      ...state,
      count: state.count ? state.count + 1 : 1,
      notifications: addNotificationFirebase(action, state)
    }
  )),

  on(notificationActionTypes.removeNotification, (state, action) => ({
      ...state,
      notifications: removeNotificationFirebase(action, state)
    }
  )),
);
