<a *ngIf="!user" class="element-menu center-vertically login-icon" (click)="saveUrl()"
   [routerLink]="'/connexion'">
  <img src="../../../../../assets/images/header/profil.svg?v=2" width="41" height="41" loading="lazy" alt="Image du profil"/>
</a>

<div *ngIf="user" class="dropdown sub-menu-profil-header" appDropdown>
  <div class="nav-btn element-menu center-vertically">
    <img *ngIf="user.userPicture && user.userPicture != ''" class="logo-user" [src]="user.userPicture"
         alt="Image de l'utilisateur connecté"/>
    <div *ngIf="!user.userPicture || user.userPicture == ''"
         class="logo-user content color-{{user.id.charCodeAt(0) % 5}}">
      {{user.username ? user.username.charAt(0).toUpperCase() : 'A'}}
    </div>
  </div>
  <div class="dropdown-content sub-menu-profil-content">
    <div class="classic-content">
      <a class="sub-menu-profil-btn close" [routerLink]="'/utilisateur/' + user.username.toLowerCase()">
        <div class="sub-menu-profil-icon">
          <img *ngIf="user.userPicture && user.userPicture != ''" class="logo-user" [src]="user.userPicture"
               alt="Image de l'utilisateur connecté"/>
          <div *ngIf="!user.userPicture || user.userPicture == ''"
               class="logo-user content color-{{user.id.charCodeAt(0) % 5}}">
            {{user.username ? user.username.charAt(0).toUpperCase() : 'A'}}
          </div>
        </div>
        Mon profil
      </a>
      <a class="sub-menu-profil-btn close" [routerLink]="'/tableau-de-bord'">
        <div class="sub-menu-profil-icon">
          <img src="../../../../../assets/images/header/dashboard.svg?v=2" alt="Mon tableau de bord"/>
        </div>
        Tableau de bord
      </a>
      <a class="sub-menu-profil-btn close" [routerLink]="'/mes-favoris'">
        <div class="sub-menu-profil-icon">
          <img src="../../../../../assets/images/header/wish.svg?v=2" alt="Mes favoris"/>
        </div>
        Mes favoris
      </a>
      <a class="sub-menu-profil-btn close" [routerLink]="'tableau-de-bord'" [queryParams]="{tab: 'mes-recettes'}">
        <div class="sub-menu-profil-icon">
          <img src="../../../../../assets/images/header/recipes.svg?v=2" alt="Mes recettes"/>
        </div>
        Mes recettes
      </a>
      <a class="sub-menu-profil-btn close" [routerLink]="'/parametres'">
        <div class="sub-menu-profil-icon">
          <img src="../../../../../assets/images/header/settings.svg?v=2" alt="Paramètres"/>
        </div>
        Mes paramètres
      </a>
      <div class="sub-menu-profil-btn close" (click)="signOut()">
        <div class="sub-menu-profil-icon">
          <img src="../../../../../assets/images/header/logout.svg?v=2" alt="Déconnexion"/>
        </div>
        Déconnexion
      </div>
    </div>
  </div>
</div>
