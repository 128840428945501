import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, makeStateKey, TransferState} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {Event, Router, RouterModule, Scroll} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './core/interceptor/auth.interceptor';
import {ErrorHandlerInterceptor} from './core/interceptor/error-handler.interceptor';
import {NavbarModule} from './shared/components/navbar/navbar.module';
import {CookieModule} from 'ngx-cookie';
import {FooterModule} from './shared/components/footer/footer.module';
import {Store, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CategoryEffects} from './core/store/category/category.effects';
import {categoryReducer} from './core/store/category/category.reducers';
import {AppState, metaReducers, reducers} from './core/store/app.state';
import {domainReducer} from './core/store/domain/domain.reducers';
import {DomainEffects} from './core/store/domain/domain.effects';
import {recipeReducer} from './core/store/recipe/recipe.reducers';
import {RecipeEffects} from './core/store/recipe/recipe.effects';
import {tagReducer} from './core/store/tag/tag.reducers';
import {TagEffects} from './core/store/tag/tag.effects';
import {commentReducer} from './core/store/comment/comment.reducers';
import {CommentEffects} from './core/store/comment/comment.effects';
import {userReducer} from './core/store/user/user.reducers';
import {UserEffects} from './core/store/user/user.effects';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData, ViewportScroller} from '@angular/common';
import {NotificationsModule} from './shared/components/notifications/notifications.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {followUserReducer} from './core/store/follow-user/follow-user.reducers';
import {FollowUserEffects} from './core/store/follow-user/follow-user.effects';
import {algoliaReducer} from './core/store/algolia/algolia.reducers';
import {AlgoliaEffects} from './core/store/algolia/algolia.effects';
import {ReportEffects} from './core/store/report/report.effects';
import {reportReducer} from './core/store/report/report.reducers';
import {PendingChangesGuard} from './core/guard/pending-changes.guard';
import {filter, pairwise} from 'rxjs/operators';
import {notificationReducer} from './core/store/notification/notification.reducers';
import {NotificationEffects} from './core/store/notification/notification.effects';
import {footerReducer} from './core/store/footer/footer.reducers';
import {FooterEffects} from './core/store/footer/footer.effects';
import {headerReducer} from './core/store/header/header.reducers';
import {HeaderEffects} from './core/store/header/header.effects';
import {NgxJsonLdModule} from '@ngx-lite/json-ld';
import {blogReducer} from './core/store/blog/blog.reducers';
import {BlogEffects} from './core/store/blog/blog.effects';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ingredientReducer} from './core/store/ingredient/ingredient.reducers';
import {IngredientEffects} from './core/store/ingredient/ingredient.effects';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {FIREBASE_OPTIONS} from '@angular/fire/compat';
import {messageReducer} from './core/store/message/message.reducers';
import {MessageEffects} from './core/store/message/message.effects';
import {conversationReducer} from './core/store/conversation/conversation.reducers';
import {ConversationEffects} from './core/store/conversation/conversation.effects';
import {monetizationPositionReducer} from './core/store/monetizationposition/monetization-position.reducers';
import {MonetizationPositionEffects} from './core/store/monetizationposition/monetization-position.effects';
import {addressReducer} from './core/store/address/address.reducers';
import {AddressEffects} from './core/store/address/address.effects';
import {meansPaymentReducer} from './core/store/means-payment/means-payment.reducers';
import {MeansPaymentEffects} from './core/store/means-payment/means-payment.effects';
import {withdrawalReducer} from './core/store/withdrawal/withdrawal.reducers';
import {WithdrawalEffects} from './core/store/withdrawal/withdrawal.effects';
import {LoaderModule} from './shared/components/loader/loader.module';
import {blogCategoryReducer} from './core/store/blog-category/blog-category.reducers';
import {BlogCategoryEffects} from './core/store/blog-category/blog-category.effects';

registerLocaleData(localeFr);

export const NGRX_STATE = makeStateKey('NGRX_STATE');

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
      BrowserModule.withServerTransition({appId: 'serverApp'}),
      BrowserAnimationsModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:30000'
      }),
      CookieModule.withOptions(),
      HttpClientModule,
      AppRoutingModule,
      RouterModule,
      NavbarModule,
      FooterModule,

      provideFirebaseApp(() => initializeApp(environment.firebaseConfig),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore())
      ),
      AngularFireAuthModule,
      StoreModule.forRoot(reducers, {metaReducers}),
      StoreModule.forFeature('categories', categoryReducer),
      StoreModule.forFeature('domains', domainReducer),
      StoreModule.forFeature('recipes', recipeReducer),
      StoreModule.forFeature('monetizationPositions', monetizationPositionReducer),
      StoreModule.forFeature('tags', tagReducer),
      StoreModule.forFeature('comments', commentReducer),
      StoreModule.forFeature('followUsers', followUserReducer),
      StoreModule.forFeature('users', userReducer),
      StoreModule.forFeature('algolia', algoliaReducer),
      StoreModule.forFeature('reports', reportReducer),
      StoreModule.forFeature('notifications', notificationReducer),
      StoreModule.forFeature('footer', footerReducer),
      StoreModule.forFeature('header', headerReducer),
      StoreModule.forFeature('blogs', blogReducer),
      StoreModule.forFeature('blogCategories', blogCategoryReducer),
      StoreModule.forFeature('ingredients', ingredientReducer),
      StoreModule.forFeature('messages', messageReducer),
      StoreModule.forFeature('conversations', conversationReducer),
      StoreModule.forFeature('address', addressReducer),
      StoreModule.forFeature('meansPayment', meansPaymentReducer),
      StoreModule.forFeature('withdrawal', withdrawalReducer),
      EffectsModule.forRoot([
        CategoryEffects,
        DomainEffects,
        RecipeEffects,
        TagEffects,
        CommentEffects,
        UserEffects,
        FollowUserEffects,
        AlgoliaEffects,
        ReportEffects,
        NotificationEffects,
        FooterEffects,
        HeaderEffects,
        BlogEffects,
        IngredientEffects,
        MessageEffects,
        ConversationEffects,
        MonetizationPositionEffects,
        AddressEffects,
        MeansPaymentEffects,
        WithdrawalEffects,
        BlogCategoryEffects
        ]),
        NgxJsonLdModule,
        NotificationsModule,
        NgxChartsModule,
        NotificationsModule,
        LoaderModule
    ],
  providers: [
    PendingChangesGuard,
    {
      provide: FIREBASE_OPTIONS,
      useValue: environment.firebaseConfig},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  public constructor(
    private readonly transferState: TransferState,
    private readonly store: Store<AppState>,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
    this.router.events.pipe(
      filter((e: Event): e is Scroll => e instanceof Scroll),
      pairwise()
    ).subscribe((eventPair) => {
      const previousEvent = eventPair[0];
      const event = eventPair[1];
      if (event.position) {
        // backward navigation
        this.viewportScroller.scrollToPosition(event.position);
      } else if (event.anchor) {
        // anchor navigation
        this.viewportScroller.scrollToAnchor(event.anchor);
      } else {
        // forward navigation
        if ((previousEvent.routerEvent.url.split('?')[0]) !== event.routerEvent.url.split('?')[0]) {
          // Routes don't match, this is actual forward navigation
          // Default behavior: scroll to top
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      }
    });

    const isBrowser = this.transferState.hasKey<any>(NGRX_STATE);

    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
  }

  onServer(): void {
    this.transferState.onSerialize(NGRX_STATE, () => {
      let state;
      this.store.subscribe({
        next: (saveState: any) => {
          state = saveState;
        },
        error: err => console.log(err)
      }).unsubscribe();

      return state;
    });
  }

  onBrowser(): void {
    const state = this.transferState.get<any>(NGRX_STATE, null);
    this.transferState.remove(NGRX_STATE);
    this.store.dispatch({type: 'SET_ROOT_STATE', payload: state});
  }
}
