import {messageActionTypes} from './message.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {Message} from '../../model/message/message.model';
import {MessageService} from '../../../shared/services/message/message.service';
import {UserService} from '../../../shared/services/user/user.service';
import {AppState} from '../app.state';

@Injectable()
export class MessageEffects {

  loadMessages$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(messageActionTypes.loadMessages),
        switchMap((action) => this.messageService.query(action.messageFilter)),
        map((messages: Message[]) => messages.reverse()),
        map((messages: Message[]) => messageActionTypes.messagesLoadedDone({messages})),
        catchError((err, caught) => {
          this.store.dispatch(messageActionTypes.messagesLoadedFail());
          return caught;
        })
      );
    }
  );

  stateChangeMessage = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(messageActionTypes.stateChangeMessage),
        switchMap((action) => this.messageService.stateChange(action.key)),
        map((value: any) => value.payload.child('/').val()),
        map((message: Message) => messageActionTypes.addMessage({message}))
      );
    }
  );

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    private actions$: Actions,
    private store: Store<AppState>) {
  }


}
