import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {MeansPayment} from '../../model/means-payment/means-payment';
import {FilterMeansPayment} from '../../model/means-payment/filter-means-payment.model';
import {meansPaymentActionTypes} from './means-payment.actions';

export interface MeansPaymentState extends EntityState<MeansPayment> {
  isLoadingList: boolean;
  isLoadingOne: boolean;
  isLoadingPatch: boolean;
  isLoadingCreate: boolean;
  isLoadingDelete: boolean;
  messageErrorLoadMeansPayment: string;
  messageErrorLoadsMeansPayment: string;
  messageErrorCreateMeansPayment: string;
  messageErrorDeleteMeansPayment: string;
  messageErrorPatchMeansPayment: string;
  totalElements: number;
  totalPages: number;
  empty: boolean;
  page: number;
  filters: FilterMeansPayment;
  meansPayment: MeansPayment;
  meansPayments: MeansPayment[];
}

export const adapter: EntityAdapter<MeansPayment> = createEntityAdapter<MeansPayment>({
  selectId: meansPayment => meansPayment.id
});

export const initialState = adapter.getInitialState({
  isLoadingList: false,
  isLoadingOne: false,
  isLoadingPatch: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  messageErrorLoadMeansPayment: null,
  messageErrorLoadsMeansPayment: null,
  messageErrorCreateMeansPayment: null,
  messageErrorDeleteMeansPayment: null,
  messageErrorPatchMeansPayment: null,
  totalElements: null,
  totalPages: null,
  page: 0,
  empty: true,
  meansPayment: null,
  meansPayments: [],
});

export const meansPaymentReducer = createReducer(
  initialState,

  on(meansPaymentActionTypes.loadListMeansPayment, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.filters,
    messageErrorLoadsMeansPayment: null,
  })),

  on(meansPaymentActionTypes.meansPaymentListLoadedDone, (state, action) => ({
    ...state,
    isLoadingList: false,
    totalElements: action.meansPayments.totalElements,
    totalPages: action.meansPayments.totalPages,
    empty: action.meansPayments.empty,
    page: action.meansPayments.number,
    meansPayments: action.meansPayments.content,
  })),

  on(meansPaymentActionTypes.meansPaymentListLoadedFail, (state, action) => ({
      ...state,
      isLoadingList: false,
      isLoad: true,
      messageErrorDeleteRecipe: action.message
    }
  )),

  on(meansPaymentActionTypes.loadOneMeansPayment, (state) => ({
    ...state,
    isLoadingOne: true,
    messageErrorLoadMeansPayment: null,
  })),

  on(meansPaymentActionTypes.meansPaymentOneLoadedDone, (state, action) => ({
    ...state,
    meansPayment: action.meansPayment,
    isLoadingOne: false,
  })),

  on(meansPaymentActionTypes.meansPaymentOneLoadedFail, (state, action) => ({
    ...state,
    isLoadingOne: false,
    messageErrorLoadMeansPayment: action.message
  })),

  on(meansPaymentActionTypes.createMeansPayment, (state) => ({
    ...state,
    isLoadingCreate: true,
    messageErrorCreateMeansPayment: null,
  })),

  on(meansPaymentActionTypes.meansPaymentCreatedDone, (state, action) => ({
    ...state,
    meansPayment: action.meansPayment,
    isLoadingCreate: false
  })),

  on(meansPaymentActionTypes.meansPaymentCreatedFail, (state, action) => ({
    ...state,
    isLoadingCreate: false,
    messageErrorCreateMeansPayment: action.message
  })),

  on(meansPaymentActionTypes.deleteMeansPayment, (state) => ({
    ...state,
    isLoadingDelete: true,
    messageErrorDeleteMeansPayment: null,
  })),

  on(meansPaymentActionTypes.meansPaymentDeletedDone, (state) => ({
    ...state,
    isLoadingDelete: false
  })),

  on(meansPaymentActionTypes.meansPaymentDeletedFail, (state, action) => ({
    ...state,
    isLoadingDelete: false,
    messageErrorDeleteMeansPayment: action.message
  })),

  on(meansPaymentActionTypes.patchMeansPayment, (state) => ({
    ...state,
    isLoadingPatch: true,
    messageErrorPatchMeansPayment: null,
  })),

  on(meansPaymentActionTypes.meansPaymentPatchDone, (state, action) => ({
    ...state,
    meansPayment: action.meansPayment,
    isLoadingPatch: false,
  })),

  on(meansPaymentActionTypes.meansPaymentPatchFail, (state, action) => ({
    ...state,
    isLoadingPatch: false,
    messageErrorPatchMeansPayment: action.message
  })),

  on(meansPaymentActionTypes.clearMeansPayment, () => ({
    ...initialState
  })),
);

export const {} = adapter.getSelectors();
