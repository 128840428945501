import {EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {commentActionTypes} from './comment.actions';
import {IFilterComment} from '../../model/comment/filter-comment.model';
import {Comment, IComment} from '../../model/comment/comment.model';

export interface CommentState extends EntityState<Comment> {
  comments: IComment[];
  isLoadingList: boolean;
  isLoadingCreate: boolean;
  isLoadingPatch: boolean;
  isLoadingDelete: boolean;
  totalElements: number;
  totalPages: number;
  empty: boolean;
  page: number;
  number: number;
  errorCreate: string;
  errorPatch: string;
  errorDelete: string;
  filters: IFilterComment;
}

export const initialState = {
  comments: [],
  isLoadingList: false,
  isLoadingCreate: false,
  isLoadingPatch: false,
  isLoadingDelete: false,
  totalElements: null,
  totalPages: null,
  page: 0,
  number: 0,
  errorCreate: null,
  errorPatch: null,
  errorDelete: null,
  empty: true,
};

function checkChild(action: any): boolean {
  return action.comments.content.length > 0 && action.comments.content[0].parent != null;
}

function getComment(action: any, state): IComment[] {
  if (action.comments.content.length > 0) {
    const currentComments: IComment[] = JSON.parse(JSON.stringify(state.comments));
    const newComments: IComment[] = JSON.parse(JSON.stringify(action.comments.content));

    const parent = newComments[0].parent;
    if (parent != null) {
      currentComments.forEach(comment => {
        if (comment.id === parent) {

          const childs = [...comment.childs];

          newComments.forEach(child => {
            childs.push(child);
          });

          comment.childs = childs;
          comment.pageChild = comment.pageChild + 1;
        }
      });
      return currentComments;
    }

  } else {
    return state.comments;
  }

  return action.comments.content;
}

function removeComment(action: any, state): IComment[] {
  const currentComments: IComment[] = JSON.parse(JSON.stringify(state.comments));

  currentComments
    .filter(comment => comment.id === action.id)
    .forEach(comment => {
      comment.childs = [];
      comment.pageChild = 0;
    });

  return currentComments;
}


function changeReply(action: any, state): IComment[] {
  const currentComments: IComment[] = JSON.parse(JSON.stringify(state.comments));

  currentComments.forEach(comment => {
    comment.childs.forEach(child => {
      if (child.id === action.id) {
        child.showReply = !child.showReply;
      }
    });
    if (comment.id === action.id) {
      comment.showReply = !comment.showReply;
    }
  });

  return currentComments;
}

function changeEdit(action: any, state): IComment[] {
  const currentComments: IComment[] = JSON.parse(JSON.stringify(state.comments));

  currentComments.forEach(comment => {
    comment.childs.forEach(child => {
      if (child.id === action.id) {
        child.showEdit = !child.showEdit;
      }
    });
    if (comment.id === action.id) {
      comment.showEdit = !comment.showEdit;
    }
  });

  return currentComments;
}

function injectComment(action: any, state): IComment[] {
  const currentComments: IComment[] = JSON.parse(JSON.stringify(state.comments));

  const comment: IComment = action.comment;
  if (comment != null) {
    if (comment.parent != null) {
      currentComments
        .filter(currentComment => currentComment.id === comment.parent)
        .forEach(currentComment => {
          currentComment.showReply = false;
          currentComment.showEdit = false;
          currentComment.childs.forEach(child => {
            child.showReply = false;
            child.showEdit = false;
          });
          currentComment.childs.push(comment);
        });
    } else {
      currentComments.unshift(comment);
    }
  }

  return currentComments;
}

function findCommentAndReplace(currentComments: IComment[], comment: IComment): void {
  currentComments
    .forEach(currentComment => {
      currentComment.showEdit = false;
      if (currentComment.id === comment.id) {
        currentComment.text = comment.text;
        currentComment.like = comment.like;
        currentComment.nbLike = comment.nbLike;
        currentComment.pin = comment.pin;
      }

      currentComment.childs.forEach(currentChild => {
        currentChild.showEdit = false;
        if (currentChild.id === comment.id) {
          currentChild.text = comment.text;
          currentChild.like = comment.like;
          currentChild.nbLike = comment.nbLike;
        }
      });
    });
}

function patchComment(action: any, state): IComment[] {
  const currentComments: IComment[] = JSON.parse(JSON.stringify(state.comments));

  const comment: IComment = action.comment;
  if (comment != null) {
    findCommentAndReplace(currentComments, comment);
  }

  return currentComments;
}

function findCommentAndDelete(currentComments: IComment[], comment: IComment): IComment[] {
  const findCommentToDelete = currentComments.filter(currentComment => currentComment.id === comment.id);

  if (findCommentToDelete.length > 0) {
    return currentComments.filter(currentComment => currentComment.id !== comment.id);
  }

  currentComments.forEach(curentComment => {
    const findChildCommentToDelete = curentComment.childs.filter(child => child.id === comment.id);

    if (findChildCommentToDelete.length > 0) {
      curentComment.nbChilds -= 1;
      curentComment.childs = curentComment.childs.filter(child => child.id !== comment.id);
    }
  });

  return currentComments;
}

function deleteComment(action: any, state): IComment[] {
  const currentComments: IComment[] = JSON.parse(JSON.stringify(state.comments));

  const comment: IComment = action.comment;
  if (comment != null && comment.text != null) {
    findCommentAndReplace(currentComments, comment);
  } else {
    return findCommentAndDelete(currentComments, comment);
  }

  return currentComments;
}

export const commentReducer = createReducer(
  initialState,

  on(commentActionTypes.loadComments, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.filters
  })),


  on(commentActionTypes.loadCommentsDone, (state, action: any) => ({
    ...state,
    isLoadingList: false,
    totalElements: checkChild(action) ? state.totalElements : action.comments.totalElements,
    totalPages: checkChild(action) ? state.totalPages : action.comments.totalPages,
    empty: checkChild(action) ? state.empty : action.comments.empty,
    page: checkChild(action) ? state.number : action.comments.number,
    comments: getComment(action, state)
  })),

  on(commentActionTypes.loadCommentsFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(commentActionTypes.removeCommentsChild, (state, action: any) => ({
    ...state,
    comments: removeComment(action, state)
  })),

  on(commentActionTypes.changeReply, (state, action: any) => ({
    ...state,
    comments: changeReply(action, state)
  })),

  on(commentActionTypes.changeEdit, (state, action: any) => ({
    ...state,
    comments: changeEdit(action, state)
  })),

  on(commentActionTypes.createComment, (state) => ({
    ...state,
    errorCreate: null,
    isLoadingCreate: true,
  })),

  on(commentActionTypes.createCommentDone, (state, action: any) => ({
    ...state,
    isLoadingCreate: false,
    errorCreate: null,
    totalElements: state.totalElements + 1,
    comments: injectComment(action, state)
  })),

  on(commentActionTypes.createCommentFail, (state, action) => ({
      ...state,
      isLoadingCreate: false,
      errorCreate: action.message,
    }
  )),

  on(commentActionTypes.patchComment, (state) => ({
    ...state,
    errorPatch: null,
    isLoadingPatch: true,
  })),

  on(commentActionTypes.patchCommentDone, (state, action: any) => ({
    ...state,
    isLoadingPatch: false,
    errorPatch: null,
    comments: patchComment(action, state)
  })),

  on(commentActionTypes.patchCommentFail, (state, action) => ({
      ...state,
      isLoadingPatch: false,
      errorPatch: action.message,
    }
  )),

  on(commentActionTypes.deleteComment, (state) => ({
    ...state,
    errorDelete: null,
    isLoadingDelete: true,
  })),

  on(commentActionTypes.deleteCommentDone, (state, action: any) => ({
    ...state,
    isLoadingDelete: false,
    errorDelete: null,
    comments: deleteComment(action, state)
  })),

  on(commentActionTypes.deleteCommentFail, (state, action) => ({
      ...state,
      isLoadingDelete: false,
      errorDelete: action.message,
    }
  )),

  on(commentActionTypes.clearComments, () => ({
      ...initialState,
    }
  )),
);
