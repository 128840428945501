import {createAction, props} from '@ngrx/store';
import {IRecipe, Recipe} from '../../model/recipe/recipe.model';
import {FilterRecipe} from '../../model/recipe/filter-recipe.model';
import {Page} from '../../model/page.model';
import {IListRecipe} from '../../model/recipe/list-recipe.model';
import {ICreateRecipe} from '../../model/recipe/create-recipe.model';
import {IPatchRecipe} from '../../model/recipe/patch-recipe.model';


export const loadRecipes = createAction(
  '[Recipes List] Load recipes via Service',
  props<{ filters: FilterRecipe }>()
);

export const loadRecipesDone = createAction(
  '[Recipes Effect] Recipes Loaded Successfully',
  props<{ recipes: Page<IListRecipe> }>()
);

export const loadRecipesFail = createAction(
  '[Recipes Effect] Recipes Loaded failed'
);

export const loadRecipe = createAction(
  '[Recipes Effect] Load recipe via Service',
  props<{ id: number }>()
);

export const loadRecipeDone = createAction(
  '[Recipes Effect] Recipe Loaded Successfully',
  props<{ recipe: Recipe }>()
);

export const loadRecipeFail = createAction(
  '[Recipes Effect] Recipe Loaded failed',
  props<{ message: string }>()
);

export const patchRecipe = createAction(
  '[Recipes Effect] Patch recipe',
  props<{ patchRecipe: IPatchRecipe }>()
);

export const patchRecipeDone = createAction(
  '[Recipes Effect] Recipe Patch Successfully',
  props<{ recipe: Recipe }>()
);

export const patchRecipeFail = createAction(
  '[Recipes Effect] Recipe Patch fail',
  props<{ message: string }>()
);

export const clearRecipe = createAction(
  '[Recipes Effect] Clear recipe store'
);

export const createRecipe = createAction(
  '[Recipes Effect] Create recipe',
  props<{ createRecipe: ICreateRecipe }>()
);

export const createRecipeDone = createAction(
  '[Recipes Effect] Create recipe done',
  props<{ recipe: IRecipe }>()
);

export const createRecipeFail = createAction(
  '[Recipes Effect] Create recipe fail',
  props<{ message: string }>()
);

export const deleteRecipe = createAction(
  '[Recipes Effect] Delete recipe',
  props<{ deleteRecipe: number }>()
);

export const deleteRecipeDone = createAction(
  '[Recipes Effect] Delete recipe done',
  props<{ recipe: IRecipe }>()
);

export const deleteRecipeFail = createAction(
  '[Recipes Effect] Delete recipe fail',
  props<{ message: string }>()
);


export const recipeActionTypes = {
  loadRecipes,
  loadRecipesDone,
  loadRecipesFail,
  loadRecipe,
  loadRecipeDone,
  loadRecipeFail,
  patchRecipe,
  patchRecipeDone,
  patchRecipeFail,
  clearRecipe,
  createRecipe,
  createRecipeDone,
  createRecipeFail,
  deleteRecipe,
  deleteRecipeDone,
  deleteRecipeFail,
};
