import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AngularFireDatabase, SnapshotAction} from '@angular/fire/compat/database';
import {AppState} from '../../../core/store/app.state';
import {Message} from '../../../core/model/message/message.model';
import {MessageFilter} from '../../../core/model/message/message-filter.model';
import {first} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {CreateMessage} from '../../../core/model/message/create-message.model';

@Injectable({providedIn: 'root'})
export class MessageService {
  public resourceUrl = environment.SERVER_API_URL + 'api/messages';

  constructor(private db: AngularFireDatabase,
              private store: Store<AppState>,
              private http: HttpClient) {
  }

  create(createMessage: CreateMessage): Observable<void> {
    return this.http.post<void>(this.resourceUrl, createMessage);
  }

  readAll(key: string): Observable<void> {
    return this.http.put<void>(this.resourceUrl, {
      keyRoom: key,
      type: 'MESSAGE_READ'
    });
  }

  query(messageFilter: MessageFilter): Observable<Message[]> {

    let query = ref => ref.orderByChild('creationDate').limitToLast(messageFilter.limitToLast);
    if (messageFilter.lastCreationDate) {
      query = ref => ref.orderByChild('creationDate')
        .endAt(messageFilter.lastCreationDate)
        .limitToLast(messageFilter.limitToLast);
    }

    return this.db.list<Message>('chatRoom/' + messageFilter.key + '/messages', query).valueChanges().pipe(first());
  }

  stateChange(key: string): Observable<SnapshotAction<Message>> {

    const time = new Date().getTime().toString();
    return this.db.list<Message>('chatRoom/' + key + '/messages',
      ref => ref.orderByChild('creationDate').startAt(time))
      .stateChanges(['child_added', 'child_changed', 'child_removed']);
  }
}
