import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class MetaService {
  constructor(@Inject(DOCUMENT) private dom) {
  }

  createCanonicalURL(): void {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    if (this.dom.URL) {
      this.dom.URL.replace('http://localhost:4000', 'https://tooda.fr/');
    }
    link.setAttribute('href', this.dom.URL);
  }
}
