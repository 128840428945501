import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IReport} from '../../core/model/report/report.model';
import {CreateReport} from '../../core/model/report/create-report.model';

@Injectable({providedIn: 'root'})
export class ReportService {
  public resourceUrl = environment.SERVER_API_URL + 'api/reports';

  constructor(private http: HttpClient) {
  }

  create(createReport: CreateReport): Observable<IReport | null> {
    return this.http.post<IReport>(`${this.resourceUrl}`, createReport);
  }
}
