<div class="dropdown notification {{classHeader | async}} {{classShowHeader}}" appDropdown *ngIf="user != null">
  <div class="btn-icon-notification element-menu center-vertically" (click)="loadNotification()" appTooltip="Notification" placement="bottom">
    <img src="/assets/images/header/notification.svg?v=2" [class]="(countNotification | async) && (countNotification | async) > 0 ? 'ring': ''" alt="Icones notification"/>
    <span class="count-notification" *ngIf="(countNotification | async) && (countNotification | async) > 0">{{countNotification | async}}</span>
  </div>
  <div class="dropdown-content" (scroll)="onScroll($event)">
    <div class="specific-content">

      <ng-container *ngFor="let notification of (notifications | async)">
        <a class="sub-menu-notification close" *ngIf="!notification.url.startsWith('http')" [routerLink]="notification.url" [queryParams]="notification.queryParams" >
          <img *ngIf="notification.urlIcon" [src]="getIcon(notification)"
               alt="Icon notification" class="icon-notification"/>
          <span class="text" [innerHTML]="notification.message + '  <span class=\'time\'>' + (notification.creationDate | dateAgo: 'short':'time') + '</span>'"></span>
          <div class="not-read {{notification.read ? '' : 'red'}}"></div>
        </a>

        <a class="sub-menu-notification close" *ngIf="notification.url.startsWith('http')" target="_blank" [href]="notification.url">
          <img *ngIf="notification.urlIcon" [src]="getIcon(notification)"
               alt="Icon notification" class="icon-notification"/>
          <span class="text" [innerHTML]="notification.message + '  <span class=\'time\'>' + (notification.creationDate | dateAgo: 'short':'time') + '</span>'"></span>
          <div class="not-read {{notification.read ? '' : 'red'}}"></div>
        </a>
      </ng-container>


      <div class="sub-menu-notification close" *ngIf="!(isLoading | async) && (notifications | async)?.length === 0">
        <img src="/assets/images/header/notification.svg?v=2" alt="Icon pas de notification" class="icon-notification" *ngIf="(countNotification | async) && (countNotification | async) > 0"/>
        Vous n'avez aucune notification
      </div>

      <div class="sub-menu-notification" *ngIf="isLoading | async">
        Chargement des notifications...
      </div>
    </div>
  </div>
</div>
