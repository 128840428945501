import {Injectable} from '@angular/core';
import {ICookieService} from './cookie-client.inteface.service';
import {CookieService} from 'ngx-cookie';

@Injectable({
  providedIn: 'root',
})
export class CookieClientService implements ICookieService {

  constructor(private cookieService: CookieService) {

  }

  getItem(name: string): string | null {
    return this.cookieService.get(name);
  }

  setItem(name: string, value: string, expiry?: Date | string): boolean {
    if (!expiry) {
      expiry = new Date();
      expiry.setFullYear(2037);
    }
    this.cookieService.put(name, value, {expires : expiry});
    return true;
  }

  removeItem(name: string): boolean {
    this.cookieService.remove(name);
    return true;
  }
}
