import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {FilterRecipe} from '../../../core/model/recipe/filter-recipe.model';
import {createRequestOption} from '../util/request-util';
import {IRecipe, Recipe} from '../../../core/model/recipe/recipe.model';
import {Page} from '../../../core/model/page.model';
import {IListRecipe} from '../../../core/model/recipe/list-recipe.model';
import {CreateRecipe} from '../../../core/model/recipe/create-recipe.model';
import {PatchRecipe} from '../../../core/model/recipe/patch-recipe.model';
import {CreateImage} from '../../../core/model/picture/create-picture.model';
import {IPicture} from '../../../core/model/picture/picture.model';
import {CreateVideo} from '../../../core/model/create-video.model';
import {FilterDashboardRecipe} from '../../../core/model/recipe/filter-dashboard-recipe.model';
import {MonetizationDashboadRecipe} from '../../../core/model/monetization/monetization-info-recipe.model';

@Injectable({providedIn: 'root'})
export class RecipeService {
  public resourceUrl = environment.SERVER_API_URL + 'api/recipes';

  constructor(private http: HttpClient) {
  }

  public static getUrl(recipe: Recipe): string {
    return '/recette/' + recipe.domain.title + '/' +
      recipe.category.type + '/' +
      recipe.category.subCategory + '/' +
      recipe.id;
  }

  create(createRecipe: CreateRecipe): Observable<IRecipe | null> {
    return this.http.post<IRecipe>(`${this.resourceUrl}`, createRecipe);
  }

  query(filterRecipe: FilterRecipe): Observable<Page<IListRecipe> | null> {
    const options = createRequestOption(filterRecipe);
    return this.http.get<Page<IListRecipe>>(`${this.resourceUrl}`, {params: options});
  }

  find(id: number): Observable<IRecipe | null> {
    return this.http.get<IRecipe>(`${this.resourceUrl}/${id}`);
  }

  startViewRecipe(id: number): Observable<boolean | null> {
    return this.http.post<boolean>(`${this.resourceUrl}/${id}/view/start`, null);
  }

  stopViewRecipe(id: number): Observable<void> {
    return this.http.post<void>(`${this.resourceUrl}/${id}/view/stop`, null);
  }

  patch(recipe: PatchRecipe): Observable<any> {
    return this.http.patch<IRecipe>(`${this.resourceUrl}/${recipe.id}`, recipe);
  }

  delete(deleteRecipe: number): Observable<any> {
    return this.http.delete(`${this.resourceUrl}/${deleteRecipe}`);
  }

  createImage(createPicture: CreateImage): Observable<IRecipe | null> {
    const formData = new FormData();
    formData.append('file', createPicture.file);
    formData.append('ranking', createPicture.ranking.toString());
    formData.append('platform', createPicture.platform ? createPicture.platform : '');
    return this.http.post<IPicture>(`${this.resourceUrl}/${createPicture.id}/image`, formData);
  }

  createVideo(createVideo: CreateVideo): Observable<IRecipe | null> {
    const formData = new FormData();
    formData.append('file', createVideo.file);
    return this.http.post<IPicture>(`${this.resourceUrl}/${createVideo.id}/video`, formData);
  }

  nbViewRecipes(filterDashboardRecipe: FilterDashboardRecipe): Observable<number> {
    const options = createRequestOption(filterDashboardRecipe);
    return this.http.get<number>(`${this.resourceUrl}/nb-view`, {params: options});
  }

  countDiffNbViewRecipes(): Observable<number> {
    return this.http.get<number>(`${this.resourceUrl}/count-diff-nb-view`);
  }

  getMonetizationInformation(id: number): Observable<MonetizationDashboadRecipe> {
    return this.http.get<MonetizationDashboadRecipe>(`${this.resourceUrl}/${id}/monetization`);
  }
}
