import {createAction, props} from '@ngrx/store';
import {User} from '../../model/user/user.model';
import {UserInfo} from '../../model/user/user-info.model';
import {IListRecipe} from '../../model/recipe/list-recipe.model';
import {PatchUser} from '../../model/user/patch-user.model';
import {Page} from '../../model/page.model';
import {IListUser, ListUser} from '../../model/user/list-user.model';
import {FilterUser} from '../../model/user/filter-user.model';

export const loadUsers = createAction(
  '[Users List] Load users via Service',
  props<{ filters: FilterUser }>()
);

export const loadUsersDone = createAction(
  '[Users Effect] Users Loaded Successfully',
  props<{ users: Page<IListUser> }>()
);

export const loadUsersFail = createAction(
  '[Users Effect] Users Loaded failed'
);

export const loadUser = createAction(
  '[Users Effect] Load user via Service',
  props<{ id: string }>()
);

export const loadUserDone = createAction(
  '[Users Effect] User Loaded Successfully',
  props<{ user: UserInfo }>()
);

export const loadUserFail = createAction(
  '[Users Effect] User Loaded failed',
  props<{ message: string }>()
);

export const patchUser = createAction(
  '[Users Effect] Update User',
  props<{ patchUser: PatchUser }>()
);

export const patchUserDone = createAction(
  '[Users Effect] User Updated Successfully',
  props<{ user: User }>()
);

export const patchUserFail = createAction(
  '[Users Effect] User Updated fail',
  props<{ message: string }>()
);

export const deleteUser = createAction(
  '[Users Effect] Delete User',
  props<{ id: string }>()
);

export const deleteUserDone = createAction(
  '[Users Effect] User deleted Successfully',
  props<{ user: User }>()
);

export const deleteUserFail = createAction(
  '[Users Effect] User deleted fail',
  props<{ message: string }>()
);

export const cancelDeleteUser = createAction(
  '[Users Effect] Cancel delete User',
  props<{ id: string }>()
);

export const cancelDeleteUserDone = createAction(
  '[Users Effect] User cancel deleted Successfully',
  props<{ user: User }>()
);

export const cancelDeleteUserFail = createAction(
  '[Users Effect] User cancel deleted fail',
  props<{ message: string }>()
);

export const clearUser = createAction(
  '[Users Effect] Clear User',
);

export const updateRecipePromote = createAction(
  '[Users Effect] Update recipe promote',
  props<{ recipe: IListRecipe }>()
);

export const updatePictureUser = createAction(
  '[Users Effect] Update picture user',
  props<{ file: any, id: string }>()
);

export const addUserSelected = createAction(
  '[Users Effect] Add user Selected',
  props<{ user: ListUser }>()
);

export const removeUserSelected = createAction(
  '[Users Effect] Remove user Selected',
  props<{ user: ListUser }>()
);

export const refreshUser = createAction(
  '[Users Effect] Refresh user',
  props<{ userId: string }>()
);

export const clearUserSelected = createAction(
  '[Users Effect] Clear user Selected',
  props<{ user: ListUser }>()
);

export const userActionTypes = {
  loadUsers,
  loadUsersDone,
  loadUsersFail,
  loadUser,
  loadUserDone,
  loadUserFail,
  patchUser,
  patchUserDone,
  patchUserFail,
  deleteUser,
  deleteUserDone,
  deleteUserFail,
  cancelDeleteUser,
  cancelDeleteUserDone,
  cancelDeleteUserFail,
  clearUser,
  updateRecipePromote,
  updatePictureUser,
  addUserSelected,
  removeUserSelected,
  refreshUser,
  clearUserSelected
};
