import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IDomain} from '../../core/model/domain/domain.model';
import {Page} from '../../core/model/page.model';
import {FilterDomain} from '../../core/model/domain/filter-category.model';
import {createRequestOption} from './util/request-util';

@Injectable({providedIn: 'root'})
export class DomainService {
  public resourceUrl = environment.SERVER_API_URL + 'api/domains';

  constructor(private http: HttpClient) {
  }

  query(filter: FilterDomain): Observable<Page<IDomain> | null> {
    const options = createRequestOption(filter);
    return this.http.get<Page<IDomain>>(`${this.resourceUrl}`, {params: options});
  }
}
