import {createAction, props} from '@ngrx/store';
import {FollowUser} from '../../model/follow-user/follow-user.model';
import {FilterFollowUser} from '../../model/follow-user/filter-follow-user.model';
import {Page} from '../../model/page.model';

export const loadFollowUser = createAction(
  '[Follow Users Effect] Load follow user via Service',
  props<{ filters: FilterFollowUser }>()
);

export const loadFollowUserDone = createAction(
  '[Follow Users Effect] Follow User Loaded Successfully',
  props<{ followUsers: Page<FollowUser> }>()
);

export const loadFollowUserFail = createAction(
  '[Follow Users Effect] Follow User Loaded failed',
  props<{ message: string }>()
);

export const clearFollowUsers = createAction(
  '[Follow Users Effect] Clear follow user store'
);


export const followUserActionTypes = {
  loadFollowUser,
  loadFollowUserDone,
  loadFollowUserFail,
  clearFollowUsers,
};
