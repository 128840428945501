import {createAction, props} from '@ngrx/store';


export const setVisibilityBgFooter = createAction(
  '[Users Effect] set visibility background footer',
  props<{ visibility: boolean }>()
);

export const setVisibilityOpinion = createAction(
  '[Users Effect] set visibility btn opinion',
  props<{ visibility: boolean }>()
);


export const setVisibilityStreamerRegistration = createAction(
  '[Users Effect] set visibility streamer registration',
  props<{ visibility: boolean }>()
);


export const footerActionTypes = {
  setVisibilityBgFooter,
  setVisibilityOpinion,
  setVisibilityStreamerRegistration,
};
