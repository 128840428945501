import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-img-or-letter-picture-component',
  templateUrl: './img-or-letter-picture.component.html',
  styleUrls: ['./img-or-letter-picture.component.scss']
})
export class ImgOrLetterPictureComponent {
  @Input() userPicture: string;
  @Input() textForOneLetter: string;
}
