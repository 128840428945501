import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {Page} from 'src/app/core/model/page.model';
import {environment} from '../../../../environments/environment';
import {BlogCategory} from '../../../core/model/blog-category/blog-category.model';

@Injectable({providedIn: 'root'})
export class BlogCategoryService {
  public resourceUrl = environment.SERVER_API_URL + 'api/blog-categories';

  constructor(private http: HttpClient) {
  }

  query(): Observable<Page<BlogCategory>> {
    return this.http.get<Page<BlogCategory>>(`${this.resourceUrl}`, {});
  }
}
