import {createReducer, on} from '@ngrx/store';
import {blogActionTypes} from './blog.actions';
import {FilterBlog} from '../../model/blog/filter-blog.model';
import {IListBlog, ListBlog} from '../../model/blog/list-blog.model';
import {Blog} from '../../model/blog/blog.model';
import {EntityState} from '@ngrx/entity';

export interface BlogState extends EntityState<ListBlog> {
  isLoadingList: boolean;
  isLoadingOne: boolean;
  messageErrorLoadBlog: string;
  totalElements: number;
  totalPages: number;
  empty: boolean;
  page: number;
  blog: Blog;
  blogs: IListBlog[];
  filters: FilterBlog;
}

export const initialState = {
  isLoadingList: false,
  isLoadingOne: false,
  messageErrorLoadBlog: null,
  blog: null,
  blogs: [],
  totalElements: null,
  totalPages: null,
  page: 0,
  empty: true,
};

export const blogReducer = createReducer(
  initialState,

  on(blogActionTypes.loadBlogs, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.filters
  })),

  on(blogActionTypes.loadBlogsDone, (state, action: any) => ({
    ...state,
    isLoadingList: false,
    totalElements: action.blogs.totalElements,
    totalPages: action.blogs.totalPages,
    empty: action.blogs.empty,
    page: action.blogs.number,
    blogs: action.blogs.content
  })),

  on(blogActionTypes.loadBlogsFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(blogActionTypes.loadBlog, state => ({
    ...state,
    isLoadingOne: true,
    messageErrorLoadBlog: null
  })),

  on(blogActionTypes.loadBlogDone, (state, action) => ({
      ...state,
      isLoadingOne: false,
      blog: action.blog,
    }
  )),

  on(blogActionTypes.loadBlogFail, (state, action) => ({
      ...state,
      isLoadingOne: false,
      messageErrorLoadBlog: action.message
    }
  )),

  on(blogActionTypes.clearBlog, () => ({
      ...initialState
    }
  )),
);
