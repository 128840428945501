import {CategoryState, selectAll} from './category.reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const categoryFeatureSelector = createFeatureSelector<CategoryState>('categories');

export const getAllCategories = createSelector(
  categoryFeatureSelector,
  selectAll
);

export const isLoadingListCategory = createSelector(
  categoryFeatureSelector,
  state => state.isLoadingList
);

export const getCurrentCategory = createSelector(
  categoryFeatureSelector,
  state => state.category
);
