import {makeStateKey} from '@angular/platform-browser';
import {User} from '../model/user/user.model';

// USER
export const STATE_CURRENT_USER = makeStateKey<User>('userIdentity');
export const STATE_CURRENT_EMAIL = makeStateKey<string>('currentEmail');
export const STATE_EMAIL_VALIDATION = makeStateKey<string>('emailValidation');
export const STATE_ONE_USER = makeStateKey('oneUser');
export const STATE_LIST_USERS = makeStateKey('listUser');

// EMAIL VALIDATION
export const STATE_MODE_ACTION_EMAIL = makeStateKey<string>('modeActionEmail');
export const STATE_OOB_CODE_ACTION_EMAIL = makeStateKey<string>('oobCodeActionEmail');

// DOMAIN
export const STATE_LIST_DOMAIN = makeStateKey('listDomain');

// CATEGORIES
export const STATE_LIST_CATEGORIES = makeStateKey('listCategories');

// RECIPES
export const STATE_LIST_RECIPES = makeStateKey('listRecipes');
export const STATE_LIST_RECIPES_HOME = makeStateKey('listRecipesHome');
export const STATE_ONE_RECIPE = makeStateKey('oneRecipe');

// COMMENTS
export const STATE_LIST_COMMENTS = makeStateKey('listComments');

// TAGS
export const STATE_LIST_TAGS = makeStateKey('listTags');

// ALGOLIA
export const STATE_MULTI_ALGOLIA = makeStateKey('multiAlgolia');
export const STATE_MULTI_ALGOLIA_PAGE = makeStateKey('multiAlgoliaPage');

// BLOG
export const STATE_LIST_BLOGS = makeStateKey('listBlogs');
export const STATE_ONE_BLOG = makeStateKey('oneBlog');
export const STATE_LIST_BLOGS_HOME = makeStateKey('listBlogsHome');
export const STATE_LIST_USERS_HOME = makeStateKey('listUsersHome');
export const STATE_LIST_BLOG_CATEGORIES = makeStateKey('listBlogCategories');

