export interface IMessageFilter {
  userId?: string;
  lastCreationDate?: string;
  limitToLast?: number;
}

export class MessageFilter implements IMessageFilter {
  constructor(
    public key?: string,
    public lastCreationDate?: string,
    public limitToLast = 15,
  ) {
  }
}
