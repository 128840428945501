import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {domainActionTypes} from './domain.actions';
import {Domain} from '../../model/domain/domain.model';

export interface DomainState extends EntityState<Domain> {
  isLoadingList: boolean;
  domain: Domain;
}

export const adapter: EntityAdapter<Domain> = createEntityAdapter<Domain>({
  selectId: domain => domain.title
});

export const initialState = adapter.getInitialState({
  isLoadingList: false,
  domain: null
});

export const domainReducer = createReducer(
  initialState,

  on(domainActionTypes.loadDomains, (state) => ({
    ...state,
    isLoadingList: true,
  })),


  on(domainActionTypes.loadDomainsDone, (state, action) => {
    return adapter.setAll(
      action.domains.content,
      {
        ...state,
        isLoadingList: false,
      }
    );
  }),

  on(domainActionTypes.loadDomainsFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(domainActionTypes.selectDomain, (state, action) => ({
      ...state,
      domain: action.domain,
    }
  )),
);

export const {selectAll} = adapter.getSelectors();
