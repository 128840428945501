import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Page} from 'src/app/core/model/page.model';
import {createRequestOption} from '../util/request-util';
import {IWithdrawal, Withdrawal} from '../../../core/model/withdrawal/withdrawal';
import {PatchWithdrawal} from 'src/app/core/model/withdrawal/patch-withdrawal.model';
import {CreateWithdrawal} from '../../../core/model/withdrawal/create-withdrawal.model';
import {WithdrawalHistory} from '../../../core/model/withdrawal/withdrawalHistory';

@Injectable({providedIn: 'root'})
export class WithdrawalService {
  public resourceUrl = environment.SERVER_API_URL + 'api/users';

  constructor(private http: HttpClient) {
  }

  query(req: any): Observable<Page<Withdrawal>> {
    const options = createRequestOption(req);
    return this.http.get<Page<Withdrawal>>(`${this.resourceUrl}/${req.userId}/withdrawals/`, {params: options});
  }

  create(userId: string, createWithdrawal: CreateWithdrawal): Observable<IWithdrawal> {
    return this.http.post<IWithdrawal>(`${this.resourceUrl}/${userId}/withdrawals/`, createWithdrawal);
  }

  find(userId: string, id: string | number): Observable<IWithdrawal> {
    return this.http.get<IWithdrawal>(`${this.resourceUrl}/${userId}/withdrawals/${id}`);
  }

  patch(userId: string, patchWithdrawal: PatchWithdrawal): Observable<Withdrawal> {
    return this.http.patch<Withdrawal>(`${this.resourceUrl}/${userId}/withdrawals/${patchWithdrawal.id}`, patchWithdrawal);
  }

  delete(userId: string, withdrawalId: string | number): Observable<Withdrawal> {
    return this.http.delete<Withdrawal>(`${this.resourceUrl}/${userId}/withdrawals/${withdrawalId}`);
  }

  downloadInvoice(userId: string, id: string | number): Observable<Blob> {
    return this.http.get(`${this.resourceUrl}/${userId}/withdrawals/${id}/invoice`, {
      responseType: 'blob',
    });
  }

  history(req): Observable<Page<WithdrawalHistory>> {
    const options = createRequestOption(req);
    return this.http.get<Page<WithdrawalHistory>>(`${this.resourceUrl}/${req.userId}/withdrawals/${req.id}/history`, {params: options});
  }
}
