import {reportActionTypes} from './report.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Report} from '../../model/report/report.model';
import {ReportService} from '../../../shared/services/report.service';
import {TransferState} from '@angular/platform-browser';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';
import {getErrorRequest} from '../../../shared/services/util/request-util';

@Injectable()
export class ReportEffects {
  createReport$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(reportActionTypes.createReport),
        switchMap((action) => this.reportService.create(action.createReport)),
        map(report => reportActionTypes.createReportDone({report})),
        catchError((err, caught) => {
          this.store.dispatch(reportActionTypes.createReportFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  constructor(
    private reportService: ReportService,
    private actions$: Actions,
    private router: Router,
    private state: TransferState,
    private utilsTransfertsState: UtilsTransfertsState,
    private store: Store<Report>) {
  }
}
