import {createAction, props} from '@ngrx/store';
import {Ingredient} from '../../model/ingredient/ingredient.model';
import {FilterIngredient} from '../../model/ingredient/filter-ingredient.model';
import {Page} from '../../model/page.model';
import {IListIngredient} from '../../model/ingredient/list-ingredient.model';


export const loadIngredients = createAction(
  '[Ingredients List] Load ingredients via Service',
  props<{ filters: FilterIngredient }>()
);

export const loadIngredientsDone = createAction(
  '[Ingredients Effect] Ingredients Loaded Successfully',
  props<{ ingredients: Page<IListIngredient> }>()
);

export const loadIngredientsFail = createAction(
  '[Ingredients Effect] Ingredients Loaded failed'
);

export const loadIngredient = createAction(
  '[Ingredients Effect] Load ingredient via Service',
  props<{ metaTitle: string }>()
);

export const loadIngredientDone = createAction(
  '[Ingredients Effect] Ingredient Loaded Successfully',
  props<{ ingredient: Ingredient }>()
);

export const loadIngredientFail = createAction(
  '[Ingredients Effect] Ingredient Loaded failed',
  props<{ message: string }>()
);

export const clearIngredient = createAction(
  '[Ingredients Effect] Clear ingredient store'
);

export const ingredientActionTypes = {
  loadIngredients,
  loadIngredientsDone,
  loadIngredientsFail,
  loadIngredient,
  loadIngredientDone,
  loadIngredientFail,
  clearIngredient,
};
