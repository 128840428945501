import {createReducer, on} from '@ngrx/store';
import {footerActionTypes, setVisibilityOpinion, setVisibilityStreamerRegistration} from './footer.actions';

export interface FooterState {
  visibilityBgFooter: boolean;
  visibilityBtnOpinion: boolean;
  visibilityStreamerRegistration: boolean;
}

export const initialState = {
  visibilityBgFooter: true,
  visibilityBtnOpinion: true,
  visibilityStreamerRegistration: false,
};

export const footerReducer = createReducer(
  initialState,

  on(footerActionTypes.setVisibilityBgFooter, (state, action) => ({
      ...state,
      visibilityBgFooter: action.visibility,
    }
  )),

  on(footerActionTypes.setVisibilityOpinion, (state, action) => ({
      ...state,
      visibilityBtnOpinion: action.visibility,
    }
  )),

  on(footerActionTypes.setVisibilityStreamerRegistration, (state, action) => ({
      ...state,
      visibilityStreamerRegistration: action.visibility,
    }
  )),
);
