import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {FilterBlog} from '../../../core/model/blog/filter-blog.model';
import {IBlog} from '../../../core/model/blog/blog.model';
import {IListBlog} from '../../../core/model/blog/list-blog.model';
import {Page} from '../../../core/model/page.model';
import {createRequestOption} from '../util/request-util';

@Injectable({providedIn: 'root'})
export class BlogService {
  public resourceUrl = environment.SERVER_API_URL + 'api/blogs';

  constructor(private http: HttpClient) {
  }

  query(filterBlog: FilterBlog): Observable<Page<IListBlog> | null> {
    const options = createRequestOption(filterBlog);
    return this.http.get<Page<IListBlog>>(`${this.resourceUrl}`, {params: options});
  }

  find(metaTitle: string): Observable<IBlog | null> {
    return this.http.get<IBlog>(`${this.resourceUrl}/${metaTitle}`);
  }
}
