import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {withdrawalActionTypes} from './withdrawal.actions';
import {UserService} from '../../../shared/services/user/user.service';
import {PaginationService} from '../../../shared/services/pagination.service';
import {Withdrawal} from '../../model/withdrawal/withdrawal';
import {WithdrawalService} from '../../../shared/services/user/withdrawal.service';
import {Page} from '../../model/page.model';
import {getErrorRequest} from '../../../shared/services/util/request-util';
import {Notif} from '../../model/notification/notif.model';
import {NotificationsService} from '../../../shared/services/util/notifications.service';
import {AuthService} from '../../../shared/services/auth/auth.service';
import {refreshUser} from '../user/user.actions';
import {WithdrawalTranslate} from './withdrawal.translate';

@Injectable()
export class WithdrawalEffects {

  constructor(
    private withdrawalService: WithdrawalService,
    private userService: UserService,
    private actions$: Actions,
    private router: Router,
    private notificationsService: NotificationsService,
    private store: Store<Withdrawal>,
    private authService: AuthService,
    private paginationService: PaginationService) {
  }

  loadWithdrawals$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(withdrawalActionTypes.loadListWithdrawal),
        switchMap((action) => this.withdrawalService.query(action.filters)),
        tap((withdrawals: Page<Withdrawal>) => this.paginationService.checkCurrentPageHaveItemsOrRedirect(withdrawals)),
        map(withdrawals => withdrawalActionTypes.withdrawalListLoadedDone({withdrawals})),
        catchError((err, caught) => {
          const notification = new Notif({text: WithdrawalTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(withdrawalActionTypes.withdrawalListLoadedFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  loadWithdrawal$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(withdrawalActionTypes.loadOneWithdrawal),
        switchMap((action) => this.withdrawalService.find(action.userId, action.id)),
        map(withdrawal => withdrawalActionTypes.withdrawalOneLoadedDone({withdrawal})),
        catchError((err, caught) => {
          const notification = new Notif({text: WithdrawalTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(withdrawalActionTypes.withdrawalOneLoadedFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  createWithdrawal$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(withdrawalActionTypes.createWithdrawal),
        switchMap((action) => this.withdrawalService.create(action.userId, action.createWithdrawal)),
        tap(() => {
          const notification = new Notif({
            text: 'Création de la demande de retrait réussi',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }),
        tap(withdrawal => this.store.dispatch(refreshUser({userId: withdrawal.userId}))),
        map((withdrawal: Withdrawal) => withdrawalActionTypes.withdrawalCreatedDone({withdrawal})),
        catchError((err, caught) => {
          const notification = new Notif({text: WithdrawalTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(withdrawalActionTypes.withdrawalCreatedFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  updateWithdrawal$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(withdrawalActionTypes.patchWithdrawal),
        switchMap((action) => this.withdrawalService.patch(action.userId, action.patchWithdrawal)),
        tap(() => {
          const notification = new Notif({
            text: 'Modification de la demande de retrait réussi',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }),
        tap(withdrawal => this.store.dispatch(refreshUser({userId: withdrawal.userId}))),
        map(withdrawal => withdrawalActionTypes.withdrawalPatchDone({withdrawal})),
        catchError((err, caught) => {
          const notification = new Notif({text: WithdrawalTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(withdrawalActionTypes.withdrawalPatchFail({message: getErrorRequest(err)}));
          return caught;
        }),
      );
    }
  );

  deleteWithdrawal$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(withdrawalActionTypes.deleteWithdrawal),
        switchMap((action) => this.withdrawalService.delete(action.userId, action.id)),
        tap(() => {
          const notification = new Notif({
            text: 'Suppresion de la demande de retrait réussi',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }),
        tap(withdrawal => this.store.dispatch(refreshUser({userId: withdrawal.userId}))),
        map(withdrawal => withdrawalActionTypes.withdrawalDeletedDone({withdrawal})),
        catchError((err, caught) => {
          const notification = new Notif({text: WithdrawalTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(withdrawalActionTypes.withdrawalDeletedFail({message: getErrorRequest(err)}));
          return caught;
        }),
      );
    }
  );
}
