import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConversationFilter} from '../../../core/model/conversation/conversation-filter.model';
import {Conversation} from '../../../core/model/conversation/conversation.model';
import {first} from 'rxjs/operators';
import {AngularFireDatabase, SnapshotAction} from '@angular/fire/compat/database';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CreateConversation} from '../../../core/model/conversation/create-conversation.model';
import {ListUser} from '../../../core/model/user/list-user.model';
import {UserService} from '../user/user.service';
import {loadConversation} from '../../../core/store/conversation/conversation.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../core/store/app.state';
import {Router} from '@angular/router';


@Injectable({providedIn: 'root'})
export class ConversationService {
  public resourceUrl = environment.SERVER_API_URL + 'api/conversations';

  constructor(private db: AngularFireDatabase,
              private userService: UserService,
              private store: Store<AppState>,
              private router: Router,
              private http: HttpClient) {
  }

  create(createConversation: CreateConversation): Observable<void> {
    return this.http.post<void>(this.resourceUrl, createConversation);
  }

  createKey(createConversation: CreateConversation): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'text/plain, */*',
        'Content-Type': 'application/json' // We send JSON
      }),
      responseType: 'text' as 'json'  // We accept plain text as response.
    };
    return this.http.post<any>(`${this.resourceUrl}/key`, createConversation, httpOptions);
  }

  query(conversationFilter: ConversationFilter): Observable<Conversation[]> {

    let query = ref => ref.orderByChild('lastMessageTimestamp').limitToLast(conversationFilter.limitToLast);
    if (conversationFilter.lastMessageTimeStamp) {
      query = ref => ref.orderByChild('lastMessageTimestamp')
        .endAt(conversationFilter.lastMessageTimeStamp)
        .limitToLast(conversationFilter.limitToLast);
    }

    return this.db.list<Conversation>('userKeyRoom/' + conversationFilter.userId, query).valueChanges().pipe(first());
  }

  get(key: string, userId: string): Observable<Conversation[]> {
    const query = ref => ref.orderByChild('key').equalTo(key);

    return this.db.list<Conversation>('userKeyRoom/' + userId, query).valueChanges().pipe(first());
  }


  stateChangeAdded(userId): Observable<SnapshotAction<Conversation>> {
    const time = new Date().getTime().toString();
    return this.db.list<Conversation>('userKeyRoom/' + userId,
      ref => ref.orderByChild('lastActionTimestamp').startAt(time))
      .stateChanges(['child_added', 'child_changed', 'child_removed']);
  }

  count(userId: string): Observable<boolean | boolean[]> {
    const query = ref => ref.orderByChild('isUnread').equalTo(true);

    return this.db.list<boolean>('userKeyRoom/' + userId, query)
      .valueChanges();
  }

  getName(usersDest: ListUser[], isLongName = false): string {
    const usersDestTmp = [...usersDest];

    if (!usersDest[0]) {
      return 'Utilisateur inconnu';
    }

    let currentName = usersDest[0].username;
    let currentNameTmp = usersDest[0].username;
    let count = 1;
    usersDestTmp.shift();

    for (const userDest of usersDestTmp) {
      currentNameTmp = currentNameTmp + ', ' + userDest.username;

      if (!isLongName && currentNameTmp.length >= 25) {
        return currentName + ' et ...';
      }

      if (isLongName && currentNameTmp.length >= 30) {
        const moduloUser = usersDestTmp.length - count;
        const addPlurial = moduloUser > 1 ? 's' : '';
        return currentName + ' et ' + moduloUser + ' autre' + addPlurial + ' personne' + addPlurial;
      }

      currentName = currentNameTmp;
      count = count++;
    }
    return currentName;
  }

  openConversation(conversation: Conversation, selectConversation: Conversation, id: string): void {
    if (!(selectConversation && conversation.key === selectConversation.key)) {
      const conversationClone = JSON.parse(JSON.stringify(conversation));
      this.injectUserDest(conversationClone, id);
      this.store.dispatch(loadConversation({conversation: conversationClone}));
    }
  }

  injectUserDest(conversation: Conversation, id: string): void {
    const ids = conversation.key.split('-');
    const index = ids.indexOf(id, 0);
    if (index > -1) {
      ids.splice(index, 1);
    }
    conversation.usersDest = this.userService.query({
      ids
    });
  }
}
