import {
  conversationsCountDone,
  conversationsCountFail,
  conversationsLoadedDone,
  conversationsLoadedFail,
  conversationsNavbarLoadedDone,
  conversationsNavbarLoadedFail,
  countConversationsUnread,
  loadConversations,
  loadConversationsNavbar
} from './conversation.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Conversation} from '../../model/conversation/conversation.model';
import {ConversationService} from '../../../shared/services/message/conversation.service';
import {MessageService} from '../../../shared/services/message/message.service';
import {UserService} from '../../../shared/services/user/user.service';
import {AppState} from '../app.state';

@Injectable()
export class ConversationEffects {

  loadConversations$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(loadConversations),
        switchMap((action) => this.conversationService.query(action.conversationFilter)),
        map((conversations: Conversation[]) => conversations.reverse()),
        map((conversations: Conversation[]) => conversationsLoadedDone({conversations})),
        catchError((err, caught) => {
          this.store.dispatch(conversationsLoadedFail());
          return caught;
        })
      );
    }
  );

  loadConversationsNavbar$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(loadConversationsNavbar),
        switchMap((action) => this.conversationService.query(action.conversationFilter)),
        map((conversations: Conversation[]) => conversations.reverse()),
        map((conversations: Conversation[]) => conversationsNavbarLoadedDone({conversations})),
        catchError((err, caught) => {
          this.store.dispatch(conversationsNavbarLoadedFail());
          return caught;
        })
      );
    }
  );

  countConversationsUnread$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(countConversationsUnread),
        switchMap((action) => this.conversationService.count(action.userId)),
        map((nbUnread: boolean[]) => conversationsCountDone({nbUnread})),
        catchError((err, caught) => {
          this.store.dispatch(conversationsCountFail());
          return caught;
        })
      );
    }
  );

  constructor(
    private conversationService: ConversationService,
    private messageService: MessageService,
    private userService: UserService,
    private actions$: Actions,
    private store: Store<AppState>) {
  }
}
