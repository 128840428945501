import {followUserActionTypes} from './follow-user.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {User} from '../../model/user/user.model';
import {STATE_ONE_USER} from '../../transfert-state/state';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';
import {getErrorRequest} from '../../../shared/services/util/request-util';
import {FollowUserService} from '../../../shared/services/follow-user.service';

@Injectable()
export class FollowUserEffects {

  loadFollowUser$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(followUserActionTypes.loadFollowUser),
        switchMap((action) =>
          this.utilsTransfertsState.getStateOrQuery(STATE_ONE_USER, this.followUserService.querySubscriptions(action.filters))
        ),
        map(followUsers => followUserActionTypes.loadFollowUserDone({followUsers})),
        catchError((err, caught) => {
          this.store.dispatch(followUserActionTypes.loadFollowUserFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  constructor(
    private followUserService: FollowUserService,
    private actions$: Actions,
    private utilsTransfertsState: UtilsTransfertsState,
    private store: Store<User>) {
  }
}
