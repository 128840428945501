import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {FilterFollowUser} from '../../core/model/follow-user/filter-follow-user.model';
import {IFollowUser} from '../../core/model/follow-user/follow-user.model';
import {createRequestOption} from './util/request-util';
import {Page} from '../../core/model/page.model';

@Injectable({providedIn: 'root'})
export class FollowUserService {
  public resourceUrl = environment.SERVER_API_URL + 'api/users';

  constructor(private http: HttpClient) {
  }

  addFollowUser(id: string): Observable<void> {
    return this.http.post<void>(`${this.resourceUrl}/${id}/follow`, id);
  }

  removeFollowUser(id: string): Observable<void> {
    return this.http.delete<void>(`${this.resourceUrl}/${id}/follow`);
  }

  querySubscriptions(filter: FilterFollowUser): Observable<Page<IFollowUser> | null> {
    const options = createRequestOption(filter);
    return this.http.get<Page<IFollowUser>>(`${this.resourceUrl}/me/subscriptions`, {params: options});
  }

  querySubscriber(filter: FilterFollowUser): Observable<Page<IFollowUser> | null> {
    const options = createRequestOption(filter);
    return this.http.get<Page<IFollowUser>>(`${this.resourceUrl}/me/subscribers`, {params: options});
  }
}
