import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StateStorageService} from '../../shared/services/auth/state-storage.service';
import {AuthService} from '../../shared/services/auth/auth.service';
import {User} from '../model/user/user.model';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(private stateStorageService: StateStorageService, public authService: AuthService, public router: Router) {
  }

  private static isConnected(user: User): boolean {
    return user && user.id != null;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const authorities = route.data.authorities;

    return this.checkLogin(authorities, state.url);
  }

  private checkLogin(authorities: string[], url: string): any {
    return this.authService.identity().pipe(
      map((user: User) => {


        if (AuthGuard.isConnected(user)) {
          if (url.startsWith('/connexion') || url.startsWith('/inscription') || url.startsWith('/mot-de-passe-oublie') || url.startsWith('/complete-compte')) {
            this.router.navigate(['']).then();
            return false;
          }

          if (user.emailVerified && url.startsWith('/verification-email')) {
            this.router.navigate(['']).then();
            return false;
          }

          if (!authorities || authorities.length === 0) {
            return true;
          }

          const hasAnyAuthority = this.authService.hasAnyAuthority(authorities);

          if (hasAnyAuthority) {
            return true;
          }

          // TODO A voir pour changer en access denied
          this.router.navigate(['']).then();
          return false;
        } else {

          if (url.startsWith('/complete-compte') && this.authService.currentUserFirebase() == null) {
            this.router.navigate(['/connexion']).then();
            return false;
          }

          if (url.startsWith('/connexion') || url.startsWith('/inscription')) {
            return true;
          }

          if (!authorities || authorities.length === 0) {
            return true;
          }

          this.stateStorageService.storeUrl(url);
          this.router.navigate(['/connexion']).then();
          return false;
        }
      }));
  }
}
