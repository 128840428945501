import {Component} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NotificationsService} from '../../services/util/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
        style({transform: 'translateX(-100%)'}),
        animate('100ms ease-in-out')
      ]),
      transition('* => void', [
        animate('200ms ease-in-out', style({opacity: 0}))
      ])
    ])
  ]
})
export class NotificationsComponent {

  notifications$;

  constructor(private _notificationsService: NotificationsService) {
    this.notifications$ = this._notificationsService.getNotifications();
  }

  trackById(index, item): void {
    return item.id;
  }
}
