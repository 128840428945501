import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../../core/model/user/user.model';
import {
  clearNotifications,
  countNotifications,
  loadNotifications,
  notificationActionTypes,
  resetCountNotifications,
  stateChangeNotification
} from '../../../../../core/store/notification/notification.actions';
import {NotificationFirebaseFilter} from '../../../../../core/model/notification/notification-firebase-filter.model';
import {AppState} from '../../../../../core/store/app.state';
import {Store} from '@ngrx/store';
import {NotificationFirebase} from '../../../../../core/model/notification/notification-firebase.model';
import {Observable} from 'rxjs';
import {
  getAllNotifications,
  getCountNotification,
  getNotificationFilter,
  isLoadingNotificationList,
  noMoreNotificationInFirebase
} from '../../../../../core/store/notification/notification.selectors';
import {filter, switchMap, take} from 'rxjs/operators';
import {SsrContext} from '../../../../services/util/ssr-context.service';
import {getClassHeader} from '../../../../../core/store/header/header.selectors';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Component({
  selector: 'app-nav-bar-notification-component',
  templateUrl: './navbar-notification.component.html',
  styleUrls: ['./navbar-notification.component.scss', '../navbar-items.component.scss'],
})
export class NavbarNotificationComponent implements OnInit, OnDestroy {
  @Input() user: User;
  notifications: Observable<NotificationFirebase[]>;
  countNotification: Observable<number | number[]>;
  isLoading: Observable<boolean>;
  classHeader: Observable<string>;
  classShowHeader: string;

  private isFirstLoadNotifications = true;

  constructor(private store: Store<AppState>,
              private afAuth: AngularFireAuth,
              private db: AngularFireDatabase,
              private ssrContext: SsrContext) {
  }

  ngOnInit(): void {
    this.classHeader = this.store.select(getClassHeader);
    this.isLoading = this.store.select(isLoadingNotificationList);
    this.notifications = this.store.select(getAllNotifications);
    this.countNotification = this.store.select(getCountNotification);
    this.initCountNotification();
  }

  private initCountNotification(): void {
    if (!this.ssrContext.isServer()) {
      this.afAuth.authState.pipe(take(1)).subscribe(user => {
        this.db.database.app.auth().onAuthStateChanged(userDb => {
          if (userDb) {
            this.store.dispatch(countNotifications({notificationFilter: new NotificationFirebaseFilter(user?.uid)}));
          }
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearNotifications());
  }

  loadNotification(): void {

    setTimeout(() => {
      this.store.dispatch(resetCountNotifications());
    }, 5000);

    if (this.isFirstLoadNotifications) {
      this.isFirstLoadNotifications = false;
      this.store.dispatch(loadNotifications({notificationFilter: new NotificationFirebaseFilter(this.user.id)}));
      this.store.dispatch(stateChangeNotification({userId: this.user.id}));
    }
  }

  onScroll(event: any): void {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 70) {
      this.store.select(isLoadingNotificationList).pipe(take(1))
        .pipe(
          filter(isLoading => !isLoading),
          switchMap(() => this.store.select(noMoreNotificationInFirebase).pipe(take(1))),
          filter(noMoreMessage => !noMoreMessage),
          switchMap(() => this.store.select(getNotificationFilter).pipe(take(1))),
        )
        .subscribe((notificationFilter: NotificationFirebaseFilter) => {
          this.store.dispatch(notificationActionTypes.loadNotifications(
            {notificationFilter: new NotificationFirebaseFilter(this.user.id, notificationFilter.lastCreationDate)}
          ));
        });
    }
  }

  @HostListener('window:scroll', ['$event'])
  scrollPage(): void {
    if (window.scrollY > 70) {
      this.classShowHeader = 'inner-page';
    } else {
      this.classShowHeader = '';
    }
  }

  getIcon(notification: NotificationFirebase): string {
    if (notification.urlIcon.startsWith('data')) {
      return notification.urlIcon;
    }
    return '/assets/images/header/notification/' + notification.urlIcon;
  }
}
