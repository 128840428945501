import {createReducer, on} from '@ngrx/store';
import {headerActionTypes} from './header.actions';

export interface HeaderState {
  classHeader: string;
}

export const initialState = {
  classHeader: '',
};

export const headerReducer = createReducer(
  initialState,

  on(headerActionTypes.setClassHeader, (state, action) => ({
      ...state,
      classHeader: action.classHeader,
    }
  )),
);
