import {createAction, props} from '@ngrx/store';
import {IReport} from '../../model/report/report.model';
import {ICreateReport} from '../../model/report/create-report.model';


export const createReport = createAction(
  '[Reports Effect] Reports create',
  props<{ createReport: ICreateReport }>()
);

export const createReportDone = createAction(
  '[Reports Effect] Reports create Successfully',
  props<{ report: IReport }>()
);

export const createReportFail = createAction(
  '[Reports Effect] Reports create failed',
  props<{ message: string }>()
);

export const clearReport = createAction(
  '[Reports Effect] Reports clear failed'
);

export const reportActionTypes = {
  createReport,
  createReportDone,
  createReportFail,
  clearReport,
};
