import {createAction, props} from '@ngrx/store';


export const setClassHeader = createAction(
  '[Users Effect] set class header',
  props<{ classHeader: string }>()
);


export const headerActionTypes = {
  setClassHeader,
};
