import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {StateStorageService} from './state-storage.service';
import {from, Observable, of, ReplaySubject} from 'rxjs';
import {User} from '../../../core/model/user/user.model';
import {UserService} from '../user/user.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {CookieService} from '../util/cookie/cookie.service';
import {ResponseRefreshTokenFirebase} from '../../../core/model/firebase/response-refresh-token-firebase.model';
import {TransferState} from '@angular/platform-browser';
import {ResponseValidationEmailFirebase} from '../../../core/model/firebase/response-validation-email-firebase.model';
import {STATE_CURRENT_USER} from '../../../core/transfert-state/state';
import {AppState} from '../../../core/store/app.state';
import {Store} from '@ngrx/store';
import {SsrContext} from '../util/ssr-context.service';
import {countNotifications} from '../../../core/store/notification/notification.actions';
import {NotificationFirebaseFilter} from '../../../core/model/notification/notification-firebase-filter.model';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {FacebookAuthProvider, getAuth, GoogleAuthProvider} from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public router: Router,
    private stateStorageService: StateStorageService,
    private http: HttpClient,
    private userService: UserService,
    private cookieService: CookieService,
    private state: TransferState,
    public afAuth: AngularFireAuth,
    public db: AngularFireDatabase,
    public store: Store<AppState>,
    public ssrContext: SsrContext,
  ) {
  }

  private userState = new ReplaySubject<User | null>(1);
  private accountCache$?: Observable<User | null>;

  private readonly ID_TOKEN_COOKIE = 'idToken';
  private readonly REFRESH_TOKEN_COOKIE = 'refreshToken';
  private readonly UID_COOKIE = 'uid';
  private readonly USER_FIREBASE = 'userFirebase';

  googleAuth(): Observable<any> {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({prompt: 'select_account'});
    return this.authLogin(provider);
  }

  facebookAuth(): Observable<any> {
    return this.authLogin(new FacebookAuthProvider());
  }

  signIn(email: string, password: string): void {
    this.afAuth.signInWithEmailAndPassword(email, password).then(user => this.setCookieUserFirebase(user));
  }

  authLogin(provider): Observable<any> {
    return from(this.afAuth.signInWithPopup(provider))
      .pipe(
        switchMap(
          (result: any) => from(result.user.getIdToken())
            .pipe(tap((idToken: string) => {
              this.setCookieUserFirebase(result.user);
              this.setCookieAuth(idToken, result.user.refreshToken, result.user.uid);
            }))
        ),
        switchMap(() => this.identity(true)),
        tap(user => {
          if (user) {
            this.navigateToStoredUrl();
          } else {
            this.clearCookieAuth();
            this.router.navigate(['complete-compte']).then();
          }
        }),
      );
  }


  refreshToken(): Observable<string> {
    const refreshToken = this.cookieService.getCookieService().getItem('refreshToken');

    return this.http.post<ResponseRefreshTokenFirebase>
    ('https://securetoken.googleapis.com/v1/token?key=' + environment.firebaseConfig.apiKey,
      {grant_type: 'refresh_token', refresh_token: refreshToken})
      .pipe(
        tap((res: ResponseRefreshTokenFirebase) => this.setCookieAuth(res.id_token, res.refresh_token, res.user_id)),
        map((res: ResponseRefreshTokenFirebase) => res.id_token),
        catchError((err, caught) => {
          this.signOut();
          return caught;
        })
      );
  }

  setCookieAuth(idToken: string, refreshToken: string, uid: string): void {
    const expireIdToken = new Date();
    expireIdToken.setMinutes(expireIdToken.getMinutes() + 55);
    this.cookieService.getCookieService().setItem(this.ID_TOKEN_COOKIE, idToken, expireIdToken);
    this.cookieService.getCookieService().setItem(this.REFRESH_TOKEN_COOKIE, refreshToken);
    this.cookieService.getCookieService().setItem(this.UID_COOKIE, uid);
  }

  setCookieUserFirebase(user: any): void {
    this.cookieService.getCookieService().setItem(this.USER_FIREBASE, JSON.stringify(user.user));
  }

  clearCookieAuth(): void {
    this.cookieService.getCookieService().removeItem(this.ID_TOKEN_COOKIE);
    this.cookieService.getCookieService().removeItem(this.REFRESH_TOKEN_COOKIE);
    this.cookieService.getCookieService().removeItem(this.UID_COOKIE);
    this.cookieService.getCookieService().removeItem(this.USER_FIREBASE);
  }

  getIdToken(): Observable<string> {
    const idToken = this.cookieService.getCookieService().getItem(this.ID_TOKEN_COOKIE);
    if (this.checkRefreshTokenCookie() && !idToken) {
      return this.refreshToken();
    }

    return of(idToken);
  }

  private getUidCookie(): string {
    return this.cookieService.getCookieService().getItem(this.UID_COOKIE);
  }

  private checkRefreshTokenCookie(): boolean {
    return this.cookieService.getCookieService().getItem(this.REFRESH_TOKEN_COOKIE) != null;
  }

  private checkUidCookie(): boolean {
    return this.cookieService.getCookieService().getItem(this.UID_COOKIE) != null;
  }

  emailValidation(oobCode: string): Observable<ResponseValidationEmailFirebase> {
    return this.http.post<ResponseValidationEmailFirebase>
    ('https://identitytoolkit.googleapis.com/v1/accounts:update?key=' + environment.firebaseConfig.apiKey, {oobCode});
  }


  confirmPasswordReset(password: string, oobCode: string): Observable<any> {
    return this.http.post<any>
    ('https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=' + environment.firebaseConfig.apiKey,
      {oobCode, newPassword: password});
  }

  isAuthenticated(): boolean {
    return this.checkRefreshTokenCookie() && this.userIdentity() !== null;
  }

  canAuthenticated(): boolean {
    return this.checkRefreshTokenCookie() && this.checkUidCookie();
  }

  currentUserFirebase(): any {
    return getAuth().currentUser;
  }

  signOut(): void {
    this.clearCookieAuth();
    this.setCurrentUser(null);
    this.afAuth.signOut().then();
    window.location.reload();
  }

  identity(force?: boolean): Observable<User | null> {
    if ((!this.accountCache$ || force || !this.isAuthenticated())) {
      if (!this.canAuthenticated()) {
        return of(null);
      }
      this.accountCache$ = this.userService.find(this.getUidCookie()).pipe(
        catchError(() => {
          return of(null);
        }),
        tap((user: User | null) => {
          this.setCurrentUser(user);
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  setCurrentUser(identity: User | null): void {
    this.state.set(STATE_CURRENT_USER, identity);
    this.userState.next(identity);
    if (!this.ssrContext.isServer()) {
      this.afAuth.authState.subscribe(userFirebase => {
        this.db.database.app.auth().updateCurrentUser(userFirebase).then(() => {
          this.store.dispatch(countNotifications({notificationFilter: new NotificationFirebaseFilter(userFirebase?.uid)}));
        });
      });
    }
  }

  navigateToStoredUrl(): void {
    let previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      previousUrl = decodeURIComponent(previousUrl);
      this.stateStorageService.clearUrl();
      if (previousUrl.includes('?')) {
        const allUrl = previousUrl.split('?');
        this.router.navigate([allUrl[0]], {queryParams: this.queryStringToJSON(allUrl[1])}).then();
      } else {
        this.router.navigate([previousUrl]).then();
      }
    } else {
      this.router.navigate(['']).then();
    }
  }

  queryStringToJSON(qs): any {
    qs = qs || location.search.slice(1);

    const pairs = qs.split('&');
    const result = {};
    pairs.forEach(p => {
      const pair = p.split('=');
      const key = pair[0];
      const value = decodeURIComponent(pair[1] || '');

      if (result[key]) {
        if (Object.prototype.toString.call(result[key]) === '[object Array]') {
          result[key].push(value);
        } else {
          result[key] = [result[key], value];
        }
      } else {
        result[key] = value;
      }
    });

    return JSON.parse(JSON.stringify(result));
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.userIdentity() || !this.userIdentity().authorities) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.userIdentity().authorities.some((authority: string) => authorities.includes(authority));
  }

  signUp(user: User): Observable<User> {
    return this.userService.create(user);
  }

  getUserState(): Observable<User | null> {
    return this.userState.asObservable();
  }

  userIdentity(): User {
    return this.state.get<User>(STATE_CURRENT_USER, null);
  }

  isAdmin(): boolean {
    if (!this.userIdentity() || !this.userIdentity().authorities) {
      return false;
    }

    return this.userIdentity().authorities.some((authority: string) => 'ADMIN' === authority);
  }

  hasRole(role: string): boolean {
    if (!this.userIdentity() || !this.userIdentity().authorities) {
      return false;
    }

    return this.userIdentity().authorities.some((authority: string) => role === authority);
  }
}
