import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AppState} from '../../core/store/app.state';
import {Store} from '@ngrx/store';
import {getCountNotification} from '../../core/store/notification/notification.selectors';
import {getCountConversations} from '../../core/store/conversation/conversation.selectors';
import {SsrContext} from './util/ssr-context.service';
import { DOCUMENT } from '@angular/common';

@Injectable({providedIn: 'root'})
export class SEOService {
  nbNotif = 0;
  notifCount = 0;
  messageCount = 0;
  titleSEO = '';
  favIcon32: HTMLLinkElement;
  favIcon16: HTMLLinkElement;

  constructor(private title: Title,
              private meta: Meta,
              private store: Store<AppState>,
              private ssrContext: SsrContext,
              @Inject(DOCUMENT) private dom,
              private router: Router) {
    if (!this.ssrContext.isServer()) {
      this.favIcon32 = document.querySelector('#appIcon32');
      this.favIcon16 = document.querySelector('#appIcon16');
    }
  }

  updateSEO(title: string, desc: string, img?: string): void {
    this.titleSEO = title.substring(0, 62) + ' • Tooda';
    const descSEO = desc.substring(0, 159);

    this.changeTitleWithCount();
    this.meta.updateTag({name: 'description', content: descSEO});

    this.meta.updateTag({name: 'og:type', content: 'website'});
    this.meta.updateTag({name: 'og:url', content: 'https://tooda.fr' + this.router.url});
    this.meta.updateTag({name: 'og:title', content: this.titleSEO});
    this.meta.updateTag({name: 'og:description', content: descSEO});
    this.meta.updateTag({name: 'og:image', content: img ?? 'https://tooda.fr/assets/images/tooda_logo_og.png'});

    this.meta.updateTag({name: 'twitter:card', content: 'summary_large_image'});
    this.meta.updateTag({name: 'twitter:title', content: this.titleSEO});
    this.meta.updateTag({name: 'twitter:site', content: 'Tooda'});
    this.meta.updateTag({name: 'twitter:description', content: descSEO});
    this.meta.updateTag({name: 'twitter:url', content: 'https://tooda.fr' + this.router.url});
    this.meta.updateTag({name: 'twitter:image', content: img ?? 'https://tooda.fr/assets/images/tooda_logo_og.png'});

    this.store.select(getCountNotification).subscribe(count => {
      this.notifCount = count;
      this.nbNotif = this.notifCount + this.messageCount;

      this.changeTitleWithCount();
    });

    this.store.select(getCountConversations).subscribe(count => {
      this.messageCount = count;
      this.nbNotif = this.notifCount + this.messageCount;

      this.changeTitleWithCount();
    });

    this.updateCanonicalUrl('https://tooda.fr' + this.router.url);
  }

  updateCanonicalUrl(url: string): void {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  private changeTitleWithCount(): void {
    if (this.nbNotif > 0) {
      const nbNotifTitle = this.nbNotif > 99 ? '99+' : this.nbNotif;
      this.title.setTitle('(' + nbNotifTitle + ') ' + this.titleSEO);
      if (this.favIcon32 && this.favIcon16) {
        this.favIcon32.href = '/assets/favicon-32x32-notif.png';
        this.favIcon16.href = '/assets/favicon-16x16-notif.png';
      }
    } else {
      this.title.setTitle(this.titleSEO);
      if (this.favIcon32 && this.favIcon16) {
        this.favIcon32.href = '/assets/favicon-32x32.png';
        this.favIcon16.href = '/assets/favicon-16x16.png';
      }
    }
  }
}
