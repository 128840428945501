import {createAction, props} from '@ngrx/store';
import {Address} from '../../model/address/address';
import {FilterAddress} from '../../model/address/filter-address.model';
import {Page} from '../../model/page.model';
import {CreateAddress} from '../../model/address/create-address.model';
import {PatchAddress} from '../../model/address/patch-address.model';


export const loadListAddress = createAction(
  '[Address Effect] Load Addresss via Service',
  props<{ filters: FilterAddress }>()
);

export const addressListLoadedDone = createAction(
  '[Address Effect] Addresss Loaded Successfully',
  props<{ addresss: Page<Address> }>()
);

export const addressListLoadedFail = createAction(
  '[Address Effect] Addresss Loaded failed',
  props<{ message: string }>()
);

export const loadOneAddress = createAction(
  '[Addresss List] Load Address via Service',
  props<{ id: string | number, userId: string }>()
);

export const addressOneLoadedDone = createAction(
  '[Address Effect] Address Loaded Successfully',
  props<{ address: Address }>()
);

export const addressOneLoadedFail = createAction(
  '[Address Effect] Address Loaded Fail',
  props<{ message: string }>()
);

export const createAddress = createAction(
  '[Address Effect] Create Address',
  props<{ createAddress: CreateAddress, userId: string }>()
);

export const addressCreatedDone = createAction(
  '[Address Effect] Address Created Successfully',
  props<{ address: Address }>()
);

export const addressCreatedFail = createAction(
  '[Address Effect] Address Created failed',
  props<{ message: string }>()
);

export const patchAddress = createAction(
  '[Address Effect] Update Address',
  props<{ patchAddress: PatchAddress, userId: string }>()
);

export const addressPatchDone = createAction(
  '[Address Effect] Address Updated Successfully',
  props<{ address: Address }>()
);

export const addressPatchFail = createAction(
  '[Address Effect] Address Updated fail',
  props<{ message: string }>()
);


export const deleteAddress = createAction(
  '[Address Effect] Delete Address',
  props<{ id: string | number, userId: string }>()
);

export const addressDeletedDone = createAction(
  '[Address Effect] Address Deleted Successfully',
  props<{ address: Address }>()
);

export const addressDeletedFail = createAction(
  '[Address Effect] Address Deleted fail',
  props<{ message: string }>()
);

export const clearAddress = createAction(
  '[Address Effect] Address clear all'
);


export const addressActionTypes = {
  loadListAddress,
  addressListLoadedDone,
  addressListLoadedFail,
  loadOneAddress,
  addressOneLoadedDone,
  addressOneLoadedFail,
  createAddress,
  addressCreatedDone,
  addressCreatedFail,
  patchAddress,
  addressPatchDone,
  addressPatchFail,
  deleteAddress,
  addressDeletedDone,
  addressDeletedFail,
  clearAddress,
};
