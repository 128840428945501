import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import { BannerComponent } from './components/ad/banner/banner.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    BannerComponent
  ],
    exports: [
        CommonModule,
        FormsModule,
        BannerComponent,
    ]
})
export class SharedModule {
  constructor() {
  }
}
