import {NgModule} from '@angular/core';
import {OpinionFormComponent} from './opinion-form.component';
import {SharedModule} from '../../../shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    OpinionFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [
    OpinionFormComponent
  ],
  providers: [],
})
export class OpinionFormModule {
}
