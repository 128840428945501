import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {meansPaymentActionTypes} from './means-payment.actions';
import {UserService} from '../../../shared/services/user/user.service';
import {PaginationService} from '../../../shared/services/pagination.service';
import {MeansPayment} from '../../model/means-payment/means-payment';
import {MeansPaymentService} from '../../../shared/services/user/means-payment.service';
import {Page} from '../../model/page.model';
import {getErrorRequest} from '../../../shared/services/util/request-util';
import {Notif} from '../../model/notification/notif.model';
import {NotificationsService} from '../../../shared/services/util/notifications.service';
import {MeansPaymentTranslate} from './means-payment.translate';

@Injectable()
export class MeansPaymentEffects {

  constructor(
    private meansPaymentService: MeansPaymentService,
    private userService: UserService,
    private actions$: Actions,
    private router: Router,
    private notificationsService: NotificationsService,
    private store: Store<MeansPayment>,
    private paginationService: PaginationService) {
  }

  loadMeansPayments$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(meansPaymentActionTypes.loadListMeansPayment),
        switchMap((action) => this.meansPaymentService.query(action.filters)),
        tap((meansPayments: Page<MeansPayment>) => this.paginationService.checkCurrentPageHaveItemsOrRedirect(meansPayments)),
        map(meansPayments => meansPaymentActionTypes.meansPaymentListLoadedDone({meansPayments})),
        catchError((err, caught) => {
          const notification = new Notif({text: MeansPaymentTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(meansPaymentActionTypes.meansPaymentListLoadedFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  loadMeansPayment$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(meansPaymentActionTypes.loadOneMeansPayment),
        switchMap((action) => this.meansPaymentService.find(action.userId, action.id)),
        map(meansPayment => meansPaymentActionTypes.meansPaymentOneLoadedDone({meansPayment})),
        catchError((err, caught) => {
          const notification = new Notif({text: MeansPaymentTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(meansPaymentActionTypes.meansPaymentOneLoadedFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  createMeansPayment$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(meansPaymentActionTypes.createMeansPayment),
        switchMap((action) => this.meansPaymentService.create(action.userId, action.createMeansPayment)),
        tap(() => {
          const notification = new Notif({
            text: 'Création du moyen de paiement réussi',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }),
        map((meansPayment: MeansPayment) => meansPaymentActionTypes.meansPaymentCreatedDone({meansPayment})),
        catchError((err, caught) => {
          const notification = new Notif({text: MeansPaymentTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(meansPaymentActionTypes.meansPaymentCreatedFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  updateMeansPayment$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(meansPaymentActionTypes.patchMeansPayment),
        switchMap((action) => this.meansPaymentService.patch(action.userId, action.patchMeansPayment)),
        tap(() => {
          const notification = new Notif({
            text: 'Modification du moyen de paiement réussi',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }),
        map(meansPayment => meansPaymentActionTypes.meansPaymentPatchDone({meansPayment})),
        catchError((err, caught) => {
          const notification = new Notif({text: MeansPaymentTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(meansPaymentActionTypes.meansPaymentPatchFail({message: getErrorRequest(err)}));
          return caught;
        }),
      );
    }
  );

  deleteMeansPayment$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(meansPaymentActionTypes.deleteMeansPayment),
        switchMap((action) => this.meansPaymentService.delete(action.userId, action.id)),
        map(meansPayment => meansPaymentActionTypes.meansPaymentDeletedDone({meansPayment})),
        tap(() => {
          const notification = new Notif({
            text: 'Suppresion du moyen de paiement réussi',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }),
        catchError((err, caught) => {
          const notification = new Notif({text: MeansPaymentTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(meansPaymentActionTypes.meansPaymentDeletedFail({message: getErrorRequest(err)}));
          return caught;
        }),
      );
    }
  );
}
