import { GlobalTranslate } from 'src/app/features/global.translate';

export class AddressTranslate extends GlobalTranslate {
  static max_address = 'La limite est de 8 adresses enregistrées';
  static name_is_required = 'Veuillez donner un nom à l\'adresse';
  static full_name_is_required = 'Veuillez indiquer le nom complet pour cette adresse';
  static address_is_required = 'L\'adresse est requise';
  static postal_code_is_required = 'Veuillez saisir le code postal';
  static country_code_is_required = 'Veuillez sélectionner un pays';
  static city_code_is_required = 'Veuillez indiquer la ville';
  static phone_number_is_required = 'Veuillez indiquer votre numéro de téléphone';
  static address_not_found = 'Adresse introuvable, veuillez vérifier et réessayer';
  static name_is_invalid = 'Le nom d\'adresse saisi est invalide';
  static full_name_is_invalid = 'Le nom complet saisi pour l\'adresse est invalide';
  static invalid_country = 'Le pays sélectionné est invalide';
  static invalid_city = 'La ville saisie est invalide';
  static invalid_phone_number = 'Le numéro de téléphone est invalide';
  static invalid_postal_code = 'Le code postal saisi est invalide';
}
