import {algoliaActionTypes} from './algolia.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {User} from '../../model/user/user.model';
import {STATE_MULTI_ALGOLIA, STATE_MULTI_ALGOLIA_PAGE} from '../../transfert-state/state';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';
import {getErrorRequest} from '../../../shared/services/util/request-util';
import {AlgoliaService} from '../../../shared/services/algolia.service';

@Injectable()
export class AlgoliaEffects {

  loadMultiAlgolia$ = createEffect(() =>{
    const actions = inject(Actions);
    return actions.pipe(
      ofType(algoliaActionTypes.loadMultiAlgolia),
      switchMap((action) =>
        this.utilsTransfertsState.getStateOrQuery(STATE_MULTI_ALGOLIA, this.algoliaService.findMultiIndex(action.filter))),
      map(results => algoliaActionTypes.loadMultiAlgoliaDone({results})),
      catchError((err, caught) => {
        this.store.dispatch(algoliaActionTypes.loadMultiAlgoliaFail({message: getErrorRequest(err)}));
        return caught;
      })
    )}
  );

  loadMultiAlgoliaPage$ = createEffect(() =>{
    const actions = inject(Actions);
    return actions.pipe(
      ofType(algoliaActionTypes.loadMultiAlgoliaPage),
      switchMap((action) =>
        this.utilsTransfertsState.getStateOrQuery(STATE_MULTI_ALGOLIA_PAGE, this.algoliaService.findMultiIndex(action.filter))),
      map(results => algoliaActionTypes.loadMultiAlgoliaDonePage({results})),
      catchError((err, caught) => {
        this.store.dispatch(algoliaActionTypes.loadMultiAlgoliaFailPage({message: getErrorRequest(err)}));
        return caught;
      })
    )}
  );

  loadAlgolia$ = createEffect(() =>{
    const actions = inject(Actions);
    return actions.pipe(
      ofType(algoliaActionTypes.loadAlgolia),
      switchMap((action) =>
        this.utilsTransfertsState.getStateOrQuery(STATE_MULTI_ALGOLIA_PAGE, this.algoliaService.findOneIndex(action.filter))),
      map(results => algoliaActionTypes.loadAlgoliaDone({results})),
      catchError((err, caught) => {
        this.store.dispatch(algoliaActionTypes.loadAlgoliaFail({message: getErrorRequest(err)}));
        return caught;
      })
    )}
  );

  constructor(
    private algoliaService: AlgoliaService,
    private actions$: Actions,
    private utilsTransfertsState: UtilsTransfertsState,
    private store: Store<User>) {
  }
}
