import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {IUser, User} from '../../../core/model/user/user.model';
import {Page} from '../../../core/model/page.model';
import {createRequestOption} from '../util/request-util';
import {UserSignIn} from '../../../core/model/user/user-sign-in.model';
import {FormGroup} from '@angular/forms';
import {PatchUser} from '../../../core/model/user/patch-user.model';
import {IUserInfo} from '../../../core/model/user/user-info.model';
import {ListUser} from '../../../core/model/user/list-user.model';
import {FilterDashboardUser} from '../../../core/model/user/filter-dashboard-user.model';
import {MonetizationDashboardUser} from '../../../core/model/monetization/monetization-info-user.model';

@Injectable({providedIn: 'root'})
export class UserService {
  public resourceUrl = environment.SERVER_API_URL + 'api/users';

  constructor(private http: HttpClient) {
  }

  signIn(userSignIn: UserSignIn): Observable<IUser | null> {
    userSignIn.key = environment.firebaseConfig.apiKey;
    return this.http.post<IUser>(`${this.resourceUrl}/sign-in`, userSignIn);
  }

  find(userId: string | null): Observable<IUser | null> {
    return this.http.get<IUser>(`${this.resourceUrl}/${userId}`);
  }

  findByUsername(username: string): Observable<IUser | null> {
    return this.http.get<IUser>(`${this.resourceUrl}/username/${username}`);
  }

  create(user: User): Observable<IUser> {
    return this.http.post<IUser>(this.resourceUrl, user);
  }

  patch(patchUser: PatchUser): Observable<IUser> {
    return this.http.patch<IUser>(`${this.resourceUrl}/${patchUser.userId}`, patchUser);
  }

  delete(id): Observable<IUser> {
    return this.http.delete<IUser>(`${this.resourceUrl}/${id}`);
  }

  cancelDelete(id): Observable<IUser> {
    return this.http.delete<IUser>(`${this.resourceUrl}/${id}/cancel`);
  }

  updatePicture(file: any, id: string): Observable<IUserInfo> {
    const body = new FormData();
    body.append('file', file);
    return this.http.put<IUserInfo>(`${this.resourceUrl}/${id}/picture`, body);
  }

  query(req: any): Observable<Page<ListUser>> {
    const options = createRequestOption(req);
    return this.http.get<Page<ListUser>>(`${this.resourceUrl}`, {params: options});
  }

  resetPassword(login: string): Observable<IUser | null> {
    return this.http.post<IUser>(`${this.resourceUrl}/reset-password`, login);
  }

  verifUser(form: FormGroup): void {
    const regexp = new RegExp('^[a-zA-Z0-9_.]{1,254}$');

    if (!regexp.test(form.get('username').value)) {
      form.get('username').setErrors({format: true});
    }

    if (form.get('username').value.includes('..')) {
      form.get('username').setErrors({dots: true});
    }

    if (form.get('username').value.startsWith('.')) {
      form.get('username').setErrors({firstDot: true});
    }

    if (form.get('username').value.endsWith('.')) {
      form.get('username').setErrors({endDot: true});
    }
  }

  verifUserAndEmailBackend(err: any, form: FormGroup): boolean {
    if (err && err.error && err.error.code) {
      if (err.error.code === 'username_already_exist') {
        form.get('username').setErrors({exist: true});
        return true;
      }

      if (err.error.code === 'mail_already_exist') {
        form.get('email').setErrors({exist: true});
        return true;
      }

      if (err.error.code === 'username_not_valid') {
        form.get('username').setErrors({format: true});
        return true;
      }
    }
    return false;
  }

  sendEmailVerification(): Observable<IUser | null> {
    return this.http.post<IUser>(`${this.resourceUrl}/email-verification`, {});
  }

  nbViewSubscriber(req: FilterDashboardUser): Observable<number> {
    const options = createRequestOption(req);
    return this.http.get<number>(`${this.resourceUrl}/nb-subscriber`, {params: options});
  }

  countDiffNbSubcribe(): Observable<number> {
    return this.http.get<number>(`${this.resourceUrl}/count-diff-nb-subscriber`);
  }

  getMonetizationInformation(id: string): Observable<MonetizationDashboardUser> {
    return this.http.get<MonetizationDashboardUser>(`${this.resourceUrl}/${id}/monetization`);
  }
}
