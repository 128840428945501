import {createAction, props} from '@ngrx/store';
import {Comment, IComment} from '../../model/comment/comment.model';
import {FilterComment} from '../../model/comment/filter-comment.model';
import {ICreateComment} from '../../model/comment/create-comment.model';
import {IPatchComment} from '../../model/comment/patch-comment.model';


export const loadComments = createAction(
  '[Comments List] Load comments via Service',
  props<{ filters: FilterComment }>()
);

export const loadCommentsDone = createAction(
  '[Comments Effect] Comments Loaded Successfully',
  props<{ comments: Comment[] }>()
);

export const loadCommentsFail = createAction(
  '[Comments Effect] Comments Loaded failed'
);

export const removeCommentsChild = createAction(
  '[Comments Effect] Comments remove childs',
  props<{ id: number }>()
);

export const changeReply = createAction(
  '[Comments Effect] Comments show reply',
  props<{ id: number }>()
);

export const changeEdit = createAction(
  '[Comments Effect] Comments show edit',
  props<{ id: number }>()
);

export const createComment = createAction(
  '[Comments Effect] Comments create',
  props<{ createComment: ICreateComment }>()
);

export const createCommentDone = createAction(
  '[Comments Effect] Comments create Successfully',
  props<{ comment: IComment }>()
);

export const createCommentFail = createAction(
  '[Comments Effect] Comments create failed',
  props<{ message: string }>()
);

export const patchComment = createAction(
  '[Comments Effect] Comments patch',
  props<{ patchComment: IPatchComment }>()
);

export const patchCommentDone = createAction(
  '[Comments Effect] Comments patch Successfully',
  props<{ comment: IComment }>()
);

export const patchCommentFail = createAction(
  '[Comments Effect] Comments patch failed',
  props<{ message: string }>()
);

export const deleteComment = createAction(
  '[Comments Effect] Comments delete',
  props<{ deleteComment: number }>()
);

export const deleteCommentDone = createAction(
  '[Comments Effect] Comments delete Successfully',
  props<{ comment: IComment }>()
);

export const deleteCommentFail = createAction(
  '[Comments Effect] Comments delete failed',
  props<{ message: string }>()
);

export const clearComments = createAction(
  '[Comments Effect] Clear Comments'
);

export const commentActionTypes = {
  loadComments,
  loadCommentsDone,
  loadCommentsFail,
  removeCommentsChild,
  changeReply,
  changeEdit,
  createComment,
  createCommentDone,
  createCommentFail,
  patchComment,
  patchCommentDone,
  patchCommentFail,
  deleteComment,
  deleteCommentDone,
  deleteCommentFail,
  clearComments,
};
