import {HttpParams} from '@angular/common/http';

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort' && req[key] != null && req[key] !== '') {
        if (Array.isArray(req[key])) {
          req[key].forEach(value => {
            options = options.append(key, value);
          });
        } else {
          options = options.set(key, req[key]);
        }
      }

      if (key === 'size' && req[key] === '') {
        options = options.set('size', '10');
      }
    });
    if (req.sort) {
      req.sort.forEach((val: string) => {
        options = options.append('sort', val);
      });
    }
  }
  // let options2: HttpParams = new HttpParams();
  // options2.append("pageable", {});
  return options;
};

export const getErrorRequest = (error): string => {
  if (error && error.error && error.error.code && typeof error.error.code === 'string') {
    return error.error.code;
  }

  if (error && error.code && typeof error.code === 'string') {
    return error.code;
  }

  return 'error';
};


export const stringifyOrNull = (list): any => {
  if (list.length === 0) {
    return null;
  }
  return JSON.stringify(list);
};

export const URLtoData = (url, callback): void => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};
