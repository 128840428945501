import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {tagActionTypes} from './tag.actions';
import {FilterTag} from '../../model/tag/filter-tag.model';
import {Tag} from '../../model/tag/tag.model';

export interface TagState extends EntityState<Tag> {
  isLoadingList: boolean;
  currentTags: Tag[];
  filters: FilterTag;
}

export const adapter: EntityAdapter<Tag> = createEntityAdapter<Tag>({
  selectId: tag => tag.id
});

export const initialState = adapter.getInitialState({
  isLoadingList: false,
  currentTags: null
});

export const tagReducer = createReducer(
  initialState,

  on(tagActionTypes.loadTags, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.filters
  })),


  on(tagActionTypes.loadTagsDone, (state, action) => {
    return adapter.setAll(
      action.tags,
      {
        ...state,
        isLoadingList: false,
      }
    );
  }),

  on(tagActionTypes.loadTagsFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(tagActionTypes.selectTags, (state, action) => ({
      ...state,
      currentTags: action.tags,
    }
  )),

  on(tagActionTypes.clearTags, () => ({
    ...initialState
  })),
);

export const {selectAll} = adapter.getSelectors();
