import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {createRequestOption} from '../util/request-util';
import {MonetizationPosition} from 'src/app/core/model/monetization/monetization-position.model';
import {FilterMonetizationPosition} from 'src/app/core/model/monetization/filter-monetization-position.model';
import {ToggleMonetizationPosition} from '../../../core/model/monetization/toggle-monetization-position.model';

@Injectable({providedIn: 'root'})
export class MonetizationPositionService {
  public resourceUrl = environment.SERVER_API_URL + 'api/monetizations/position';

  constructor(private http: HttpClient) {
  }

  query(filter: FilterMonetizationPosition): Observable<MonetizationPosition[]> {
    const options = createRequestOption(filter);
    return this.http.get<MonetizationPosition[]>(this.resourceUrl, {params: options});
  }

  create(filter: ToggleMonetizationPosition): Observable<MonetizationPosition> {
    return this.http.post<MonetizationPosition>(this.resourceUrl, filter);
  }

  delete(filter: ToggleMonetizationPosition): Observable<void> {
    const options = createRequestOption(filter);
    return this.http.delete<void>(this.resourceUrl, {params: options});
  }
}
