import {NgModule} from '@angular/core';
import {LoaderComponent} from './loader.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    LoaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
  exports: [
    LoaderComponent
  ],
  providers: [],
})
export class LoaderModule {
}
