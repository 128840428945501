import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {createRequestOption} from './util/request-util';
import {FilterTag} from '../../core/model/tag/filter-tag.model';
import {ITag} from '../../core/model/tag/tag.model';

@Injectable({providedIn: 'root'})
export class TagService {
  public resourceUrl = environment.SERVER_API_URL + 'api/tags';

  constructor(private http: HttpClient) {
  }

  query(filterTag: FilterTag): Observable<ITag[] | null> {
    const options = createRequestOption(filterTag);
    return this.http.get<ITag[]>(`${this.resourceUrl}`, {params: options});
  }
}
