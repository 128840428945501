import {PageFilter} from '../utils/page-filter.model';

export interface IFilterCategory extends PageFilter {
  domains?: string[];
  enabled?: boolean;
}

export class FilterCategory implements IFilterCategory {
  constructor(
    public domains?: string[],
    public disabled: boolean = false,
    public page?: number,
    public size: number = 200,
    public properties?: string,
    public direction?: string
  ) {
  }
}
