import {createAction, props} from '@ngrx/store';
import {Domain} from '../../model/domain/domain.model';
import {Page} from '../../model/page.model';
import {FilterDomain} from '../../model/domain/filter-category.model';


export const loadDomains = createAction(
  '[Domains List] Load domains via Service',
  props<{ filter: FilterDomain }>()
);

export const loadDomainsDone = createAction(
  '[Domains Effect] Domains Loaded Successfully',
  props<{ domains: Page<Domain> }>()
);

export const loadDomainsFail = createAction(
  '[Domains Effect] Domains Loaded failed'
);

export const selectDomain = createAction(
  '[Domain] Select Domain ',
  props<{ domain: Domain }>()
);

export const domainActionTypes = {
  loadDomains,
  loadDomainsDone,
  loadDomainsFail,
  selectDomain,
};
