import {Injectable} from '@angular/core';
import {CookieService} from '../util/cookie/cookie.service';

@Injectable({providedIn: 'root'})
export class StateStorageService {
  private readonly PREVIOUS_URL_KEY = 'previousUrl';

  constructor(private cookieService: CookieService) {
  }

  storeUrl(url: string): void {
    this.cookieService.getCookieService().setItem(this.PREVIOUS_URL_KEY, url);
  }

  getUrl(): string | null | undefined {
    return this.cookieService.getCookieService().getItem(this.PREVIOUS_URL_KEY);
  }

  clearUrl(): void {
    this.cookieService.getCookieService().removeItem(this.PREVIOUS_URL_KEY);
  }
}
