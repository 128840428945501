import {createAction, props} from '@ngrx/store';
import {NotificationFirebaseFilter} from '../../model/notification/notification-firebase-filter.model';
import {NotificationFirebase} from '../../model/notification/notification-firebase.model';


export const loadNotifications = createAction(
  '[Notifications List] Load Notifications via Service',
  props<{ notificationFilter: NotificationFirebaseFilter }>()
);

export const notificationsLoadedDone = createAction(
  '[Notifications Effect] Notifications Loaded Successfully',
  props<{ notifications: NotificationFirebase[] }>()
);

export const notificationsLoadedFail = createAction(
  '[Notifications Effect] Notifications Loaded failed'
);

export const countNotifications = createAction(
  '[Notifications List] count Notifications via Service',
  props<{ notificationFilter: NotificationFirebaseFilter }>()
);

export const notificationsCountDone = createAction(
  '[Notifications Effect] Notifications count Successfully',
  props<{ count: number | number[] }>()
);

export const notificationsCountFail = createAction(
  '[Notifications Effect] Notifications count failed'
);

export const resetCountNotifications = createAction(
  '[Notifications List] Reset count Notifications via Service'
);

export const notificationsResetCountDone = createAction(
  '[Notifications Effect] Notifications reset count Successfully'
);

export const notificationsResetCountFail = createAction(
  '[Notifications Effect] Notifications reset count failed'
);

export const clearNotifications = createAction(
  '[Notifications Effect] Clear all  store Notifications'
);

export const addNotification = createAction(
  '[Notifications Effect] Add Notification',
  props<{ notification: NotificationFirebase }>()
);

export const removeNotification = createAction(
  '[Notifications Effect] remove Notification',
  props<{ notification: NotificationFirebase }>()
);

export const stateChangeNotification = createAction(
  '[Notifications Effect] State change Notification',
  props<{ userId: string }>()
);

export const notificationActionTypes = {
  loadNotifications,
  notificationsLoadedDone,
  notificationsLoadedFail,
  countNotifications,
  notificationsCountDone,
  notificationsCountFail,
  resetCountNotifications,
  notificationsResetCountDone,
  notificationsResetCountFail,
  clearNotifications,
  stateChangeNotification,
  addNotification,
  removeNotification,
};
