import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Page} from 'src/app/core/model/page.model';
import {createRequestOption} from '../util/request-util';
import {IMeansPayment, MeansPayment} from '../../../core/model/means-payment/means-payment';
import {CreateMeansPayment} from 'src/app/core/model/means-payment/create-means-payment.model';
import {PatchMeansPayment} from '../../../core/model/means-payment/patch-means-payment.model';

@Injectable({providedIn: 'root'})
export class MeansPaymentService {
  public resourceUrl = environment.SERVER_API_URL + 'api/users';

  constructor(private http: HttpClient) {
  }

  query(req: any): Observable<Page<MeansPayment>> {
    const options = createRequestOption(req);
    return this.http.get<Page<MeansPayment>>(`${this.resourceUrl}/${req.userId}/meansPayments/`, {params: options});
  }

  create(userId: string, createMeansPayment: CreateMeansPayment): Observable<IMeansPayment> {
    return this.http.post<IMeansPayment>(`${this.resourceUrl}/${userId}/meansPayments/`, createMeansPayment);
  }

  find(userId: string, id: string | number): Observable<IMeansPayment> {
    return this.http.get<IMeansPayment>(`${this.resourceUrl}/${userId}/meansPayments//${id}`);
  }

  patch(userId: string, patchMeansPayment: PatchMeansPayment): Observable<MeansPayment> {
    return this.http.patch<MeansPayment>(`${this.resourceUrl}/${userId}/meansPayments/${patchMeansPayment.id}`, patchMeansPayment);
  }

  delete(userId: string, meansPaymentId: string | number): Observable<MeansPayment> {
    return this.http.delete<MeansPayment>(`${this.resourceUrl}/${userId}/meansPayments/${meansPaymentId}`);
  }
}
