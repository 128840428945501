import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {FilterCategory} from '../../core/model/category/filter-category.model';
import {createRequestOption} from './util/request-util';
import {ICategory} from '../../core/model/category/category.model';
import {Page} from '../../core/model/page.model';

@Injectable({providedIn: 'root'})
export class CategoryService {
  public resourceUrl = environment.SERVER_API_URL + 'api/categories';

  constructor(private http: HttpClient) {
  }

  query(filterCategory: FilterCategory): Observable<Page<ICategory> | null> {
    const options = createRequestOption(filterCategory);
    return this.http.get<Page<ICategory>>(`${this.resourceUrl}`, {params: options});
  }
}
