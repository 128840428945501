<div class="dropdown conversation {{classHeader | async}} {{classShowHeader}}" appDropdown *ngIf="user != null">
  <div (click)="loadConversation()" class="btn-icon-conversation element-menu center-vertically" appTooltip="Discussion instantanée" placement="bottom">
    <img src="/assets/images/header/messages.svg?v=2"
         [class]="(countConversation | async) && (countConversation | async) > 0 ? 'ring': ''"
         alt="Icones conversation"/>
    <span class="count-conversation"
          *ngIf="(countConversation | async) && (countConversation | async) > 0">{{countConversation | async}}</span>
  </div>
  <div class="dropdown-content" >

    <div class="conversations scroll-red" (scroll)="onScroll($event)" *ngIf="conversations.length > 0">
      <a *ngFor="let conversation of conversations"
         [routerLink]="'/messages/' + conversation.key"
         (click)="openConversation(conversation)"
         class="conversation close {{conversation.unread > 0 ? 'unread' : ''}}">
        <div
          class="conversation-user"
          *ngIf="(conversation.usersDest | async) as usersDest else loading">

          <div class="picture-user" *ngIf="usersDest.content.length == 0">
            <div class="content color-0"></div>
          </div>

          <app-img-or-letter-picture-component *ngIf="usersDest.content.length == 1" class="block-picture-user"
                                      [userPicture]="usersDest.content[0].userPicture"
                                      [textForOneLetter]="usersDest.content[0].username"
          ></app-img-or-letter-picture-component>

          <div class="multi-picture" *ngIf="usersDest.content.length > 1">
            <div class="picture-user first">
              <img *ngIf="usersDest.content[0].userPicture && usersDest.content[0].userPicture != ''" class="content"
                   src="{{usersDest.content[0].userPicture}}"
                   alt="Image de l'utilisateur {{usersDest.content[0].username}}"/>
              <div *ngIf="!usersDest.content[0].userPicture || usersDest.content[0].userPicture == ''"
                   class="content color-{{usersDest.content[0].id.charCodeAt(0) % 5}}">
                {{usersDest.content[0].username.charAt(0).toUpperCase()}}
              </div>
            </div>
            <div class="picture-user second">
              <img *ngIf="usersDest.content[1].userPicture && usersDest.content[1].userPicture != ''" class="content"
                   src="{{usersDest.content[1].userPicture}}"
                   alt="Image de l'utilisateur {{usersDest.content[1].username}}"/>
              <div *ngIf="!usersDest.content[1].userPicture || usersDest.content[1].userPicture == ''"
                   class="content color-{{usersDest.content[1].id.charCodeAt(0) % 5}}">
                {{usersDest.content[1].username.charAt(0).toUpperCase()}}
              </div>
            </div>
          </div>

          <div class="text">
            <h6>{{getName(usersDest.content)}}</h6>
            <p *ngIf="!conversation.isLocal">{{conversation.lastMessage}}</p>
          </div>

          <div class="not-read"></div>
        </div>

        <ng-template #loading>
          <div class="conversation-loader">
            <div class="picture"></div>
            <div class="text">
              <div class="title"></div>
              <div class="message"></div>
            </div>
          </div>
        </ng-template>

      </a>
    </div>

    <div class="loader" *ngIf="isLoading | async">
      <div class="conversation-loader" *ngFor="let number of [0,1,2,3,4]">
        <div class="picture"></div>
        <div class="text">
          <div class="title"></div>
          <div class="message"></div>
        </div>
      </div>
    </div>

    <div class="no-message-text" *ngIf="conversations.length == 0 && !(isLoading | async)">
      Aucune discussion
    </div>

    <a [routerLink]="'/messages/liste'" class="show-all">
      Voir toutes les conversations
    </a>

  </div>
</div>
