import {NgModule} from '@angular/core';
import {FooterComponent} from './footer.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared.module';
import {TooltipModule} from '../../../core/pipe/tooltip.module';

@NgModule({
  declarations: [
    FooterComponent
  ],
    imports: [
        SharedModule,
        RouterModule,
        TooltipModule
    ],
  exports: [
    FooterComponent
  ],
  providers: [],
})
export class FooterModule {
}
