import {createReducer, on} from '@ngrx/store';
import {userActionTypes} from './user.actions';
import {IUser} from '../../model/user/user.model';
import {IUserInfo} from '../../model/user/user-info.model';
import {IListUser, ListUser} from '../../model/user/list-user.model';
import {FilterIngredient} from '../../model/ingredient/filter-ingredient.model';

export interface UserState {
  isLoadingUser: boolean;
  isLoadingList: boolean;
  isLoadingUpdateUser: boolean;
  isLoadingPictureUser: boolean;
  isLoadingDeleteUser: boolean;
  user: IUserInfo;
  users: IListUser[];
  usersSelected: IListUser[];
  userUpdate: IUser;
  errorUser: boolean;
  messageErrorUser: string;
  errorUpdateUser: boolean;
  messageErrorUpdateUser: string;
  messageErrorDeleteUser: string;
  totalElements: number;
  totalPages: number;
  empty: boolean;
  page: number;
  filters: FilterIngredient;
}

export const initialState = {
  isLoadingUser: false,
  isLoadingList: false,
  isLoadingUpdateUser: false,
  isLoadingPictureUser: false,
  isLoadingDeleteUser: false,
  user: null,
  users: [],
  usersSelected: [],
  userUpdate: null,
  errorUser: false,
  messageErrorUser: '',
  errorUpdateUser: false,
  messageErrorUpdateUser: '',
  messageErrorDeleteUser: '',
  totalElements: null,
  totalPages: null,
  page: 0,
  filters: null,
};

function updateUser(action, state): IUser {
  const currentUser: IUser = JSON.parse(JSON.stringify(state.user));

  if (currentUser && currentUser.id === action.user.id) {
    return action.user;
  }

  return currentUser;
}

function updateRecipePromote(action, state): IUserInfo {
  const currentUser: IUserInfo = JSON.parse(JSON.stringify(state.user));

  if (currentUser) {
    currentUser.recipePromote = action.recipe;
    return currentUser;
  }

  return currentUser;
}

function addOneUserSelected(action, state): ListUser[] {
  const currentUserSelected: IListUser[] = JSON.parse(JSON.stringify(state.usersSelected));
  currentUserSelected.push(action.user);
  return currentUserSelected;
}

function removeOneUserSelected(action, state): ListUser[] {
  const currentUserSelected: IListUser[] = JSON.parse(JSON.stringify(state.usersSelected));

  return currentUserSelected.filter(user => user.id !== action.user.id);
}

export const userReducer = createReducer(
  initialState,

  on(userActionTypes.loadUsers, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.filters
  })),

  on(userActionTypes.loadUsersDone, (state, action: any) => ({
    ...state,
    isLoadingList: false,
    totalElements: action.users.totalElements,
    totalPages: action.users.totalPages,
    empty: action.users.empty,
    page: action.users.number,
    users: action.users.content
  })),

  on(userActionTypes.loadUsersFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(userActionTypes.updatePictureUser, (state) => ({
      ...state,
      isLoadingPictureUser: true,
    }
  )),

  on(userActionTypes.loadUser, (state) => ({
      ...state,
      isLoadingUser: true,
    }
  )),

  on(userActionTypes.loadUserDone, (state, action) => ({
      ...state,
      isLoadingUser: false,
      user: action.user,
      errorUser: false,
      messageErrorUser: ''
    }
  )),

  on(userActionTypes.loadUserFail, (state, action) => ({
      ...state,
      isLoadingUser: false,
      errorUser: true,
      messageErrorUser: action.message
    }
  )),

  on(userActionTypes.patchUser, (state) => ({
      ...state,
      isLoadingUpdateUser: true,
      errorUpdateUser: false,
      messageErrorUpdateUser: ''
    }
  )),

  on(userActionTypes.patchUserDone, (state, action) => ({
      ...state,
      isLoadingUpdateUser: false,
      isLoadingPictureUser: false,
      user: updateUser(action, state),
      userUpdate: action.user,
    }
  )),

  on(userActionTypes.patchUserFail, (state, action) => ({
      ...state,
      isLoadingUpdateUser: false,
      isLoadingPictureUser: false,
      errorUpdateUser: true,
      messageErrorUpdateUser: action.message
    }
  )),

  on(userActionTypes.deleteUser, (state) => ({
      ...state,
      isLoadingDeleteUser: true,
      messageErrorDeleteUser: ''
    }
  )),

  on(userActionTypes.deleteUserDone, (state, action) => ({
      ...state,
      isLoadingDeleteUser: false,
      user: updateUser(action, state),
    }
  )),

  on(userActionTypes.deleteUserFail, (state, action) => ({
      ...state,
      isLoadingDeleteUser: false,
      messageErrorDeleteUser: action.message
    }
  )),

  on(userActionTypes.cancelDeleteUser, (state) => ({
      ...state,
      isLoadingDeleteUser: true,
      messageErrorDeleteUser: ''
    }
  )),

  on(userActionTypes.cancelDeleteUserDone, (state, action) => ({
      ...state,
      isLoadingDeleteUser: false,
      user: updateUser(action, state),
    }
  )),

  on(userActionTypes.cancelDeleteUserFail, (state, action) => ({
      ...state,
      isLoadingDeleteUser: false,
      messageErrorDeleteUser: action.message
    }
  )),

  on(userActionTypes.clearUser, () => ({
      ...initialState,
    }
  )),

  on(userActionTypes.updateRecipePromote, (state, action) => ({
      ...state,
      user: updateRecipePromote(action, state),
    }
  )),

  on(userActionTypes.addUserSelected, (state, action) => ({
      ...state,
      usersSelected: addOneUserSelected(action, state),
    }
  )),

  on(userActionTypes.removeUserSelected, (state, action) => ({
      ...state,
      usersSelected: removeOneUserSelected(action, state),
    }
  )),
);
