import {NgModule} from '@angular/core';
import {SearchInputComponent} from './search-input.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AutoCompleteModule} from '../../../../core/directive/auto-complete/auto-complete.module';

@NgModule({
  declarations: [
    SearchInputComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    AutoCompleteModule,
  ],
  exports: [
    SearchInputComponent
  ],
  providers: [],
})
export class SearchInputModule {
}
