import {EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {Conversation} from '../../model/conversation/conversation.model';
import {conversationActionTypes} from './conversation.actions';
import {ConversationFilter} from '../../model/conversation/conversation-filter.model';

export interface ConversationState extends EntityState<Conversation> {
  isLoadingList: boolean;
  isLoadingListNavbar: boolean;
  count: number;
  conversationFilter: ConversationFilter;
  conversationNavbarFilter: ConversationFilter;
  noMoreConversationInFirebase: boolean;
  noMoreConversationNavbarInFirebase: boolean;
  conversations: Conversation[];
  conversationsNavbar: Conversation[];
  conversation: Conversation;
  conversationLocal: Conversation;
}

export const initialState = {
  isLoadingList: false,
  isLoadingListNavbar: false,
  count: 0,
  conversationFilter: new ConversationFilter(),
  conversationNavbarFilter: new ConversationFilter(),
  noMoreConversationInFirebase: false,
  noMoreConversationNavbarInFirebase: false,
  conversations: [],
  conversationsNavbar: [],
  conversation: null,
  conversationLocal: null,
};

function getConversationFilter(conversationFilter: ConversationFilter, conversations: Conversation[]): ConversationFilter {
  const newConversationFilter = new ConversationFilter();
  if (conversations.length > 0) {
    newConversationFilter.lastMessageTimeStamp = conversations[conversations.length - 1].lastMessageTimestamp;
    newConversationFilter.userId = conversationFilter.userId;
  }
  return newConversationFilter;
}

function getConversations(action, state): Conversation[] {
  if (action.conversations.length > 0) {
    const newConversations = [...action.conversations];

    if (action.conversations.length >= state.conversationFilter.limitToLast) {
      newConversations.pop();
    }

    return newConversations;
  }
  return action.conversations;
}

function getConversationsNavbar(action, state): Conversation[] {
  if (action.conversations.length > 0) {
    const newConversations = [...action.conversations];

    if (action.conversations.length >= state.conversationNavbarFilter.limitToLast) {
      newConversations.pop();
    }

    return newConversations;
  }
  return action.conversations;
}

export const conversationReducer = createReducer(
  initialState,

  on(conversationActionTypes.loadConversations, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.conversationFilter
  })),

  on(conversationActionTypes.conversationsLoadedDone, (state, action) => ({
    ...state,
    conversations: getConversations(action, state),
    isLoadingList: false,
    noMoreConversationInFirebase: action.conversations.length < state.conversationFilter.limitToLast,
    conversationFilter: getConversationFilter(state.conversationFilter, action.conversations)
  })),

  on(conversationActionTypes.conversationsLoadedFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(conversationActionTypes.loadConversationsNavbar, (state, res) => ({
    ...state,
    isLoadingListNavbar: true,
    filters: res.conversationFilter
  })),

  on(conversationActionTypes.conversationsNavbarLoadedDone, (state, action) => ({
    ...state,
    conversationsNavbar: getConversationsNavbar(action, state),
    isLoadingListNavbar: false,
    noMoreConversationNavbarInFirebase: action.conversations.length < state.conversationNavbarFilter.limitToLast,
    conversationNavbarFilter: getConversationFilter(state.conversationNavbarFilter, action.conversations)
  })),

  on(conversationActionTypes.conversationsNavbarLoadedFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(conversationActionTypes.loadConversation, (state, res) => ({
    ...state,
    conversation: res.conversation
  })),

  on(conversationActionTypes.clearConversations, () => ({
    ...initialState
  })),

  on(conversationActionTypes.clearConversation, (state) => ({
    ...state,
    conversation: null
  })),

  on(conversationActionTypes.addConversation, (state, action) => ({
      ...state,
      conversationLocal: action.conversation
    }
  )),

  on(conversationActionTypes.conversationsCountDone, (state, action) => ({
      ...state,
      count: action.nbUnread ? action.nbUnread.length : 0
    }
  )),
);
