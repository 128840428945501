import {createAction, props} from '@ngrx/store';
import {Tag} from '../../model/tag/tag.model';
import {FilterTag} from '../../model/tag/filter-tag.model';


export const loadTags = createAction(
  '[Tags List] Load tags via Service',
  props<{ filters: FilterTag }>()
);

export const loadTagsDone = createAction(
  '[Tags Effect] Tags Loaded Successfully',
  props<{ tags: Tag[] }>()
);

export const loadTagsFail = createAction(
  '[Tags Effect] Tags Loaded failed'
);

export const selectTags = createAction(
  '[Tag] Select Tag ',
  props<{ tags: Tag[] }>()
);

export const clearTags = createAction(
  '[Tag] Clear Tags '
);

export const tagActionTypes = {
  loadTags,
  loadTagsDone,
  loadTagsFail,
  selectTags,
  clearTags,
};
