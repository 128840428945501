import {NgModule} from '@angular/core';
import {NavbarComponent} from './navbar.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared.module';
import {ListSelectCategoryModule} from '../category/list-select-category/list-select-category.module';
import {DropdownModule} from '../../../core/directive/dropdown/dropdown.module';
import {ModalModule} from '../modal/modal.module';
import {NavbarUserModule} from './user/navbar-user.module';
import {SearchInputModule} from '../search/input/search-input.module';
import {NavbarNotificationModule} from './navbar-items/notification/navbar-notification.module';
import {NavbarConversationModule} from './navbar-items/conversation/navbar-conversation.module';
import {TooltipModule} from '../../../core/pipe/tooltip.module';
import {OpinionFormModule} from './opinion/opinion-form.module';

@NgModule({
  declarations: [
    NavbarComponent
  ],
    imports: [
        SharedModule,
        RouterModule,
        ListSelectCategoryModule,
        DropdownModule,
        ModalModule,
        NavbarUserModule,
        SearchInputModule,
        NavbarNotificationModule,
        NavbarConversationModule,
        TooltipModule,
        OpinionFormModule
    ],
  exports: [
    NavbarComponent
  ],
  providers: [],
})
export class NavbarModule {
}
