import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {FilterComment} from '../../core/model/comment/filter-comment.model';
import {createRequestOption} from './util/request-util';
import {IComment} from '../../core/model/comment/comment.model';
import {Page} from '../../core/model/page.model';
import {CreateComment} from '../../core/model/comment/create-comment.model';
import {IRecipe} from '../../core/model/recipe/recipe.model';
import {PatchComment} from '../../core/model/comment/patch-comment.model';

@Injectable({providedIn: 'root'})
export class CommentService {
  public resourceUrl = environment.SERVER_API_URL + 'api/comments';

  constructor(private http: HttpClient) {
  }

  create(createComment: CreateComment): Observable<IComment | null> {
    return this.http.post<IComment>(`${this.resourceUrl}`, createComment);
  }

  patch(patchComment: PatchComment): Observable<any> {
    return this.http.patch<IRecipe>(`${this.resourceUrl}/${patchComment.id}`, patchComment);
  }

  delete(deleteComment: number): Observable<any> {
    return this.http.delete(`${this.resourceUrl}/${deleteComment}`);
  }

  query(filterComment: FilterComment): Observable<Page<IComment> | null> {
    const options = createRequestOption(filterComment);
    return this.http.get<Page<IComment>>(`${this.resourceUrl}`, {params: options});
  }

  find(id: number): Observable<IComment | null> {
    return this.http.get<IComment>(`${this.resourceUrl}/${id}`);
  }

  nbViewComments(): Observable<number> {
    return this.http.get<number>(`${this.resourceUrl}/nb-comments`);
  }

  countDiffNbComments(): Observable<number> {
    return this.http.get<number>(`${this.resourceUrl}/count-diff-nb-comments`);
  }
}
