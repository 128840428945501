import {tagActionTypes} from './tag.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Tag} from '../../model/tag/tag.model';
import {TagService} from '../../../shared/services/tag.service';
import {TransferState} from '@angular/platform-browser';
import {STATE_LIST_TAGS} from '../../transfert-state/state';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';

@Injectable()
export class TagEffects {

  loadTags$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(tagActionTypes.loadTags),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_LIST_TAGS, this.tagService.query(action.filters))),
        map(tags => tagActionTypes.loadTagsDone({tags})),
        catchError((err, caught) => {
          this.store.dispatch(tagActionTypes.loadTagsFail());
          return caught;
        })
      );
    }
  );

  constructor(
    private tagService: TagService,
    private actions$: Actions,
    private router: Router,
    private state: TransferState,
    private utilsTransfertsState: UtilsTransfertsState,
    private store: Store<Tag>) {
  }
}
