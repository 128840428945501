import {createAction, props} from '@ngrx/store';
import {IAlgoliaFilter} from '../../model/algolia/algolia-filter.model';
import {IAlgoliaResults} from '../../model/algolia/algolia-results.model';
import {IFilterAlgoliaRequest} from '../../model/algolia/algolia-filter-request.model';
import {IAlgoliaResult} from '../../model/algolia/algolia-result.model';

export const loadMultiAlgolia = createAction(
  '[Algolia Effect] Load multi algolia via Service',
  props<{ filter: IAlgoliaFilter }>()
);

export const loadMultiAlgoliaDone = createAction(
  '[Algolia Effect] Multi algolia Loaded Successfully',
  props<{ results: IAlgoliaResults }>()
);

export const loadMultiAlgoliaFail = createAction(
  '[Algolia Effect] Multi Algolia Loaded failed',
  props<{ message: string }>()
);

export const loadMultiAlgoliaPage = createAction(
  '[Algolia Effect] Load multi algolia via Service Page',
  props<{ filter: IAlgoliaFilter }>()
);

export const loadMultiAlgoliaDonePage = createAction(
  '[Algolia Effect] Multi algolia Loaded Successfully Page',
  props<{ results: IAlgoliaResults }>()
);

export const loadMultiAlgoliaFailPage = createAction(
  '[Algolia Effect] Multi Algolia Loaded failed Page',
  props<{ message: string }>()
);

export const clearAlgoliaNavBar = createAction(
  '[Algolia Effect] Clear nav bar Algolia',
);

export const clearAlgolia = createAction(
  '[Algolia Effect] Clear Algolia',
);

export const loadAlgolia = createAction(
  '[Algolia Effect] Load algolia via Service',
  props<{ filter: IFilterAlgoliaRequest }>()
);

export const loadAlgoliaDone = createAction(
  '[Algolia Effect] Algolia Loaded Successfully',
  props<{ results: IAlgoliaResult }>()
);

export const loadAlgoliaFail = createAction(
  '[Algolia Effect] Algolia Loaded failed',
  props<{ message: string }>()
);

export const algoliaActionTypes = {
  loadMultiAlgolia,
  loadMultiAlgoliaDone,
  loadMultiAlgoliaFail,
  loadAlgolia,
  loadAlgoliaDone,
  loadAlgoliaFail,
  loadMultiAlgoliaPage,
  loadMultiAlgoliaDonePage,
  loadMultiAlgoliaFailPage,
  clearAlgoliaNavBar,
  clearAlgolia
};
