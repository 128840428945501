import {createAction, props} from '@ngrx/store';
import {ConversationFilter} from '../../model/conversation/conversation-filter.model';
import {Conversation} from '../../model/conversation/conversation.model';

export const loadConversations = createAction(
  '[Conversations Effect] Load Conversations via Service',
  props<{ conversationFilter: ConversationFilter }>()
);

export const conversationsLoadedDone = createAction(
  '[Conversations Effect] Conversations Loaded Successfully',
  props<{ conversations: Conversation[] }>()
);

export const conversationsLoadedFail = createAction(
  '[Conversations Effect] Conversations Loaded failed'
);

export const loadConversationsNavbar = createAction(
  '[Conversations List] Load Conversations navbar via Service',
  props<{ conversationFilter: ConversationFilter }>()
);

export const conversationsNavbarLoadedDone = createAction(
  '[Conversations Effect] Conversations navbar Loaded Successfully',
  props<{ conversations: Conversation[] }>()
);

export const conversationsNavbarLoadedFail = createAction(
  '[Conversations Effect] Conversations navbar Loaded failed'
);

export const loadConversation = createAction(
  '[Conversation Effect] Load Conversation via Service',
  props<{ conversation: Conversation }>()
);

export const clearConversations = createAction(
  '[Conversations Effect] Clear all store conversations'
);

export const clearConversation = createAction(
  '[Conversations Effect] Clear store conversation'
);

export const addConversation = createAction(
  '[Conversations Effect] Add Conversation',
  props<{ conversation: Conversation }>()
);

export const countConversationsUnread = createAction(
  '[Conversations Effect] Count Conversation',
  props<{ userId: string }>()
);

export const conversationsCountDone = createAction(
  '[Conversations Effect] Count Conversation Done',
  props<{ nbUnread: boolean[] }>()
);

export const conversationsCountFail = createAction(
  '[Conversations Effect] Count Conversation Fail',
);

export const conversationActionTypes = {
  loadConversations,
  conversationsLoadedDone,
  conversationsLoadedFail,
  loadConversationsNavbar,
  conversationsNavbarLoadedDone,
  conversationsNavbarLoadedFail,
  loadConversation,
  clearConversations,
  clearConversation,
  addConversation,
  countConversationsUnread,
  conversationsCountDone,
  conversationsCountFail,
};
