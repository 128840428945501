import {notificationActionTypes} from './notification.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../shared/services/notification.service';
import {UserService} from '../../../shared/services/user/user.service';
import {AppState} from '../app.state';
import {NotificationFirebase} from '../../model/notification/notification-firebase.model';

@Injectable()
export class NotificationEffects {

  loadNotifications$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(notificationActionTypes.loadNotifications),
        switchMap((action) => this.notificationService.query(action.notificationFilter)),
        map((notifications: NotificationFirebase[]) => notifications.reverse()),
        map((notifications: NotificationFirebase[]) => notificationActionTypes.notificationsLoadedDone({notifications})),
        catchError((err, caught) => {
          this.store.dispatch(notificationActionTypes.notificationsLoadedFail());
          return caught;
        })
      );
    }
  );

  stateChangeAddedNotification$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(notificationActionTypes.stateChangeNotification),
        switchMap((action) => this.notificationService.stateChangeAdded(action.userId)),
        map((value: any) => value.payload.child('/').val()),
        map((notification: NotificationFirebase) => notificationActionTypes.addNotification({notification}))
      );
    }
  );

  stateChangeRemovedNotification$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(notificationActionTypes.stateChangeNotification),
        switchMap((action) => this.notificationService.stateChangeRemoved(action.userId)),
        map((value: any) => value.payload.child('/').val()),
        map((notification: NotificationFirebase) => notificationActionTypes.removeNotification({notification}))
      );
    }
  );

  countNotifications$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(notificationActionTypes.countNotifications),
        switchMap((action) => this.notificationService.count(action.notificationFilter)),
        map(count => notificationActionTypes.notificationsCountDone({count})),
        catchError((err, caught) => {
          this.store.dispatch(notificationActionTypes.notificationsCountFail());
          return caught;
        })
      );
    }
  );

  resetCountNotifications$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(notificationActionTypes.resetCountNotifications),
        switchMap(() => this.notificationService.resetCount()),
        map(() => notificationActionTypes.notificationsResetCountDone()),
        catchError((err, caught) => {
          this.store.dispatch(notificationActionTypes.notificationsResetCountFail());
          return caught;
        })
      );
    }
  );

  constructor(
    private notificationService: NotificationService,
    private userService: UserService,
    private actions$: Actions,
    private store: Store<AppState>) {
  }


}
