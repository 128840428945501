import {Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AlgoliaService} from '../../../services/algolia.service';
import {debounceTime, map} from 'rxjs/operators';
import {AlgoliaFilter} from '../../../../core/model/algolia/algolia-filter.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/store/app.state';
import {clearAlgoliaNavBar, loadMultiAlgolia} from '../../../../core/store/algolia/algolia.actions';
import {getAlgoliaMultiResults, isLoadingMultiAlgolia} from '../../../../core/store/algolia/algolia.selectors';
import {Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {getClassHeader} from '../../../../core/store/header/header.selectors';
import { SsrContext } from 'src/app/shared/services/util/ssr-context.service';

@Component({
  selector: 'app-search-input-component',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit, OnDestroy {

  @ViewChild('inputSearch') inputSearch: ElementRef;
  @ViewChildren('inputIngredients') inputIngredients: QueryList<ElementRef>;
  @ViewChild('autoComplete') autoComplete: ElementRef;
  form
  isLoadingObservable: Observable<boolean>;
  hitsObservable: Observable<any[]>;
  private allSubscription: Subscription[] = [];
  classHeader: Observable<string>;
  histories: string[] = [];
  classShowHeader: string;

  constructor(private fb: FormBuilder,
              private algoliaService: AlgoliaService,
              private router: Router,
              private renderer: Renderer2,
              private ssrContext: SsrContext,
              private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      search: ['', [Validators.required]],
    });
    this.histories = this.getHistorySearch();
    this.classHeader = this.store.select(getClassHeader);
    this.isLoadingObservable = this.store.select(isLoadingMultiAlgolia);
    this.hitsObservable = this.store.select(getAlgoliaMultiResults)
      .pipe(
        map(algoliaMultiResults => {
          if (algoliaMultiResults == null) {
            return null;
          }

          const results = JSON.parse(JSON.stringify(algoliaMultiResults));
          let hits = [];

          results.results.forEach(result => {
            result.hits.forEach(hit => {
              hit.index = result.index;
            });
            hits = hits.concat(result.hits);
          });
          return hits.slice(0, 7);
        })
      );

    const subscribe = this.form.valueChanges
      .pipe(debounceTime(200))
      .subscribe({
        next: (value) => {
          if (value && value.search) {
            const filter = new AlgoliaFilter();
            filter.strategy = 'none';
            filter.requests = [
              this.algoliaService.createFilterAlgoliaRequest('recipe', value.search, 7),
              this.algoliaService.createFilterAlgoliaRequest('user', value.search, 7)
            ];

            this.store.dispatch(loadMultiAlgolia({filter}));
          } else {
            this.store.dispatch(clearAlgoliaNavBar());
          }
        },
        error: (err) => console.log(err),
      });
    this.allSubscription.push(subscribe);
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearAlgoliaNavBar());
    this.allSubscription.forEach(value => value.unsubscribe());
  }

  search(): void {
    if (this.form.valid) {
      this.router.navigate(['/recherche'], {queryParams: {search: this.form.get('search').value}}).then();
      this.setHistorySearchLocalStorage(this.form.get('search').value);
    }
  }

  setHistorySearchLocalStorage(value): void {
    if (this.histories.includes(value)) {
      this.histories = this.histories.filter(e => e !== value);
      this.histories.unshift(value);
    } else {
      if (this.histories) {
        this.histories.unshift(value);
        if (this.histories.length >= 5) {
          this.histories.pop();
        }
      }
    }

    localStorage.setItem('history_search', JSON.stringify(this.histories));

    this.clear();

    this.renderer.removeClass(this.autoComplete.nativeElement, 'show');
    this.blur();
  }

  private getHistorySearch(): string [] {
    let historySearch = [];
    if (!this.ssrContext.isServer()) {
      historySearch = JSON.parse(localStorage.getItem('history_search'));
    }

    return historySearch ?? [];
  }

  clear(): void {
    this.form.get('search').setValue('');
    this.inputSearch.nativeElement.focus();
  }

  blur(): void {
    this.inputSearch.nativeElement.blur();
  }

  deleteHistory(e: MouseEvent, history: string): void {
    e.preventDefault();
    e.stopPropagation();

    this.histories = this.histories.filter(historySelected => historySelected !== history);
    localStorage.setItem('history_search', JSON.stringify(this.histories));
  }

  @HostListener('window:scroll', ['$event'])
  scrollPage(): void {
    if (window.scrollY > 70) {
      this.classShowHeader = 'inner-page';
    } else {
      this.classShowHeader = '';
    }
  }
}
