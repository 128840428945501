export const environment = {
  production: true,
  lang: 'fr',
  googleAnalyticsTrackingCode: 'G-BKTDKLG03G',
  VERSION_SW: '134',
  SERVER_API_URL: 'https://tooda.fr/',
  GOOGLE_ID: '631712551201-iabt2mbgpmhbulhbnq1lo7o1efva7pcm.apps.googleusercontent.com',
  FACEBOOK_ID: '1027716734709309',
  firebaseConfig: {
    apiKey: 'AIzaSyDMs6ppOuRJ4yVEyAlxLRUHA7d7uYXnuzs',
    authDomain: 'tooda-fab96.firebaseapp.com',
    projectId: 'tooda-fab96',
    databaseURL: 'https://tooda-fab96-default-rtdb.europe-west1.firebasedatabase.app/',
    storageBucket: 'tooda-fab96.appspot.com',
    measurementId: 'G-1EDVGW7J23',
    messagingSenderId: '631712551201',
    appId: '1:631712551201:web:a7c5e520f4ac22823b2e60'
  },
  ALGOLIA_KEY: 'da2c647861ce78e02ac54aa3741a8f3c',
  ALGOLIA_APP_ID: 'XTZGNH7Z73',
  ALGOLIA_URL: 'https://XTZGNH7Z73-dsn.algolia.net',
  adsense: {
    adClient: 'ca-pub-7418714798998462',
    show: true
  }
};
