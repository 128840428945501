import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {createRequestOption} from './util/request-util';
import {FilterIngredient} from '../../core/model/filter-ingredient.model';
import {IListIngredient} from '../../core/model/ingredient/list-ingredient.model';
import {Page} from '../../core/model/page.model';
import {IIngredient} from '../../core/model/ingredient/ingredient.model';

@Injectable({providedIn: 'root'})
export class IngredientService {
  public resourceUrl = environment.SERVER_API_URL + 'api/ingredients';

  constructor(private http: HttpClient) {
  }

  query(filterIngredient: FilterIngredient): Observable<Page<IListIngredient> | null> {
    const options = createRequestOption(filterIngredient);
    return this.http.get<Page<IListIngredient>>(`${this.resourceUrl}`, {params: options});
  }

  find(id: string | number): Observable<IIngredient> {
    return this.http.get<IIngredient>(`${this.resourceUrl}/${id}`);
  }
}
