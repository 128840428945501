import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {addressActionTypes} from './address.actions';
import {UserService} from '../../../shared/services/user/user.service';
import {PaginationService} from '../../../shared/services/pagination.service';
import {Address} from '../../model/address/address';
import {AddressService} from '../../../shared/services/user/address.service';
import {Page} from '../../model/page.model';
import {getErrorRequest} from '../../../shared/services/util/request-util';
import {Notif} from '../../model/notification/notif.model';
import {NotificationsService} from '../../../shared/services/util/notifications.service';
import {AddressTranslate} from './address.translate';

@Injectable()
export class AddressEffects {

  constructor(
    private addressService: AddressService,
    private userService: UserService,
    private actions$: Actions,
    private router: Router,
    private notificationsService: NotificationsService,
    private store: Store<Address>,
    private paginationService: PaginationService) {
  }

  loadAddresss$ = createEffect(() => {
      const actions = inject(Actions);

      return actions.pipe(
        ofType(addressActionTypes.loadListAddress),
        switchMap((action) => this.addressService.query(action.filters)),
        tap((addresss: Page<Address>) => this.paginationService.checkCurrentPageHaveItemsOrRedirect(addresss)),
        map(addresss => addressActionTypes.addressListLoadedDone({addresss})),
        catchError((err, caught) => {
          const notification = new Notif({text: AddressTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(addressActionTypes.addressListLoadedFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  loadAddress$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(addressActionTypes.loadOneAddress),
        switchMap((action) => this.addressService.find(action.userId, action.id)),
        map(address => addressActionTypes.addressOneLoadedDone({address})),
        catchError((err, caught) => {
          const notification = new Notif({text: AddressTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(addressActionTypes.addressOneLoadedFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  createAddress$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(addressActionTypes.createAddress),
        switchMap((action) => this.addressService.create(action.userId, action.createAddress)),
        tap(() => {
          const notification = new Notif({
            text: 'Création de l\'adresse réussi',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }),
        map((address: Address) => addressActionTypes.addressCreatedDone({address})),
        catchError((err, caught) => {
          const notification = new Notif({text: AddressTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(addressActionTypes.addressCreatedFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  updateAddress$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(addressActionTypes.patchAddress),
        switchMap((action) => this.addressService.patch(action.userId, action.patchAddress)),
        tap(() => {
          const notification = new Notif({
            text: 'Modification de l\'adresse réussi',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }),
        map(address => addressActionTypes.addressPatchDone({address})),
        catchError((err, caught) => {
          const notification = new Notif({text: AddressTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(addressActionTypes.addressPatchFail({message: getErrorRequest(err)}));
          return caught;
        }),
      );
    }
  );

  deleteAddress$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(addressActionTypes.deleteAddress),
        switchMap((action) => this.addressService.delete(action.userId, action.id)),
        map(address => addressActionTypes.addressDeletedDone({address})),
        tap(() => {
          const notification = new Notif({
            text: 'Suppresion de l\'adresse réussi',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }),
        catchError((err, caught) => {
          const notification = new Notif({text: AddressTranslate[getErrorRequest(err)], level: 'error', options: {timeout: 5}});
          this.notificationsService.addNotification(notification);
          this.store.dispatch(addressActionTypes.addressDeletedFail({message: getErrorRequest(err)}));
          return caught;
        }),
      );
    }
  );
}
