import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient, HttpEvent} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AlgoliaFilter} from '../../core/model/algolia/algolia-filter.model';
import {AlgoliaResults} from '../../core/model/algolia/algolia-results.model';
import {FilterAlgoliaRequest} from '../../core/model/algolia/algolia-filter-request.model';
import {IAlgoliaResult} from '../../core/model/algolia/algolia-result.model';

@Injectable({providedIn: 'root'})
export class AlgoliaService {

  constructor(private http: HttpClient) {
  }
  public resourceUrl = environment.ALGOLIA_URL;

  private static headerAlgolia(): any {
    return {
      headers: {
        'X-Algolia-API-Key': environment.ALGOLIA_KEY,
        'X-Algolia-Application-Id': environment.ALGOLIA_APP_ID,
      }
    };
  }

  findOneIndex(filterAlgoliaRequest: FilterAlgoliaRequest): Observable<HttpEvent<IAlgoliaResult>> {
    return this.http.get<IAlgoliaResult>
    (
      `${this.resourceUrl}/1/indexes/${filterAlgoliaRequest.indexName}?getRankingInfo=1&${filterAlgoliaRequest.params}`,
      AlgoliaService.headerAlgolia()
    );
  }

  findMultiIndex(filterAlgolia: AlgoliaFilter): Observable<AlgoliaResults> {
    return this.http.post<AlgoliaResults>(`${this.resourceUrl}/1/indexes/*/queries`, filterAlgolia, AlgoliaService.headerAlgolia());
  }

  createFilterAlgoliaRequest(indexName: string, query: string, hitPerPage: number, page?: number): FilterAlgoliaRequest {
    const filterAlgoliaRequest = new FilterAlgoliaRequest();
    filterAlgoliaRequest.indexName = indexName;
    filterAlgoliaRequest.params = 'query=' + query + '&hitsPerPage=' + hitPerPage;
    if (page) {
      filterAlgoliaRequest.params = filterAlgoliaRequest.params + '&page=' + page;
    }
    return filterAlgoliaRequest;
  }
}
