import {AbstractControl, FormGroup} from '@angular/forms';

export class FormUtils {
  static showError(control: AbstractControl, errorCode?: string): boolean {
    if (errorCode) {
      return control.hasError(errorCode);
    } else {
      return control.invalid;
    }
  }

  static getClassBlocInput(form: FormGroup, nameForm: string, isSubmited: boolean, addClass?: string): string {
    let classInput = 'bloc-input';

    if (addClass) {
      classInput += ' ' + addClass;
    }

    if (FormUtils.showError(form.get(nameForm)) && isSubmited) {
      classInput += ' show-error';
    }

    return classInput;
  }

  static getClassBlocInputControl(control: AbstractControl, isSubmited: boolean, addClass?: string): string {
    let classInput = 'bloc-input';

    if (addClass) {
      classInput += ' ' + addClass;
    }

    if (FormUtils.showError(control) && isSubmited) {
      classInput += ' show-error';
    }

    return classInput;
  }
}
