import {NgModule} from '@angular/core';
import {NavbarConversationComponent} from './navbar-conversation.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../../shared.module';
import {ListSelectCategoryModule} from '../../../category/list-select-category/list-select-category.module';
import {DropdownModule} from '../../../../../core/directive/dropdown/dropdown.module';
import {ModalModule} from '../../../modal/modal.module';
import {DateAgoModule} from '../../../../../core/pipe/date-ago.module';
import {TooltipModule} from '../../../../../core/pipe/tooltip.module';
import {ImgOrLetterPictureModule} from '../../../img-or-letter/img-or-letter-picture.module';

@NgModule({
  declarations: [
    NavbarConversationComponent
  ],
    imports: [
        SharedModule,
        RouterModule,
        ListSelectCategoryModule,
        DropdownModule,
        ModalModule,
        DateAgoModule,
        TooltipModule,
        ImgOrLetterPictureModule
    ],
  exports: [
    NavbarConversationComponent
  ],
  providers: [],
})
export class NavbarConversationModule {
}
