import {createAction, props} from '@ngrx/store';
import {Blog} from '../../model/blog/blog.model';
import {FilterBlog} from '../../model/blog/filter-blog.model';
import {Page} from '../../model/page.model';
import {IListBlog} from '../../model/blog/list-blog.model';


export const loadBlogs = createAction(
  '[Blogs List] Load blogs via Service',
  props<{ filters: FilterBlog }>()
);

export const loadBlogsDone = createAction(
  '[Blogs Effect] Blogs Loaded Successfully',
  props<{ blogs: Page<IListBlog> }>()
);

export const loadBlogsFail = createAction(
  '[Blogs Effect] Blogs Loaded failed'
);

export const loadBlog = createAction(
  '[Blogs Effect] Load blog via Service',
  props<{ metaTitle: string }>()
);

export const loadBlogDone = createAction(
  '[Blogs Effect] Blog Loaded Successfully',
  props<{ blog: Blog }>()
);

export const loadBlogFail = createAction(
  '[Blogs Effect] Blog Loaded failed',
  props<{ message: string }>()
);

export const clearBlog = createAction(
  '[Blogs Effect] Clear blog store'
);

export const blogActionTypes = {
  loadBlogs,
  loadBlogsDone,
  loadBlogsFail,
  loadBlog,
  loadBlogDone,
  loadBlogFail,
  clearBlog,
};
