import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {BlogCategoryService} from 'src/app/shared/services/blog/blog-category.service';
import {UserService} from '../../../shared/services/user/user.service';
import {BlogCategory} from '../../model/blog-category/blog-category.model';
import {PaginationService} from '../../../shared/services/pagination.service';
import {blogCategoryActionTypes} from './blog-category.actions';
import {Page} from '../../model/page.model';
import {STATE_LIST_BLOG_CATEGORIES} from '../../transfert-state/state';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';

@Injectable()
export class BlogCategoryEffects {

  constructor(
    private blogCategoryService: BlogCategoryService,
    private userService: UserService,
    private actions$: Actions,
    private utilsTransfertsState: UtilsTransfertsState,
    private router: Router,
    private store: Store<BlogCategory>,
    private paginationService: PaginationService) {
  }

  loadBlogs$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(blogCategoryActionTypes.loadListBlogCategory),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_LIST_BLOG_CATEGORIES, this.blogCategoryService.query())),
        tap((blogCategories: Page<BlogCategory>) => this.paginationService.checkCurrentPageHaveItemsOrRedirect(blogCategories)),
        map(blogCategories => blogCategoryActionTypes.blogCategoryListLoadedDone({blogCategories})),
        catchError((err, caught) => {
          this.store.dispatch(blogCategoryActionTypes.blogCategoryListLoadedFail());
          return caught;
        })
      );
    }
  );
}
