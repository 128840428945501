import {NgModule} from '@angular/core';
import {ListSelectCategoryComponent} from './list-select-category.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared.module';

@NgModule({
  declarations: [
    ListSelectCategoryComponent
  ],
    imports: [
        SharedModule,
        RouterModule
    ],
  exports: [
    ListSelectCategoryComponent
  ],
  providers: [],
})
export class ListSelectCategoryModule {
}
