import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {MetaService} from './shared/services/util/meta.service';
import {SsrContext} from './shared/services/util/ssr-context.service';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  trackingCode = environment.googleAnalyticsTrackingCode;

  constructor(private metaService: MetaService,
              public ssrContext: SsrContext,
              private readonly renderer: Renderer2,
              private readonly el: ElementRef) {
  }

  ngOnInit(): void {
    if (!this.ssrContext.isServer()) {
      this.createAnalytics();
      this.createAd();
      this.clearCache();
    }

    this.metaService.createCanonicalURL();
  }

  private clearCache(): void {
    if (caches) {
      caches.keys().then(cs => {
        if (cs.length > 0) {
          const versionSW = localStorage.getItem('version_service_worker');
          if (versionSW == null || versionSW !== environment.VERSION_SW) {
            cs.forEach(c => caches.delete(c));
          }
        }
        localStorage.setItem('version_service_worker', environment.VERSION_SW);
      });
    }
  }

  private createAnalytics(): void {
    if (this.trackingCode) {
      const script = this.renderer.createElement('script') as HTMLScriptElement;
      script.src = `//www.googletagmanager.com/gtag/js?id=${this.trackingCode}`;
      script.async = true;
      this.renderer.appendChild(this.el.nativeElement, script);

      const script2 = this.renderer.createElement('script') as HTMLScriptElement;
      const scriptBody = this.renderer.createText(`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', '${this.trackingCode}');
      `);
      this.renderer.appendChild(script2, scriptBody);
      this.renderer.appendChild(this.el.nativeElement, script2);
    }
  }

  private createAd(): void {
    if (environment.adsense.show) {
      const script = this.renderer.createElement('script') as HTMLScriptElement;
      script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7418714798998462`;
      script.async = true;
      script.crossOrigin = 'anonymous';
      this.renderer.appendChild(this.el.nativeElement, script);
    }
  }
}
