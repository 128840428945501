import {createAction, props} from '@ngrx/store';
import {Withdrawal} from '../../model/withdrawal/withdrawal';
import {FilterWithdrawal} from '../../model/withdrawal/filter-withdrawal.model';
import {Page} from '../../model/page.model';
import {CreateWithdrawal} from '../../model/withdrawal/create-withdrawal.model';
import {PatchWithdrawal} from '../../model/withdrawal/patch-withdrawal.model';


export const loadListWithdrawal = createAction(
  '[Withdrawal Effect] Load Withdrawals via Service',
  props<{ filters: FilterWithdrawal }>()
);

export const withdrawalListLoadedDone = createAction(
  '[Withdrawal Effect] Withdrawals Loaded Successfully',
  props<{ withdrawals: Page<Withdrawal> }>()
);

export const withdrawalListLoadedFail = createAction(
  '[Withdrawal Effect] Withdrawals Loaded failed',
  props<{ message: string }>()
);

export const loadOneWithdrawal = createAction(
  '[Withdrawals List] Load Withdrawal via Service',
  props<{ id: string | number, userId: string }>()
);

export const withdrawalOneLoadedDone = createAction(
  '[Withdrawal Effect] Withdrawal Loaded Successfully',
  props<{ withdrawal: Withdrawal }>()
);

export const withdrawalOneLoadedFail = createAction(
  '[Withdrawal Effect] Withdrawal Loaded Fail',
  props<{ message: string }>()
);

export const createWithdrawal = createAction(
  '[Withdrawal Effect] Create Withdrawal',
  props<{ createWithdrawal: CreateWithdrawal, userId: string }>()
);

export const withdrawalCreatedDone = createAction(
  '[Withdrawal Effect] Withdrawal Created Successfully',
  props<{ withdrawal: Withdrawal }>()
);

export const withdrawalCreatedFail = createAction(
  '[Withdrawal Effect] Withdrawal Created failed',
  props<{ message: string }>()
);

export const patchWithdrawal = createAction(
  '[Withdrawal Effect] Update Withdrawal',
  props<{ patchWithdrawal: PatchWithdrawal, userId: string }>()
);

export const withdrawalPatchDone = createAction(
  '[Withdrawal Effect] Withdrawal Updated Successfully',
  props<{ withdrawal: Withdrawal }>()
);

export const withdrawalPatchFail = createAction(
  '[Withdrawal Effect] Withdrawal Updated fail',
  props<{ message: string }>()
);


export const deleteWithdrawal = createAction(
  '[Withdrawal Effect] Delete Withdrawal',
  props<{ id: string | number, userId: string }>()
);

export const withdrawalDeletedDone = createAction(
  '[Withdrawal Effect] Withdrawal Deleted Successfully',
  props<{ withdrawal: Withdrawal }>()
);

export const withdrawalDeletedFail = createAction(
  '[Withdrawal Effect] Withdrawal Deleted fail',
  props<{ message: string }>()
);

export const clearWithdrawal = createAction(
  '[Withdrawal Effect] Withdrawal clear all'
);


export const withdrawalActionTypes = {
  loadListWithdrawal,
  withdrawalListLoadedDone,
  withdrawalListLoadedFail,
  loadOneWithdrawal,
  withdrawalOneLoadedDone,
  withdrawalOneLoadedFail,
  createWithdrawal,
  withdrawalCreatedDone,
  withdrawalCreatedFail,
  patchWithdrawal,
  withdrawalPatchDone,
  withdrawalPatchFail,
  deleteWithdrawal,
  withdrawalDeletedDone,
  withdrawalDeletedFail,
  clearWithdrawal,
};
