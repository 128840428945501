import {PageFilter} from '../utils/page-filter.model';

export interface IFilterDomain extends PageFilter {
    title?: string;
    disabled?: boolean;
}

export class FilterDomain implements IFilterDomain {
    constructor(
        public title?: string,
        public disabled?: boolean,
        public page?: number,
        public size?: number,
        public properties?: string,
        public direction?: string,
    ) {
    }
}
