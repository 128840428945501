import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Page} from '../../core/model/page.model';

@Injectable({providedIn: 'root'})
export class PaginationService {
    constructor(private router: Router) {
    }

    checkCurrentPageHaveItemsOrRedirect(page: Page<any>): void {
        if (page.numberOfElements === 0 && page.totalElements > 0) {
            const urlTree = this.router.parseUrl(this.router.url);
            urlTree.queryParams.page = Math.floor(page.totalElements / page.size);
            this.router.navigateByUrl(urlTree).then();
        }
    }
}
