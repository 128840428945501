export class GlobalTranslate {
  static error = 'Une erreur s\'est produite, veuillez réitérer votre demande ou contacter le support';
  static user_need_connected = 'Vous n\'êtes pas connecté';
  static user_need_admin = 'Vous n\'avez pas le droit de faire cette action';
  static user_doesnt_exist = 'Ce profil n\'existe pas...';
  static recipe_not_found = 'La recette n\'existe pas';
  static cant_show_recipe = 'Vous ne pas acceder à la recette';
  static invalid_email = 'L\'Email est invalid';
  static invalid_username = 'Le speudo est invalid';
  static mail_already_exist = 'L\'Email est déja utilisé';
  static username_already_exist = 'Le speudo est déja utilisé';
  static username_not_valid = 'Le speudo est invalid';
  static user_disabled = 'Votre compte à été désactivé, veuillez contacter un administrateur';
  static cant_delete_recipe = 'Vous \'avez pas les droits pour supprimer cette recette';
}
