import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {StateKey, TransferState} from '@angular/platform-browser';
import {SsrContext} from './ssr-context.service';

@Injectable({providedIn: 'root'})
export class UtilsTransfertsState {
  constructor(private state: TransferState,
              private ssrContext: SsrContext) {

  }

  public getStateOrQuery(stateName: StateKey<any>, query: Observable<any | null>): Observable<any> {
    if (this.state.get(stateName, null)) {
      return of(this.state.get(stateName, [] as any))
        .pipe(tap(() => this.state.set(stateName, null)));
    } else {
      return query.pipe(tap(value => this.setStateIfServer(stateName, value)));
    }
  }

  private setStateIfServer(stateName: any, value): void {
    if (this.ssrContext.isServer()) {
      this.state.set(stateName, value);
    }
  }
}
