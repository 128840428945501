import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpUtils} from '../../shared/services/util/http-utils';

@Injectable({providedIn: 'root'})
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private httpUtils: HttpUtils) {
  }

  /**
   * Intercept the request and throw error if there is
   *
   * @param request to intercept
   * @param next event
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('X-Skip-Interceptor')) {
      const headers = request.headers.delete('X-Skip-Interceptor');
      return next.handle(request.clone({headers}));
    }


    if (request.url.endsWith('/api/users/') && request.method === 'GET') {
      return next.handle(request);
    }

    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  /**
   * Show error into log or anything
   *
   * @param response to parse the error
   */
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (response instanceof HttpErrorResponse) {
      this.httpUtils.displayHttpError(response as HttpErrorResponse);
    }

    throw response;
  }
}
