import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {
  constructor(private renderer: Renderer2,
              protected route: ActivatedRoute,
              private elementRef: ElementRef) {
  }

  private static clickInContent(targetElement: HTMLElement): boolean {
    let element = targetElement;
    while (true) {

      if (element && element.className && element.className.includes('dropdown-content')) {
        return true;
      }

      if (!element || element.className.includes('dropdown')) {
        return false;
      }

      element = element.parentElement;
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    const element = this.elementRef.nativeElement;
    const clickedInside = element.contains(targetElement);
    if (clickedInside) {
      const clickInContent = DropdownDirective.clickInContent(targetElement);
      if (clickInContent) {
        if (targetElement.className.includes('close')) {
          this.renderer.removeClass(element, 'show');
        }
      } else {
        if (element.className.includes('show')) {
          this.renderer.removeClass(element, 'show');
        } else {
          this.renderer.addClass(element, 'show');
        }
      }
    } else {
      this.renderer.removeClass(element, 'show');
    }
  }
}
