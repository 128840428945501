import {createAction, props} from '@ngrx/store';
import {BlogCategory} from '../../model/blog-category/blog-category.model';
import {Page} from '../../model/page.model';


export const loadListBlogCategory = createAction(
    '[BlogCategory Effect] Load BlogCategories via Service'
);

export const blogCategoryListLoadedDone = createAction(
    '[BlogCategory Effect] BlogCategories Loaded Successfully',
    props<{ blogCategories: Page<BlogCategory> }>()
);

export const blogCategoryListLoadedFail = createAction(
    '[BlogCategory Effect] BlogCategories Loaded failed'
);


export const blogCategoryActionTypes = {
    loadListBlogCategory,
    blogCategoryListLoadedDone,
    blogCategoryListLoadedFail
};
