export interface INotificationFilter {
  userId?: string;
  lastCreationDate?: string;
  limitToLast?: number;
}

export class NotificationFirebaseFilter implements INotificationFilter {
  constructor(
    public userId?: string,
    public lastCreationDate?: string,
    public limitToLast = 15,
  ) {
  }
}
