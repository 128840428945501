import {recipeActionTypes} from './recipe.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Recipe} from '../../model/recipe/recipe.model';
import {RecipeService} from '../../../shared/services/recipe/recipe.service';
import {TransferState} from '@angular/platform-browser';
import {STATE_LIST_RECIPES, STATE_ONE_RECIPE} from '../../transfert-state/state';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';
import {getErrorRequest} from '../../../shared/services/util/request-util';
import {Notif} from '../../model/notification/notif.model';
import {NotificationsService} from '../../../shared/services/util/notifications.service';

@Injectable()
export class RecipeEffects {

  loadRecipes$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(recipeActionTypes.loadRecipes),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_LIST_RECIPES, this.recipeService.query(action.filters))),
        map(recipes => recipeActionTypes.loadRecipesDone({recipes})),
        catchError((err, caught) => {
          this.store.dispatch(recipeActionTypes.loadRecipesFail());
          return caught;
        })
      );
    }
  );

  loadRecipe$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(recipeActionTypes.loadRecipe),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_ONE_RECIPE, this.recipeService.find(action.id))),
        map(recipe => recipeActionTypes.loadRecipeDone({recipe})),
        catchError((err, caught) => {
          this.store.dispatch(recipeActionTypes.loadRecipeFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  patchRecipe$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(recipeActionTypes.patchRecipe),
        switchMap((action) => this.recipeService.patch(action.patchRecipe)),
        map(recipe => recipeActionTypes.patchRecipeDone({recipe})),
        catchError((err, caught) => {
          const notification = new Notif({
            text: 'Une erreur est survenue lors de la modification de la Recette',
            level: 'error',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
          this.store.dispatch(recipeActionTypes.patchRecipeFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  createRecipe$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(recipeActionTypes.createRecipe),
        switchMap((action) => this.recipeService.create(action.createRecipe)),
        map(recipe => recipeActionTypes.createRecipeDone({recipe})),
        catchError((err, caught) => {
          this.store.dispatch(recipeActionTypes.createRecipeFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  deleteRecipe$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(recipeActionTypes.deleteRecipe),
        switchMap((action) => this.recipeService.delete(action.deleteRecipe)),
        map(recipe => recipeActionTypes.deleteRecipeDone({recipe})),
        catchError((err, caught) => {
          this.store.dispatch(recipeActionTypes.deleteRecipeFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  constructor(
    private recipeService: RecipeService,
    private actions$: Actions,
    private router: Router,
    private state: TransferState,
    private utilsTransfertsState: UtilsTransfertsState,
    private notificationsService: NotificationsService,
    private store: Store<Recipe>) {
  }

}
