import { GlobalTranslate } from 'src/app/features/global.translate';

export class MeansPaymentTranslate extends GlobalTranslate {
  static means_payment_not_found = 'Nous n\'avons pas pu trouver le moyen de paiement sélectionné. Veuillez vérifier et réessayer.';
  static max_means_payment = 'Vous êtes limité à 8 moyens de paiement. Veuillez supprimer un moyen de paiement existant pour en ajouter un nouveau.';
  static type_payment_is_required = 'Veuillez sélectionner un type de moyen de paiement (Paypal, etc.).';
  static id_paypal_is_required = 'Les informations relatives à Paypal sont nécessaires pour procéder. Veuillez les fournir.';
  static name_is_required = 'Veuillez saisir un nom pour le moyen de paiement.';
  static name_is_invalid = 'Le nom du moyen de paiement saisi est invalide. Veuillez vérifier et corriger.';
}
