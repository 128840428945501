import {commentActionTypes} from './comment.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Comment} from '../../model/comment/comment.model';
import {CommentService} from '../../../shared/services/comment.service';
import {TransferState} from '@angular/platform-browser';
import {STATE_LIST_COMMENTS} from '../../transfert-state/state';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';
import {getErrorRequest} from '../../../shared/services/util/request-util';

@Injectable()
export class CommentEffects {

  loadComments$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(commentActionTypes.loadComments),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_LIST_COMMENTS, this.commentService.query(action.filters))),
        map(comments => commentActionTypes.loadCommentsDone({comments})),
        catchError((err, caught) => {
          this.store.dispatch(commentActionTypes.loadCommentsFail());
          return caught;
        })
      );
    }
  );

  createComment$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(commentActionTypes.createComment),
        switchMap((action) => this.commentService.create(action.createComment)),
        map(comment => commentActionTypes.createCommentDone({comment})),
        catchError((err, caught) => {
          this.store.dispatch(commentActionTypes.createCommentFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  patchComment$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(commentActionTypes.patchComment),
        switchMap((action) => this.commentService.patch(action.patchComment)),
        map(comment => commentActionTypes.patchCommentDone({comment})),
        catchError((err, caught) => {
          this.store.dispatch(commentActionTypes.patchCommentFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  deleteComment$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(commentActionTypes.deleteComment),
        switchMap((action) => this.commentService.delete(action.deleteComment)),
        map(comment => commentActionTypes.deleteCommentDone({comment})),
        catchError((err, caught) => {
          this.store.dispatch(commentActionTypes.deleteCommentFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  constructor(
    private commentService: CommentService,
    private actions$: Actions,
    private router: Router,
    private state: TransferState,
    private utilsTransfertsState: UtilsTransfertsState,
    private store: Store<Comment>) {
  }
}
