import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import { BlogCategory } from '../../model/blog-category/blog-category.model';
import {blogCategoryActionTypes} from './blog-category.actions';

export interface BlogCategoryState extends EntityState<BlogCategory> {
    isLoadingList: boolean;
    totalElements: number;
    totalPages: number;
    empty: boolean;
    page: number;
    blogCategories: BlogCategory[];
}

export const adapter: EntityAdapter<BlogCategory> = createEntityAdapter<BlogCategory>({
    selectId: blogCategory => blogCategory.id
});

export const initialState = adapter.getInitialState({
    isLoadingList: false,
    totalElements: null,
    totalPages: null,
    page: 0,
    empty: true,
    blogCategories: [],
});

export const blogCategoryReducer = createReducer(
    initialState,

    on(blogCategoryActionTypes.loadListBlogCategory, (state, res) => ({
        ...state,
        isLoadingList: true,
    })),

    on(blogCategoryActionTypes.blogCategoryListLoadedDone, (state, action) => ({
        ...state,
        isLoadingList: false,
        totalElements: action.blogCategories.totalElements,
        totalPages: action.blogCategories.totalPages,
        empty: action.blogCategories.empty,
        page: action.blogCategories.number,
        blogCategories: action.blogCategories.content
    })),

    on(blogCategoryActionTypes.blogCategoryListLoadedFail, (state) => ({
            ...state,
            isLoadingList: false,
            isLoad: true,
        }
    )),
);

export const {selectAll, selectIds} = adapter.getSelectors();
