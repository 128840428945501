import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map, switchMap} from 'rxjs/operators';
import {AuthService} from '../../shared/services/auth/auth.service';


@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  private static injectIdToken(idToken: string, request: HttpRequest<any>): HttpRequest<any> {
    if (idToken) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + idToken
        }
      });
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request || !request.url || !request.url.startsWith(environment.SERVER_API_URL)) {
      return next.handle(request);
    }

    return this.authService.getIdToken()
      .pipe(
        map((idToken: string) => AuthInterceptor.injectIdToken(idToken, request)),
        switchMap((finalRequest: HttpRequest<any>) => next.handle(finalRequest)));
  }
}
