import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {Location} from '@angular/common';
import {User} from '../../../core/model/user/user.model';
import {Domain} from '../../../core/model/domain/domain.model';
import {Store} from '@ngrx/store';
import {loadDomains, loadDomainsDone} from '../../../core/store/domain/domain.actions';
import {Observable, Subject} from 'rxjs';
import {getAllDomains} from '../../../core/store/domain/domain.selectors';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {CategoryService} from '../../services/category.service';
import {FilterCategory} from '../../../core/model/category/filter-category.model';
import {ActivatedRoute, Router} from '@angular/router';
import {loadCategories} from '../../../core/store/category/category.actions';
import {Actions, ofType} from '@ngrx/effects';
import {ModalService} from '../../services/util/modal.service';
import {StateStorageService} from '../../services/auth/state-storage.service';
import {FilterDomain} from '../../../core/model/domain/filter-category.model';
import {getClassHeader} from '../../../core/store/header/header.selectors';
import {getVisibilityBgFooter, getVisibilityBtnOpinion} from '../../../core/store/footer/footer.selectors';

export interface ItemMenu {
  link: string;
  domain: Domain;
}

@Component({
  selector: 'app-nav-bar-component',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnDestroy, OnInit {
  user: User;
  showMenu = true;
  showGoUp = false;

  navigation: ItemMenu[] = [];
  disabled = '';

  selectedIndexMenu: number = null;
  classShowHeader = '';
  lastScroll = 0;
  lastScrollDown = 0;

  classHeader: Observable<string>;
  private _unsubscribeAll: Subject<any>;
  showOpinionForm = false;
  showOpinion: Observable<boolean>;
  classShowpinionForm = false;

  constructor(
    private authService: AuthService,
    public location: Location,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private stateStorageService: StateStorageService,
    private router: Router,
    private actions: Actions,
    private modalService: ModalService,
    private store: Store<Domain>
  ) {
    this._unsubscribeAll = new Subject();
    this.user = this.authService.userIdentity();
    this.showOpinion = this.store.select(getVisibilityBtnOpinion);

    this.watchRoute();
    this.watchQueryParam();
    const filterDomain = new FilterDomain();
    filterDomain.disabled = false;
    this.store.dispatch(loadDomains({filter: filterDomain}));
    this.generateMenuWithAllDomains();
    this.dispatchCategories();
  }

  ngOnInit(): void {
    this.classHeader = this.store.select(getClassHeader);

    this.authService.getUserState()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (user) => {
          this.user = user;
        },
        error: (err) => {
          console.log(err);
        }
      });
  }

  @HostListener('window:scroll', ['$event'])
  scrollPage(): void {
    this.showGoUp = window.scrollY > 100;

    if (window.scrollY < 70) {
      this.classShowHeader = '';
      return;
    }

    if (this.lastScrollDown - 100 < window.scrollY) {
      this.classShowHeader = 'hide';
    } else {
      this.classShowHeader = 'inner-page';
    }

    if (this.lastScroll < window.scrollY) {
      this.lastScrollDown = window.scrollY;
    }

    this.lastScroll = window.scrollY;
  }

  private generateMenuWithAllDomains(): void {
    this.actions.pipe(
      takeUntil(this._unsubscribeAll),
      ofType(loadDomainsDone)
    ).subscribe({
      next: (data: any) => {
        this.generateNavigation(data.domains.content);
      },
      error: (err) => {
        console.log(err);
      }
    });

    this.store.select(getAllDomains)
      .pipe(take(1))
      .subscribe({
        next: (domains) => {
          this.generateNavigation(domains);
        },
        error: (err) => {
          console.log(err);
        }
      });
  }

  private generateNavigation(domains: Domain[]): void {
    domains.forEach(domain => {
      this.navigation.push({link: '/recette/liste/' + domain.title, domain});
    });
  }


  private watchRoute(): void {
    this.router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: () => {
        this.hideSubMenu();
        this.showMenu = false;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  private watchQueryParam(): void {
    this.route.queryParamMap
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: () => {
          this.hideSubMenu();
          this.showMenu = false;
        },
        error: (err) => {
          console.log(err);
        }
      });
  }


  private dispatchCategories(): void {
    this.store.select(getAllDomains)
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((allDomain) => allDomain != null && allDomain.length !== 0),
        map((allDomain) => allDomain.map((domain) => domain.title)),
        map((currentDomain) => new FilterCategory(currentDomain))
      )
      .subscribe({
        next: (filterCategory) => {
          this.store.dispatch(loadCategories({ filters: filterCategory }));
        },
        error: (err) => {
          console.log(err);
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next({});
    this._unsubscribeAll.complete();
  }

  hideSubMenu(): void {
    this.disabled = 'disabled';
    setTimeout(() => {
      this.disabled = '';
    }, 1000);
  }

  toggleMobile(index: number): void {
    this.selectedIndexMenu = this.selectedIndexMenu === index ? null : index;
  }

  toogleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  openModal(id: string): void {
    this.modalService.open(id);
  }

  closeModal(id: string): void {
    this.modalService.close(id);
  }

  closeModalLogin(id: string): void {
    this.closeModal(id);
    this.stateStorageService.storeUrl(this.router.url);
  }

  saveUrl(): void {
    this.stateStorageService.storeUrl(this.router.url);
  }

  goUp(): void {
    window.scrollTo(0, 0);
  }
}
