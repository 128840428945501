import {createReducer, on} from '@ngrx/store';
import {recipeActionTypes} from './recipe.actions';
import {FilterRecipe} from '../../model/recipe/filter-recipe.model';
import {IListRecipe, ListRecipe} from '../../model/recipe/list-recipe.model';
import {IRecipe, Recipe} from '../../model/recipe/recipe.model';
import {EntityState} from '@ngrx/entity';

export interface RecipeState extends EntityState<ListRecipe> {
  isLoadingList: boolean;
  isLoadingOne: boolean;
  isLoadingCreate: boolean;
  isLoadingPatch: boolean;
  messageErrorLoadRecipe: string;
  messageErrorCreateRecipe: string;
  messageErrorDeleteRecipe: string;
  messageErrorPatchRecipe: string;
  totalElements: number;
  totalPages: number;
  empty: boolean;
  page: number;
  recipe: Recipe;
  recipePatch: Recipe;
  recipeCreate: Recipe;
  recipes: IListRecipe[];
  filters: FilterRecipe;
}

export const initialState = {
  isLoadingList: false,
  isLoadingOne: false,
  isLoadingCreate: false,
  isLoadingPatch: false,
  messageErrorLoadRecipe: null,
  messageErrorCreateRecipe: null,
  messageErrorDeleteRecipe: null,
  messageErrorPatchRecipe: null,
  recipe: null,
  recipePatch: null,
  recipeCreate: null,
  recipes: [],
  totalElements: null,
  totalPages: null,
  page: 0,
  empty: true,
};

function updateRecipe(action, state): IRecipe {
  const currentRecipe: IRecipe = JSON.parse(JSON.stringify(state.recipe));

  if (currentRecipe && currentRecipe.id === action.recipe.id) {
    return action.recipe;
  }

  return currentRecipe;
}

function updateOneRecipes(action, state): IRecipe[] {
  const currentRecipes: IRecipe[] = JSON.parse(JSON.stringify(state.recipes));
  return currentRecipes.map(obj => obj.id === action.recipe.id ? action.recipe : obj);
}

function deleteOneRecipes(action, state): IRecipe[] {
  const currentRecipes: IRecipe[] = JSON.parse(JSON.stringify(state.recipes));
  return currentRecipes.filter(obj => obj.id !== action.recipe.id);
}

export const recipeReducer = createReducer(
  initialState,

  on(recipeActionTypes.loadRecipes, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.filters
  })),

  on(recipeActionTypes.loadRecipesDone, (state, action: any) => ({
    ...state,
    isLoadingList: false,
    totalElements: action.recipes.totalElements,
    totalPages: action.recipes.totalPages,
    empty: action.recipes.empty,
    page: action.recipes.number,
    recipes: action.recipes.content
  })),

  on(recipeActionTypes.loadRecipesFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(recipeActionTypes.loadRecipe, state => ({
    ...state,
    isLoadingOne: true,
    messageErrorLoadRecipe: null
  })),

  on(recipeActionTypes.loadRecipeDone, (state, action) => ({
      ...state,
      isLoadingOne: false,
      recipe: action.recipe,
    }
  )),

  on(recipeActionTypes.loadRecipeFail, (state, action) => ({
      ...state,
      isLoadingOne: false,
      messageErrorLoadRecipe: action.message
    }
  )),

  on(recipeActionTypes.patchRecipe, (state) => ({
      ...state,
      isLoadingPatch: true,
    }
  )),

  on(recipeActionTypes.patchRecipeFail, (state, action) => ({
      ...state,
      isLoadingPatch: false,
      messageErrorPatchRecipe: action.message
    }
  )),

  on(recipeActionTypes.patchRecipeDone, (state, action) => ({
      ...state,
      isLoadingPatch: false,
      isLoadingOne: false,
      isLoadingList: false,
      recipePatch: action.recipe,
      recipe: updateRecipe(action, state),
      recipes: updateOneRecipes(action, state),
    }
  )),

  on(recipeActionTypes.createRecipe, (state) => ({
    ...state,
    isLoadingCreate: true,
    messageErrorCreateRecipe: null,
  })),

  on(recipeActionTypes.createRecipeDone, (state, action) => ({
    ...state,
    isLoadingCreate: false,
    recipeCreate: action.recipe
  })),

  on(recipeActionTypes.createRecipeFail, (state, action) => ({
      ...state,
      isLoadingCreate: false,
      messageErrorCreateRecipe: action.message
    }
  )),

  on(recipeActionTypes.deleteRecipe, (state) => ({
    ...state,
    isLoadingList: true,
    messageErrorDeleteRecipe: null,
  })),

  on(recipeActionTypes.deleteRecipeDone, (state, action) => ({
    ...state,
    isLoadingList: false,
    recipes: deleteOneRecipes(action, state),
  })),

  on(recipeActionTypes.deleteRecipeFail, (state, action) => ({
      ...state,
      isLoadingList: false,
      messageErrorDeleteRecipe: action.message
    }
  )),

  on(recipeActionTypes.clearRecipe, () => ({
      ...initialState
    }
  )),
);
