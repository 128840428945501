import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, isDevMode} from '@angular/core';

@Injectable({providedIn: 'root'})
export class HttpUtils {
  constructor() {
  }

  public displayHttpError(error: HttpErrorResponse): void {
    let message = error.message;
    if (error.error && error.error.code && typeof error.error.code === 'string') {
      message = error.error.code;
    }

    if (error.error && error.error.detail && typeof error.error.detail === 'string' && isDevMode()) {
      console.log('-------');
      console.log(message);
      console.log('-------');
    }

    // @todo notification
  }

  public extractHostname(url): string {
    let hostname: string;

    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    hostname = hostname.replace('www.', '');
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
    hostname = hostname.split('.')[0];

    return hostname.toLowerCase();
  }
}
