import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {categoryActionTypes} from './category.actions';
import {FilterCategory} from '../../model/category/filter-category.model';
import {Category} from '../../model/category/category.model';

export interface CategoryState extends EntityState<Category> {
  isLoadingList: boolean;
  category: Category;
  filters: FilterCategory;
}

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>({
  selectId: category => category.id
});

export const initialState = adapter.getInitialState({
  isLoadingList: false,
  category: null
});

export const categoryReducer = createReducer(
  initialState,

  on(categoryActionTypes.loadCategories, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.filters
  })),


  on(categoryActionTypes.loadCategoriesDone, (state, action) => {
    return adapter.setAll(
      action.categories.content,
      {
        ...state,
        isLoadingList: false,
      }
    );
  }),

  on(categoryActionTypes.loadCategoriesFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(categoryActionTypes.selectCategory, (state, action) => {
    return ({
        ...state,
        category: action.category,
      }
    );
  }),
);

export const {selectAll} = adapter.getSelectors();
