<app-nav-bar-component></app-nav-bar-component>

<app-notifications></app-notifications>

<main class="content">
  <router-outlet></router-outlet>
</main>

<app-footer-component></app-footer-component>

<ng-container *ngIf="ssrContext.isServer()">
  <div class="loader">
    <app-loader-component></app-loader-component>
  </div>
</ng-container>
