<div class="form-opinion">
  <p class="title">Nous avons besoin de vous pour améliorer Tooda</p>
  <p class="sub-title">Partagez votre expérience, vos avis et suggestions</p>

  <form (ngSubmit)="sendOpinion()" [formGroup]="form">
    <div [className]="getClassBlocInput(form, 'opinion')">

      <label>
        <textarea class="input" formControlName="opinion" maxlength="5000" required
                  placeholder="Donne nous ton retour, tes améliorations ..."></textarea>
      </label>

      <div class="input-info">
        5000 caractères maximum
      </div>

      <div class="input-error">
        <span *ngIf="showError(form, 'opinion', 'required')">Ce champs est obligatoire</span>
        <span
          *ngIf="showError(form, 'opinion', 'maxLength')">Ce champs ne doit pas dépasser 5 000 caractères</span>
      </div>
    </div>

    <button *ngIf="!loading" class="btn btn-raised-main" type="submit">J'envoie</button>
    <button *ngIf="loading" class="btn btn-raised-main" type="submit">Envoi en cours
    </button>

  </form>
</div>
