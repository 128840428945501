import {FooterState} from './footer.reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const footerFeatureSelector = createFeatureSelector<FooterState>('footer');

export const getVisibilityBgFooter = createSelector(
  footerFeatureSelector,
  state => state.visibilityBgFooter
);

export const getVisibilityBtnOpinion = createSelector(
  footerFeatureSelector,
  state => state.visibilityBtnOpinion
);

export const getVisibilityStreamerRegistration = createSelector(
  footerFeatureSelector,
  state => state.visibilityStreamerRegistration
);
