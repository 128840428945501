import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {Withdrawal} from '../../model/withdrawal/withdrawal';
import {FilterWithdrawal} from '../../model/withdrawal/filter-withdrawal.model';
import {withdrawalActionTypes} from './withdrawal.actions';

export interface WithdrawalState extends EntityState<Withdrawal> {
  isLoadingList: boolean;
  isLoadingOne: boolean;
  isLoadingPatch: boolean;
  isLoadingCreate: boolean;
  isLoadingDelete: boolean;
  messageErrorLoadWithdrawal: string;
  messageErrorLoadsWithdrawal: string;
  messageErrorCreateWithdrawal: string;
  messageErrorDeleteWithdrawal: string;
  messageErrorPatchWithdrawal: string;
  totalElements: number;
  totalPages: number;
  empty: boolean;
  page: number;
  filters: FilterWithdrawal;
  withdrawal: Withdrawal;
  withdrawals: Withdrawal[];
}

export const adapter: EntityAdapter<Withdrawal> = createEntityAdapter<Withdrawal>({
  selectId: withdrawal => withdrawal.id
});

export const initialState = adapter.getInitialState({
  isLoadingList: false,
  isLoadingOne: false,
  isLoadingPatch: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  messageErrorLoadWithdrawal: null,
  messageErrorLoadsWithdrawal: null,
  messageErrorCreateWithdrawal: null,
  messageErrorDeleteWithdrawal: null,
  messageErrorPatchWithdrawal: null,
  totalElements: null,
  totalPages: null,
  page: 0,
  empty: true,
  withdrawal: null,
  withdrawals: [],
});

export const withdrawalReducer = createReducer(
  initialState,

  on(withdrawalActionTypes.loadListWithdrawal, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.filters,
    messageErrorLoadsWithdrawal: null,
  })),

  on(withdrawalActionTypes.withdrawalListLoadedDone, (state, action) => ({
    ...state,
    isLoadingList: false,
    totalElements: action.withdrawals.totalElements,
    totalPages: action.withdrawals.totalPages,
    empty: action.withdrawals.empty,
    page: action.withdrawals.number,
    withdrawals: action.withdrawals.content,
  })),

  on(withdrawalActionTypes.withdrawalListLoadedFail, (state, action) => ({
      ...state,
      isLoadingList: false,
      isLoad: true,
      messageErrorDeleteRecipe: action.message
    }
  )),

  on(withdrawalActionTypes.loadOneWithdrawal, (state) => ({
    ...state,
    isLoadingOne: true,
    messageErrorLoadWithdrawal: null,
  })),

  on(withdrawalActionTypes.withdrawalOneLoadedDone, (state, action) => ({
    ...state,
    withdrawal: action.withdrawal,
    isLoadingOne: false,
  })),

  on(withdrawalActionTypes.withdrawalOneLoadedFail, (state, action) => ({
    ...state,
    isLoadingOne: false,
    messageErrorLoadWithdrawal: action.message
  })),

  on(withdrawalActionTypes.createWithdrawal, (state) => ({
    ...state,
    isLoadingCreate: true,
    messageErrorCreateWithdrawal: null,
  })),

  on(withdrawalActionTypes.withdrawalCreatedDone, (state, action) => ({
    ...state,
    withdrawal: action.withdrawal,
    isLoadingCreate: false
  })),

  on(withdrawalActionTypes.withdrawalCreatedFail, (state, action) => ({
    ...state,
    isLoadingCreate: false,
    messageErrorCreateWithdrawal: action.message
  })),

  on(withdrawalActionTypes.deleteWithdrawal, (state) => ({
    ...state,
    isLoadingDelete: true,
    messageErrorDeleteWithdrawal: null,
  })),

  on(withdrawalActionTypes.withdrawalDeletedDone, (state) => ({
    ...state,
    isLoadingDelete: false
  })),

  on(withdrawalActionTypes.withdrawalDeletedFail, (state, action) => ({
    ...state,
    isLoadingDelete: false,
    messageErrorDeleteWithdrawal: action.message
  })),

  on(withdrawalActionTypes.patchWithdrawal, (state) => ({
    ...state,
    isLoadingPatch: true,
    messageErrorPatchWithdrawal: null,
  })),

  on(withdrawalActionTypes.withdrawalPatchDone, (state, action) => ({
    ...state,
    withdrawal: action.withdrawal,
    isLoadingPatch: false,
  })),

  on(withdrawalActionTypes.withdrawalPatchFail, (state, action) => ({
    ...state,
    isLoadingPatch: false,
    messageErrorPatchWithdrawal: action.message
  })),

  on(withdrawalActionTypes.clearWithdrawal, () => ({
    ...initialState
  })),
);
