import {createAction, props} from '@ngrx/store';
import {IMonetizationPosition} from '../../model/monetization/monetization-position.model';
import {FilterMonetizationPosition} from '../../model/monetization/filter-monetization-position.model';
import {ToggleMonetizationPosition} from '../../model/monetization/toggle-monetization-position.model';


export const loadMonetizationPositions = createAction(
  '[MonetizationPositions List] Load monetizationPositions via Service',
  props<{ filters: FilterMonetizationPosition }>()
);

export const loadMonetizationPositionsDone = createAction(
  '[MonetizationPositions Effect] MonetizationPositions Loaded Successfully',
  props<{ monetizationPositions: IMonetizationPosition[] }>()
);

export const loadMonetizationPositionsFail = createAction(
  '[MonetizationPositions Effect] MonetizationPositions Loaded failed'
);

export const clearMonetizationPosition = createAction(
  '[MonetizationPositions Effect] Clear monetizationPosition store'
);

export const createMonetizationPosition = createAction(
  '[MonetizationPositions Effect] Create monetizationPosition',
  props<{ monetizationPosition: ToggleMonetizationPosition }>()
);

export const createMonetizationPositionDone = createAction(
  '[MonetizationPositions Effect] Create monetizationPosition done',
  props<{ monetizationPosition: IMonetizationPosition }>()
);

export const createMonetizationPositionFail = createAction(
  '[MonetizationPositions Effect] Create monetizationPosition fail',
  props<{ message: string }>()
);

export const deleteMonetizationPosition = createAction(
  '[MonetizationPositions Effect] Delete monetizationPosition',
  props<{ monetizationPosition: ToggleMonetizationPosition }>()
);

export const deleteMonetizationPositionDone = createAction(
  '[MonetizationPositions Effect] Delete monetizationPosition done',
  props<{ monetizationPosition: IMonetizationPosition }>()
);

export const deleteMonetizationPositionFail = createAction(
  '[MonetizationPositions Effect] Delete monetizationPosition fail',
  props<{ message: string }>()
);


export const monetizationPositionActionTypes = {
  loadMonetizationPositions,
  loadMonetizationPositionsDone,
  loadMonetizationPositionsFail,
  clearMonetizationPosition,
  createMonetizationPosition,
  createMonetizationPositionDone,
  createMonetizationPositionFail,
  deleteMonetizationPosition,
  deleteMonetizationPositionDone,
  deleteMonetizationPositionFail,
};
