import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NotificationFirebase} from '../../core/model/notification/notification-firebase.model';
import {NotificationFirebaseFilter} from '../../core/model/notification/notification-firebase-filter.model';
import {take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AngularFireDatabase, SnapshotAction} from '@angular/fire/compat/database';

@Injectable({providedIn: 'root'})
export class NotificationService {
  public resourceUrl = environment.SERVER_API_URL + 'api/notifications';

  constructor(private http: HttpClient,
              private db: AngularFireDatabase) {
  }

  query(notificationFilter: NotificationFirebaseFilter): Observable<NotificationFirebase[]> {

    let query = ref => ref.orderByChild('creationDate').limitToLast(notificationFilter.limitToLast);
    if (notificationFilter.lastCreationDate) {
      query = ref => ref.orderByChild('creationDate')
        .endAt(notificationFilter.lastCreationDate)
        .limitToLast(notificationFilter.limitToLast);
    }

    return this.db.list<NotificationFirebase>('usersNotification/' + notificationFilter.userId + '/notifications', query)
      .valueChanges()
      .pipe(take(1));
  }

  stateChangeAdded(userId: string): Observable<SnapshotAction<NotificationFirebase>> {
    const time = new Date().getTime().toString();
    return this.db.list<NotificationFirebase>(
      'usersNotification/' + userId + '/notifications',
      ref => ref.orderByChild('creationDate').startAt(time)
    ).stateChanges(['child_added']);
  }

  stateChangeRemoved(userId: string): Observable<SnapshotAction<NotificationFirebase>> {
    return this.db.list<NotificationFirebase>(
      'usersNotification/' + userId + '/notifications',
      ref => ref.orderByChild('creationDate')
    ).stateChanges(['child_removed']);
  }

  count(notificationFilter: NotificationFirebaseFilter): Observable<number | number[]> {
    return this.db.object<number>('usersNotification/' + notificationFilter.userId + '/unread')
      .valueChanges();
  }


  resetCount(): Observable<void> {
    return this.http.post<void>(`${this.resourceUrl}/read`, {});
  }
}
