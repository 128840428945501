export interface IFilterAlgoliaRequest {
  indexName?: string;
  params?: string;
}

export class FilterAlgoliaRequest implements IFilterAlgoliaRequest {
  constructor(
    public indexName?: string,
    public params?: string,
  ) {
  }
}
