import {createReducer, on} from '@ngrx/store';
import {ingredientActionTypes} from './ingredient.actions';
import {FilterIngredient} from '../../model/ingredient/filter-ingredient.model';
import {Ingredient} from '../../model/ingredient/ingredient.model';
import {EntityState} from '@ngrx/entity';
import {IListIngredient, ListIngredient} from '../../model/ingredient/list-ingredient.model';

export interface IngredientState extends EntityState<ListIngredient> {
  isLoadingList: boolean;
  isLoadingOne: boolean;
  messageErrorLoadIngredient: string;
  totalElements: number;
  totalPages: number;
  empty: boolean;
  page: number;
  ingredient: Ingredient;
  ingredients: IListIngredient[];
  filters: FilterIngredient;
}

export const initialState = {
  isLoadingList: false,
  isLoadingOne: false,
  messageErrorLoadIngredient: null,
  ingredient: null,
  ingredients: [],
  totalElements: null,
  totalPages: null,
  page: 0,
  empty: true,
};

function getIngredients(state: any, action: any): IListIngredient[] {
  if (state.filters.add) {
    return state.ingredients.concat(action.ingredients.content);
  }
  return action.ingredients.content;
}

export const ingredientReducer = createReducer(
  initialState,

  on(ingredientActionTypes.loadIngredients, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.filters
  })),

  on(ingredientActionTypes.loadIngredientsDone, (state, action: any) => ({
    ...state,
    isLoadingList: false,
    totalElements: action.ingredients.totalElements,
    totalPages: action.ingredients.totalPages,
    empty: action.ingredients.empty,
    page: action.ingredients.number,
    ingredients: getIngredients(state, action)
  })),

  on(ingredientActionTypes.loadIngredientsFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(ingredientActionTypes.loadIngredient, state => ({
    ...state,
    isLoadingOne: true,
    messageErrorLoadIngredient: null
  })),

  on(ingredientActionTypes.loadIngredientDone, (state, action) => ({
      ...state,
      isLoadingOne: false,
      ingredient: action.ingredient,
    }
  )),

  on(ingredientActionTypes.loadIngredientFail, (state, action) => ({
      ...state,
      isLoadingOne: false,
      messageErrorLoadIngredient: action.message
    }
  )),

  on(ingredientActionTypes.clearIngredient, (state) => ({
      ...state,
      ingredient: null,
    }
  )),
);
