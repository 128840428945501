import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IUser} from '../../core/model/user/user.model';

@Injectable({providedIn: 'root'})
export class OpinionService {
  public resourceUrl = environment.SERVER_API_URL + 'api/opinions';

  constructor(private http: HttpClient) {
  }

  create(opinion: string): Observable<IUser | null> {
    return this.http.post<IUser>(`${this.resourceUrl}`, opinion);
  }
}
