import {userActionTypes} from './user.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {User} from '../../model/user/user.model';
import {UserService} from '../../../shared/services/user/user.service';
import {TransferState} from '@angular/platform-browser';
import {STATE_LIST_USERS, STATE_ONE_USER} from '../../transfert-state/state';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';
import {getErrorRequest} from '../../../shared/services/util/request-util';
import {Notif} from '../../model/notification/notif.model';
import {NotificationsService} from '../../../shared/services/util/notifications.service';
import {AuthService} from '../../../shared/services/auth/auth.service';

@Injectable()
export class UserEffects {

  loadUsers$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(userActionTypes.loadUsers),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_LIST_USERS, this.userService.query(action.filters))),
        map(users => userActionTypes.loadUsersDone({users})),
        catchError((err, caught) => {
          this.store.dispatch(userActionTypes.loadUsersFail());
          return caught;
        })
      );
    }
  );

  loadUser$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(userActionTypes.loadUser),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_ONE_USER, this.userService.findByUsername(action.id))),
        map(user => userActionTypes.loadUserDone({user})),
        catchError((err, caught) => {
          this.store.dispatch(userActionTypes.loadUserFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  refreshUser$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(userActionTypes.refreshUser),
        switchMap((action) => this.userService.find(action.userId)),
        tap(user => this.setCurrentUser(user)),
        map(user => userActionTypes.loadUserDone({user})),
        catchError((err, caught) => {
          this.store.dispatch(userActionTypes.loadUserFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  patchUser$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(userActionTypes.patchUser),
        switchMap((action) => this.userService.patch(action.patchUser)),
        tap(user => {
          this.setCurrentUser(user);
          const notification = new Notif({
            text: 'Modification réussi de l\'utilisateur : ' + user.username,
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }),
        map(user => userActionTypes.patchUserDone({user})),
        catchError((err, caught) => {
          this.store.dispatch(userActionTypes.patchUserFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  updatePicture$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(userActionTypes.updatePictureUser),
        switchMap((action) => this.userService.updatePicture(action.file, action.id)),
        tap(user => {
          const notification = new Notif({
            text: 'Modification de la photo réussi',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
          this.setCurrentUser(user);
        }),
        map(user => userActionTypes.patchUserDone({user})),
        catchError((err, caught) => {
          this.store.dispatch(userActionTypes.patchUserFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  deleteUser$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(userActionTypes.deleteUser),
        switchMap((action) => this.userService.delete(action.id)),
        tap(user => {
          const notification = new Notif({
            text: 'Suppresion de l\'utilisateur dans 30 jours',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
          this.setCurrentUser(user);
        }),
        map(user => userActionTypes.deleteUserDone({user})),
        catchError((err, caught) => {
          this.store.dispatch(userActionTypes.deleteUserFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  cancelDeleteUser$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(userActionTypes.cancelDeleteUser),
        switchMap((action) => this.userService.cancelDelete(action.id)),
        tap(user => {
          const notification = new Notif({
            text: 'Annulation de la suppresion de l\'utilisateur',
            level: 'success',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
          this.setCurrentUser(user);
        }),
        map(user => userActionTypes.cancelDeleteUserDone({user})),
        catchError((err, caught) => {
          this.store.dispatch(userActionTypes.cancelDeleteUserFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );


  private setCurrentUser(user: User): void {
    const currentUser = this.authService.userIdentity();
    if (currentUser.id === user.id) {
      currentUser.username = user.username;
      currentUser.balance = user.balance;
      currentUser.email = user.email;
      currentUser.userPicture = user.userPicture;
      currentUser.name = user.name;
      currentUser.dateLastUpdateUsername = user.dateLastUpdateUsername;
      currentUser.hasMonetization = user.hasMonetization;
      currentUser.dateToDelete = user.dateToDelete;
      this.authService.setCurrentUser(currentUser);
    }
  }

  constructor(
    private userService: UserService,
    private actions$: Actions,
    private router: Router,
    private state: TransferState,
    private utilsTransfertsState: UtilsTransfertsState,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private store: Store<User>) {
  }
}
