import {createReducer, on} from '@ngrx/store';
import {algoliaActionTypes} from './algolia.actions';
import {IAlgoliaResults} from '../../model/algolia/algolia-results.model';
import {IAlgoliaResult} from '../../model/algolia/algolia-result.model';

export interface AlgoliaState {
  isLoadingAlgoliaMulti: boolean;
  algoliaMultiResult: IAlgoliaResults;
  messageErrorAlgoliaMulti: string;
  isLoadingAlgoliaMultiPage: boolean;
  algoliaMultiResultPage: IAlgoliaResults;
  messageErrorAlgoliaMultiPage: string;
}

export const initialState = {
  isLoadingMultiAlgolia: false,
  results: null,
  messageErrorMultiAlgolia: '',
  isLoadingAlgoliaMultiPage: false,
  algoliaMultiResultPage: null,
  messageErrorAlgoliaMultiPage: '',
  isLoadingAlgolia: false,
  messageErrorAlgolia: '',
};

function addHits(resultsPage: IAlgoliaResults, newAlgoliaResult: IAlgoliaResult): IAlgoliaResults {
  const currentResults: IAlgoliaResults = JSON.parse(JSON.stringify(resultsPage));

  currentResults.results.forEach(algoliaResult => {
    if (algoliaResult.index === newAlgoliaResult.indexUsed) {
      newAlgoliaResult.hits.forEach(newHit => {
        algoliaResult.hits.push(newHit);
      });
      algoliaResult.page = newAlgoliaResult.page;
      algoliaResult.nbPages = newAlgoliaResult.nbPages;
    }
  });

  return currentResults;
}

export const algoliaReducer = createReducer(
  initialState,

  on(algoliaActionTypes.loadMultiAlgolia, (state) => ({
      ...state,
      isLoadingMultiAlgolia: true,
      messageErrorMultiAlgolia: ''
    }
  )),

  on(algoliaActionTypes.loadMultiAlgoliaDone, (state, action) => ({
      ...state,
      isLoadingMultiAlgolia: false,
      algoliaMultiResult: action.results,
      messageErrorMultiAlgolia: ''
    }
  )),

  on(algoliaActionTypes.loadMultiAlgoliaFail, (state, action) => ({
      ...state,
      isLoadingMultiAlgolia: false,
      messageErrorMultiAlgolia: action.message
    }
  )),

  on(algoliaActionTypes.loadMultiAlgoliaPage, (state) => ({
      ...state,
      isLoadingMultiAlgoliaPage: true,
      messageErrorMultiAlgoliaPage: ''
    }
  )),

  on(algoliaActionTypes.loadMultiAlgoliaDonePage, (state, action) => ({
      ...state,
      isLoadingMultiAlgoliaPage: false,
      algoliaMultiResultPage: action.results,
      messageErrorMultiAlgoliaPage: ''
    }
  )),

  on(algoliaActionTypes.loadMultiAlgoliaFailPage, (state, action) => ({
      ...state,
      isLoadingMultiAlgoliaPage: false,
      messageErrorMultiAlgoliaPage: action.message
    }
  )),

  on(algoliaActionTypes.loadAlgolia, (state) => ({
      ...state,
      isLoadingAlgolia: true,
      messageErrorAlgolia: ''
    }
  )),

  on(algoliaActionTypes.loadAlgoliaDone, (state, action) => ({
      ...state,
      isLoadingAlgolia: false,
      algoliaMultiResultPage: addHits(state.algoliaMultiResultPage, action.results),
      messageErrorAlgolia: ''
    }
  )),

  on(algoliaActionTypes.loadAlgoliaFail, (state, action) => ({
      ...state,
      isLoadingAlgolia: false,
      messageErrorAlgolia: action.message
    }
  )),

  on(algoliaActionTypes.clearAlgoliaNavBar, (state) => ({
      ...state,
      isLoadingMultiAlgolia: false,
      algoliaMultiResult: null,
      messageErrorMultiAlgolia: ''
    }
  )),

  on(algoliaActionTypes.clearAlgolia, () => ({
      ...initialState,
    }
  )),
);
