import {EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {reportActionTypes} from './report.actions';
import {Report} from '../../model/report/report.model';

export interface ReportState extends EntityState<Report> {
  isLoadingCreate: boolean;
  errorCreate: string;
  reportCreate: Report;
}

export const initialState = {
  isLoadingCreate: false,
  errorCreate: null,
  reportCreate: null,
};

export const reportReducer = createReducer(
  initialState,

  on(reportActionTypes.createReport, (state) => ({
    ...state,
    isLoadingCreate: true,
    errorCreate: null,
  })),

  on(reportActionTypes.createReportDone, (state, action) => ({
    ...state,
    isLoadingCreate: false,
    reportCreate: action.report,
    errorCreate: null,
  })),

  on(reportActionTypes.createReportFail, (state, action) => ({
      ...state,
      isLoadingCreate: false,
      errorCreate: action.message,
    }
  )),

  on(reportActionTypes.clearReport, () => ({
      ...initialState,
    }
  )),
);
