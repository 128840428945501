import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../../core/model/user/user.model';
import {
  clearConversations,
  countConversationsUnread,
  loadConversationsNavbar,
} from '../../../../../core/store/conversation/conversation.actions';
import {ConversationFilter} from '../../../../../core/model/conversation/conversation-filter.model';
import {AppState} from '../../../../../core/store/app.state';
import {Store} from '@ngrx/store';
import {Conversation} from '../../../../../core/model/conversation/conversation.model';
import {Observable, Subject} from 'rxjs';
import {
  getAllConversationsNavbar,
  getConversation,
  getConversationNavbarFilter,
  getCountConversations,
  isLoadingConversationNavbarList,
  noMoreConversationNavbarInFirebase
} from '../../../../../core/store/conversation/conversation.selectors';
import {filter, switchMap, take, takeUntil} from 'rxjs/operators';
import {getClassHeader} from '../../../../../core/store/header/header.selectors';
import {ListUser} from '../../../../../core/model/user/list-user.model';
import {UserService} from '../../../../services/user/user.service';
import {Notif} from '../../../../../core/model/notification/notif.model';
import {ConversationService} from '../../../../services/message/conversation.service';
import {Router} from '@angular/router';
import {NotificationsService} from '../../../../services/util/notifications.service';
import {MessageService} from '../../../../services/message/message.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {SsrContext} from '../../../../services/util/ssr-context.service';

@Component({
  selector: 'app-nav-bar-conversation-component',
  templateUrl: './navbar-conversation.component.html',
  styleUrls: ['./navbar-conversation.component.scss', '../navbar-items.component.scss'],
})
export class NavbarConversationComponent implements OnInit, OnDestroy {
  @Input() user: User;
  conversations: Conversation[] = [];
  countConversation: Observable<number | number[]>;
  isLoading: Observable<boolean>;
  classHeader: Observable<string>;
  classShowHeader: string;
  private _unsubscribeAll: Subject<any>;
  private isFirstLoadConversations = true;
  selectConversation: Conversation = null;

  constructor(private store: Store<AppState>,
              private conversationService: ConversationService,
              private router: Router,
              private notificationsService: NotificationsService,
              private messageService: MessageService,
              private userService: UserService,
              private afAuth: AngularFireAuth,
              private db: AngularFireDatabase,
              private ssrContext: SsrContext) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.classHeader = this.store.select(getClassHeader);
    this.isLoading = this.store.select(isLoadingConversationNavbarList);
    this.countConversation = this.store.select(getCountConversations);
    this.store.select(getAllConversationsNavbar)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(conversations => this.cloneConversations(conversations));

    this.store.select(getConversation)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(conversation => this.selectConversation = conversation);

    this.initCountConversation();
  }

  private cloneConversations(conversations: Conversation[]): void {
    conversations
      .forEach(conversation => {
        const newConversation = JSON.parse(JSON.stringify(conversation));
        this.conversationService.injectUserDest(newConversation, this.user.id);
        this.conversations.push(Object.assign({}, newConversation));
      });
  }

  loadConversation(): void {
    if (this.isFirstLoadConversations) {
      this.isFirstLoadConversations = false;
      this.store.dispatch(loadConversationsNavbar({conversationFilter: new ConversationFilter(this.user.id)}));
      this.checkChangeChatRoom();
    }
  }

  private checkChangeChatRoom(): void {
    this.conversationService.stateChangeAdded(this.user.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (value: any) => {
          const chatRoomFirebase = value.payload.child('/').val();
          const currentChatRoom = this.conversations.filter(chatRoom => chatRoom.key === chatRoomFirebase.key);

          if (currentChatRoom.length === 1) {
            currentChatRoom[0].lastMessageTimestamp = chatRoomFirebase.lastMessageTimestamp;
            currentChatRoom[0].lastMessage = chatRoomFirebase.lastMessage;
            currentChatRoom[0].unread = chatRoomFirebase.unread;
            this.conversations = this.conversations.sort((a, b) => b.lastMessageTimestamp.localeCompare(a.lastMessageTimestamp));
          } else {
            this.conversationService.injectUserDest(chatRoomFirebase, this.user.id);
            this.conversations.unshift(chatRoomFirebase);
          }
        },
        error: () => {
          const notification = new Notif({
            text: 'Impossible de se connecter au serveur de messagerie, veuillez recharger votre page',
            level: 'error',
            options: {timeout: 2}
          });
          this.notificationsService.addNotification(notification);
        }
      });
  }

  @HostListener('window:scroll', ['$event'])
  scrollPage(): void {
    if (window.scrollY > 70) {
      this.classShowHeader = 'inner-page';
    } else {
      this.classShowHeader = '';
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearConversations());
    this._unsubscribeAll.next({});
    this._unsubscribeAll.complete();
  }

  getName(usersDest: ListUser[]): string {
    return this.conversationService.getName(usersDest);
  }

  onScroll(event: any): void {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 70) {
      this.store.select(isLoadingConversationNavbarList).pipe(take(1))
        .pipe(
          filter(isLoading => !isLoading),
          switchMap(() => this.store.select(noMoreConversationNavbarInFirebase).pipe(take(1))),
          filter(noMoreMessage => !noMoreMessage),
          switchMap(() => this.store.select(getConversationNavbarFilter).pipe(take(1))),
        )
        .subscribe((conversationFilter: ConversationFilter) => {
          this.store.dispatch(loadConversationsNavbar(
            {conversationFilter: new ConversationFilter(this.user.id, conversationFilter.lastMessageTimeStamp)}
          ));
        });
    }
  }

  private initCountConversation(): void {
    if (!this.ssrContext.isServer()) {
      this.afAuth.authState.pipe(take(1)).subscribe(() => {
        this.db.database.app.auth().onAuthStateChanged(userDb => {
          if (userDb) {
            this.store.dispatch(countConversationsUnread({userId: this.user.id}));
          }
        });
      });
    }
  }

  openConversation(conversation: Conversation): void {
    this.conversationService.openConversation(conversation, this.selectConversation, this.user.id);
  }
}
