import {NotificationState} from './notification.reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const notificationFeatureSelector = createFeatureSelector<NotificationState>('notifications');

export const getAllNotifications = createSelector(
  notificationFeatureSelector,
  state => state.notifications
);

export const isLoadingNotificationList = createSelector(
  notificationFeatureSelector,
  state => state.isLoadingList
);

export const getNotificationFilter = createSelector(
  notificationFeatureSelector,
  state => state.notificationFilter
);

export const noMoreNotificationInFirebase = createSelector(
  notificationFeatureSelector,
  state => state.noMoreNotificationInFirebase
);

export const getCountNotification = createSelector(
  notificationFeatureSelector,
  state => state.count
);

