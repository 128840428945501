import {createReducer, on} from '@ngrx/store';
import {monetizationPositionActionTypes} from './monetization-position.actions';
import {EntityState} from '@ngrx/entity';
import { IMonetizationPosition } from '../../model/monetization/monetization-position.model';

export interface MonetizationPositionState extends EntityState<IMonetizationPosition> {
  isLoadingList: boolean;
  isLoadingCreate: boolean;
  isLoadingDelete: boolean;
  monetizationPositions: IMonetizationPosition[];
}

export const initialState = {
  isLoadingList: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  monetizationPositions: [],
};

function deleteOneMonetizationPositions(action, state): IMonetizationPosition[] {
  const currentMonetizationPositions: IMonetizationPosition[] = JSON.parse(JSON.stringify(state.monetizationPositions));
  return currentMonetizationPositions.filter(obj => obj.id !== action.monetizationPosition.id);
}
function addOneMonetizationPositions(action, state): IMonetizationPosition[] {
  const currentMonetizationPositions: IMonetizationPosition[] = JSON.parse(JSON.stringify(state.monetizationPositions));
  currentMonetizationPositions.push(action.monetizationPosition);
  return currentMonetizationPositions;
}

export const monetizationPositionReducer = createReducer(
  initialState,

  on(monetizationPositionActionTypes.loadMonetizationPositions, (state) => ({
    ...state,
    isLoadingList: true,
  })),

  on(monetizationPositionActionTypes.loadMonetizationPositionsDone, (state, action: any) => ({
    ...state,
    isLoadingList: false,
    monetizationPositions: action.monetizationPositions
  })),

  on(monetizationPositionActionTypes.loadMonetizationPositionsFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(monetizationPositionActionTypes.createMonetizationPosition, (state) => ({
    ...state,
    isLoadingCreate: true,
  })),

  on(monetizationPositionActionTypes.createMonetizationPositionDone, (state, action) => ({
    ...state,
    isLoadingCreate: false,
    monetizationPositions: addOneMonetizationPositions(action, state),
  })),

  on(monetizationPositionActionTypes.createMonetizationPositionFail, (state) => ({
      ...state,
      isLoadingCreate: false,
      isLoadingForm: []
    }
  )),

  on(monetizationPositionActionTypes.deleteMonetizationPosition, (state) => ({
    ...state,
    isLoadingDelete: true,
  })),

  on(monetizationPositionActionTypes.deleteMonetizationPositionDone, (state, action) => ({
    ...state,
    isLoadingDelete: false,
    monetizationPositions: deleteOneMonetizationPositions(action, state),
  })),

  on(monetizationPositionActionTypes.deleteMonetizationPositionFail, (state) => ({
      ...state,
      isLoadingDelete: false,
      isLoadingForm: []
    }
  )),

  on(monetizationPositionActionTypes.clearMonetizationPosition, () => ({
      ...initialState
    }
  )),
);
