import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../utils/form.utils';
import {OpinionService} from '../../../services/opinion.service';
import {Notif} from '../../../../core/model/notification/notif.model';
import {NotificationsService} from 'src/app/shared/services/util/notifications.service';

@Component({
  selector: 'app-opinion-form-component',
  templateUrl: './opinion-form.component.html',
  styleUrls: ['./opinion-form.component.scss'],
})
export class OpinionFormComponent {

  isSubmited = false;
  form;
  loading = false;
  @Output() output = new EventEmitter();

  constructor(protected fb: FormBuilder,
              private notificationsService: NotificationsService,
              private opinionService: OpinionService) {
    this.form = this.fb.group({
      opinion: ['', [Validators.required, Validators.maxLength(5000)]],
    })
  }

  showError(form: FormGroup, nameForm: string, errorCode?: string): boolean {
    return FormUtils.showError(form.get(nameForm), errorCode);
  }

  getClassBlocInput(form: FormGroup, nameForm: string, addClass?: string): string {
    return FormUtils.getClassBlocInput(form, nameForm, this.isSubmited, addClass);
  }

  sendOpinion(): void {
    this.isSubmited = true;

    if (!this.form.valid || this.loading) {
      return;
    }

    this.loading = true;

    this.opinionService.create(this.form.get('opinion').value).subscribe(() => {
      this.loading = false;
      this.isSubmited = false;
      this.form.get('opinion').setValue('');
      this.output.next('send');
      const notification = new Notif({
        text: 'Merci pour votre message',
        level: 'success',
        options: {timeout: 2}
      });
      this.notificationsService.addNotification(notification);
    });
  }
}
