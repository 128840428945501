import {ConversationState} from './conversation.reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const conversationFeatureSelector = createFeatureSelector<ConversationState>('conversations');

export const getAllConversations = createSelector(
  conversationFeatureSelector,
  state => state.conversations
);

export const getAllConversationsNavbar = createSelector(
  conversationFeatureSelector,
  state => state.conversationsNavbar
);

export const isLoadingConversationList = createSelector(
  conversationFeatureSelector,
  state => state.isLoadingList
);

export const isLoadingConversationNavbarList = createSelector(
  conversationFeatureSelector,
  state => state.isLoadingListNavbar
);

export const getConversationFilter = createSelector(
  conversationFeatureSelector,
  state => state.conversationFilter
);

export const getConversationNavbarFilter = createSelector(
  conversationFeatureSelector,
  state => state.conversationNavbarFilter
);

export const getConversation = createSelector(
  conversationFeatureSelector,
  state => state.conversation
);

export const getConversationLocal = createSelector(
  conversationFeatureSelector,
  state => state.conversationLocal
);

export const noMoreConversationInFirebase = createSelector(
  conversationFeatureSelector,
  state => state.noMoreConversationInFirebase
);

export const noMoreConversationNavbarInFirebase = createSelector(
  conversationFeatureSelector,
  state => state.noMoreConversationNavbarInFirebase
);

export const getCountConversations = createSelector(
  conversationFeatureSelector,
  state => state.count
);
