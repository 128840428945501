import {EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {messageActionTypes} from './message.actions';
import {MessageFilter} from '../../model/message/message-filter.model';
import {Message} from '../../model/message/message.model';

export interface MessageState extends EntityState<Message> {
  isLoadingList: boolean;
  messageFilter: MessageFilter;
  noMoreMessageInFirebase: boolean;
  messages: Message[];
}

export const initialState = {
  isLoadingList: false,
  messageFilter: new MessageFilter(),
  noMoreMessageInFirebase: false,
  messages: [],
};


function getMessageFilter(messageFilter: MessageFilter, messages: Message[]): MessageFilter {
  const newMessageFilter = new MessageFilter();
  if (messages.length > 0) {
    newMessageFilter.lastCreationDate = messages[messages.length - 1].creationDate;
    newMessageFilter.key = messageFilter.key;
  }
  return newMessageFilter;
}

function getMessages(action, state): Message[] {
  if (action.messages.length > 0) {
    const oldMessages = [...state.messages];
    const newMessages = [...action.messages];

    if (action.messages.length >= state.messageFilter.limitToLast) {
      newMessages.pop();
    }

    newMessages.forEach(messageRoom => {
      if (!oldMessages.find(oldMessage => oldMessage.id === messageRoom.id)) {
        oldMessages.push(messageRoom);
      }
    });
    return oldMessages;
  }
  return action.messages;
}

function addMessage(action, state): Message[] {
  const currentMessages = [...state.messages];
  if (action.message) {
    currentMessages.unshift(action.message);
  }
  return currentMessages;
}

export const messageReducer = createReducer(
  initialState,

  on(messageActionTypes.loadMessages, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.messageFilter
  })),

  on(messageActionTypes.messagesLoadedDone, (state, action) => ({
    ...state,
    isLoadingList: false,
    noMoreMessageInFirebase: action.messages.length < state.messageFilter.limitToLast,
    messageFilter: getMessageFilter(state.messageFilter, action.messages),
    messages: getMessages(action, state)
  })),

  on(messageActionTypes.messagesLoadedFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(messageActionTypes.clearMessage, () => ({
    ...initialState
  })),

  on(messageActionTypes.addMessage, (state, action) => ({
      ...state,
      messages: addMessage(action, state)
    }
  )),
);
