import {categoryActionTypes} from './category.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Category} from '../../model/category/category.model';
import {CategoryService} from '../../../shared/services/category.service';
import {TransferState} from '@angular/platform-browser';
import {STATE_LIST_CATEGORIES} from '../../transfert-state/state';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';

@Injectable()
export class CategoryEffects {

  loadCategories$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(categoryActionTypes.loadCategories),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_LIST_CATEGORIES, this.categoryService.query(action.filters))),
        map(categories => categoryActionTypes.loadCategoriesDone({categories})),
        catchError((err, caught) => {
          this.store.dispatch(categoryActionTypes.loadCategoriesFail());
          return caught;
        })
      );
    }
  );

  constructor(
    private categoryService: CategoryService,
    private actions$: Actions,
    private router: Router,
    private state: TransferState,
    private utilsTransfertsState: UtilsTransfertsState,
    private store: Store<Category>) {
  }
}
