import {createReducer, on} from '@ngrx/store';
import {followUserActionTypes} from './follow-user.actions';
import {FollowUser} from '../../model/follow-user/follow-user.model';
import {IFilterFollowUser} from '../../model/follow-user/filter-follow-user.model';

export interface FollowUserState {
  totalElements: number;
  totalPages: number;
  empty: boolean;
  page: number;
  number: number;
  filters: IFilterFollowUser;

  isLoadingListFollowUser: boolean;
  messageErrorFollowUser: string;
  followUsers: FollowUser[];
}

export const initialState = {
  isLoadingListFollowUser: false,
  messageErrorListFollowUser: '',
  followUsers: [],
  totalElements: null,
  totalPages: null,
  page: 0,
  number: 0,
  empty: true,
};

export const followUserReducer = createReducer(
  initialState,

  on(followUserActionTypes.loadFollowUser, (state, res) => ({
    ...state,
    isLoadingListFollowUser: true,
    filters: res.filters
  })),


  on(followUserActionTypes.loadFollowUserDone, (state, action: any) => ({
    ...state,
    isLoadingListFollowUser: false,
    totalElements: action.followUsers.totalElements,
    totalPages: action.followUsers.totalPages,
    empty: action.followUsers.empty,
    page: action.followUsers.number,
    followUsers: action.followUsers.content,
    messageErrorListFollowUser: ''
  })),

  on(followUserActionTypes.loadFollowUserFail, (state, action) => ({
      ...state,
      isLoadingListFollowUser: false,
      messageErrorListFollowUser: action.message
    }
  )),

  on(followUserActionTypes.clearFollowUsers, (state) => ({
      ...state,
      followUsers: [],
    }
  )),
);
