import {createAction, props} from '@ngrx/store';
import {Category} from '../../model/category/category.model';
import {FilterCategory} from '../../model/category/filter-category.model';
import {Page} from '../../model/page.model';


export const loadCategories = createAction(
  '[Categories List] Load categories via Service',
  props<{ filters: FilterCategory }>()
);

export const loadCategoriesDone = createAction(
  '[Categories Effect] Categories Loaded Successfully',
  props<{ categories: Page<Category> }>()
);

export const loadCategoriesFail = createAction(
  '[Categories Effect] Categories Loaded failed'
);

export const selectCategory = createAction(
  '[Category] Select Category ',
  props<{ category: Category }>()
);

export const categoryActionTypes = {
  loadCategories,
  loadCategoriesDone,
  loadCategoriesFail,
  selectCategory,
};
