<app-modal id="new-recipe-log-in">
  <div class="modal-content">
    <p class="title">Vous voulez créer une nouvelle recette ?</p>
    <p>Connectez-vous pour ajouter une nouvelle recette.</p>
  </div>
  <div class="modal-btn">
    <button type="button" class="link" (click)="closeModal('new-recipe-log-in');">Fermer</button>
    <a class="link-main" (click)="closeModalLogin('new-recipe-log-in');" [routerLink]="'/connexion'">Se connecter</a>
  </div>
</app-modal>

<div class="fake-header">

</div>

<header class="header {{classShowHeader}} {{classHeader | async}}">
  <div class="content-menu">

    <div class="left-menu">

      <div class="logo"><a [routerLink]="''"><img width="94" height="33" src="/assets/images/tooda_logo_full.svg?v=2.1" alt="Logo Tooda"></a></div>

      <div class="web-menu">
        <nav [classList]="showMenu ? 'active' : ''">
          <div appDropdown *ngFor="let item of navigation" class="dropdown menu-item {{disabled}}">
            <div class="dropdown-title" [classList]="location.path().startsWith(item.link) ? 'item selected' : 'item'">
              <div class="title-item">
                <span class="text-menu"><img src="/assets/images/home/icon-{{item.domain.title}}.svg" alt="icone {{item.domain.label}}"/>{{item.domain.label}}</span>
              </div>
            </div>
            <div class="dropdown-content list-sub-menu">

              <div class="header-dd">
                <div class="group-btn">
                  <a [routerLink]="item.link" [queryParams]="{domain : item.domain.title, properties : 'star', direction: 'desc'}" class="close btn btn-raised-main">Voir tout</a>
                  <a [routerLink]="item.link" [queryParams]="{domain : item.domain.title, properties : 'id', direction: 'desc'}"
                     class="close btn btn-menu">Nouveautés</a>
                </div>
              </div>

              <app-list-select-category-component [addClass]="'header'" [domain]="item.domain"></app-list-select-category-component>
            </div>
          </div>
        </nav>
      </div>

    </div>

    <div class="center-menu">
      <app-search-input-component class="search-component"></app-search-input-component>
    </div>

    <div class="right-menu center-vertically">
      <div class="new-recipe element-menu center-vertically">
        <a type="button" class="rotate-hover" [routerLink]="'/recette/creation'" [queryParams]="{step : 1}" *ngIf="user" appTooltip="Nouvelle recette" placement="bottom">
          <img src="/assets/images/header/plus-recipe.svg?v=3" alt="icone nouvelle recette" width="50" height="48"/>
        </a>
        <button (click)="openModal('new-recipe-log-in')" *ngIf="!user" class="btn btn-raised-main">
          <img src="/assets/images/header/new-recipe.svg?v=3" alt="icone nouvelle recette" height="23" width="17"/>
          Nouvelle Recette
        </button>
      </div>

      <app-nav-bar-notification-component *ngIf="user" [user]="user"
                                          class="element-menu center-vertically"></app-nav-bar-notification-component>

      <app-nav-bar-conversation-component *ngIf="user" [user]="user"
                                          class="element-menu center-vertically"></app-nav-bar-conversation-component>

      <app-nav-bar-user-component [user]="user"></app-nav-bar-user-component>

      <label class="menu-icon" (click)="toogleMenu()"><span class="navicon"></span></label>

    </div>
  </div>
</header>

<div [classList]="showMenu ? 'container-mobile-menu active' : 'container-mobile-menu'">
  <div class="mobile-menu">

    <a [routerLink]="'/connexion'" (click)="saveUrl()" *ngIf="!user" class="btn btn-raised-main">
      S'inscrire | Se connecter
    </a>

    <a [routerLink]="'/recette/creation'" [queryParams]="{step : 1}" class="btn new-recipe"
       *ngIf="user != null">
      Nouvelle Recette
    </a>

    <button (click)="openModal('new-recipe-log-in')" class="btn" *ngIf="user == null">
      Nouvelle Recette
    </button>

    <nav>
      <div *ngFor="let item of navigation; let index = index">
        <div class="title-menu {{location.path().startsWith(item.link) ? 'selected' : ''}}"
           (click)="toggleMobile(index)">
              <span>
                {{item.domain.label}}
              </span>
        </div>
        <div [className]="selectedIndexMenu == index ? 'list-sub-menu show-mobile' : 'list-sub-menu hide-mobile'">
          <div class="button-group">
            <a [routerLink]="item.link" [queryParams]="{domain : item.domain.title, properties : 'star', direction: 'desc'}" class="close btn btn-raised-main">Voir tout</a>
            <a [routerLink]="item.link" [queryParams]="{domain : item.domain.title, properties : 'id', direction: 'desc'}"
               class="close btn btn-menu">Nouveautés</a>
          </div>

          <app-list-select-category-component [domain]="item.domain"></app-list-select-category-component>
        </div>
      </div>
    </nav>
  </div>
</div>


<div [classList]="showMenu ? 'overlay-menu active' : 'overlay-menu'" (click)="toogleMenu()">

</div>

<button type="button" class="btn-go-to-up" *ngIf="showGoUp" (click)="goUp()">
  <span class="arrow"><</span>
</button>

<app-opinion-form-component [ngClass]="classShowpinionForm ? '' : 'hide'" (output)="classShowpinionForm = false" *ngIf="showOpinionForm"></app-opinion-form-component>

<button *ngIf="showOpinion | async" type="button" class="btn-opinion" (click)="showOpinionForm = true; classShowpinionForm = !classShowpinionForm">
  <svg xmlns="http://www.w3.org/2000/svg" width="17.529" height="17.529" viewBox="0 0 17.529 17.529">
    <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(-2 -1.586)">
      <path id="Tracé_11965" data-name="Tracé 11965" d="M24.813,3,16.5,11.313" transform="translate(-6.698)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Tracé_11966" data-name="Tracé 11966" d="M18.115,3l-5.29,15.115L9.8,11.313,3,8.29Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>

  Un avis sur Tooda ?
</button>


<div *ngIf="user && user.dateToDelete != null" class="user-waiting-delete">
  <div class="container">
    Votre compte sera supprimé le {{user.dateToDelete | date: 'dd/MM/yyyy'}}, vous pouvez annuler cette demande depuis
    <a [routerLink]="'/parametres'">cette page</a>
  </div>
</div>
