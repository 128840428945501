import {monetizationPositionActionTypes} from './monetization-position.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TransferState} from '@angular/platform-browser';
import {getErrorRequest} from '../../../shared/services/util/request-util';
import {MonetizationPosition} from '../../model/monetization/monetization-position.model';
import {MonetizationPositionService} from '../../../shared/services/monetization/monetization-position.service';

@Injectable()
export class MonetizationPositionEffects {

  loadMonetizationPositions$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(monetizationPositionActionTypes.loadMonetizationPositions),
        switchMap((action) => this.monetizationPositionService.query(action.filters)),
        map((monetizationPositions: MonetizationPosition[]) =>
          monetizationPositionActionTypes.loadMonetizationPositionsDone({monetizationPositions})
        ),
        catchError((err, caught) => {
          this.store.dispatch(monetizationPositionActionTypes.loadMonetizationPositionsFail());
          return caught;
        })
      );
    }
  );


  createMonetizationPosition$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(monetizationPositionActionTypes.createMonetizationPosition),
        switchMap((action) => this.monetizationPositionService.create(action.monetizationPosition)),
        map(monetizationPosition => monetizationPositionActionTypes.createMonetizationPositionDone({monetizationPosition})),
        catchError((err, caught) => {
          this.store.dispatch(monetizationPositionActionTypes.createMonetizationPositionFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  deleteMonetizationPosition$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(monetizationPositionActionTypes.deleteMonetizationPosition),
        switchMap((action) =>
          this.monetizationPositionService.delete(action.monetizationPosition).pipe(map(() => action.monetizationPosition))
        ),
        map(monetizationPosition => monetizationPositionActionTypes.deleteMonetizationPositionDone({monetizationPosition})),
        catchError((err, caught) => {
          this.store.dispatch(monetizationPositionActionTypes.deleteMonetizationPositionFail({message: getErrorRequest(err)}));
          return caught;
        })
      );
    }
  );

  constructor(
    private monetizationPositionService: MonetizationPositionService,
    private actions$: Actions,
    private router: Router,
    private state: TransferState,
    private store: Store<MonetizationPosition>) {
  }

}
