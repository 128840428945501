import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/home/home.module')
        .then((m) => m.HomeModule)
  },
  {
    path: 'utilisateur',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/user/user.module')
        .then((m) => m.UserModule)
  },
  {
    path: 'recette',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/recipe/recipes.module')
        .then((m) => m.RecipesModule)
  },
  {
    path: 'blog',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/blog/blogs.module')
        .then((m) => m.BlogsModule)
  },
  {
    path: 'ingredient',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/ingredients/ingredients.module')
        .then((m) => m.IngredientsModule)
  },
  {
    path: 'messages',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/message/messages.module')
        .then((m) => m.MessagesModule)
  },
  {
    path: 'connexion',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/auth/signin/signin.module')
      .then(module => module.SigninModule)
  },
  {
    path: 'inscription',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/auth/signup/signup.module')
      .then(module => module.SignupModule)
  },
  {
    path: 'action-email',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/auth/action-email/action-email.module')
      .then(module => module.ActionEmailModule)
  },
  {
    path: 'mot-de-passe-oublie',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/auth/password-reset/password-reset.module')
      .then(module => module.PasswordResetModule)
  },
  {
    path: 'complete-compte',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/auth/complete-account/complete-account.module')
      .then(module => module.CompleteAccountModule)
  },
  {
    path: 'verification-email',
    canActivate: [AuthGuard],
    data: {
      authorities: ['USER']
    },
    loadChildren: () => import('./features/auth/email-verification/email-verification.module')
      .then(module => module.EmailVerificationModule)
  },
  {
    path: 'tableau-de-bord',
    canActivate: [AuthGuard],
    data: {
      authorities: ['USER']
    },
    loadChildren: () => import('./features/dashboard/dashboard.module')
      .then(module => module.DashboardModule)
  },
  {
    path: '404',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/not-found/not-found.module').then(module => module.NotFoundModule)
  },
  {
    path: 'mes-favoris',
    canActivate: [AuthGuard],
    data: {
      authorities: ['USER']
    },
    loadChildren: () => import('./features/my-wish/my-wish.module').then(module => module.MyWishModule)
  },
  {
    path: 'parametres',
    canActivate: [AuthGuard],
    data: {
      authorities: ['USER']
    },
    loadChildren: () => import('./features/parameter/parameter.module').then(module => module.ParameterModule)
  },
  {
    path: 'recherche',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/search/search.module').then(module => module.SearchModule)
  },
  {
    path: 'mentions-legales',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/footer/legal-notice/legal-notice.module').then(module => module.LegalNoticeModule)
  },
  {
    path: 'politique-de-confidentialite',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/footer/privacy-policy/privacy-policy.module').then(module => module.PrivacyPolicyModule)
  },
  {
    path: 'conditions-generales-utilisation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/footer/cgu/cgu.module').then(module => module.CguModule)
  },
  {
    path: 'information-monetisation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/monetization/info-monetization.module').then(module => module.InfoMonetizationModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  // useHash supports gitHub.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
