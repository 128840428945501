import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loader-component',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @Input() bgColor = false;

  constructor() {

  }
}
