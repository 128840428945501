import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {Address} from '../../model/address/address';
import {FilterAddress} from '../../model/address/filter-address.model';
import {addressActionTypes} from './address.actions';

export interface AddressState extends EntityState<Address> {
  isLoadingList: boolean;
  isLoadingOne: boolean;
  isLoadingPatch: boolean;
  isLoadingCreate: boolean;
  isLoadingDelete: boolean;
  messageErrorLoadAddress: string;
  messageErrorLoadsAddress: string;
  messageErrorCreateAddress: string;
  messageErrorDeleteAddress: string;
  messageErrorPatchAddress: string;
  totalElements: number;
  totalPages: number;
  empty: boolean;
  page: number;
  filters: FilterAddress;
  address: Address;
  addresss: Address[];
}

export const adapter: EntityAdapter<Address> = createEntityAdapter<Address>({
  selectId: address => address.id
});

export const initialState = adapter.getInitialState({
  isLoadingList: false,
  isLoadingOne: false,
  isLoadingPatch: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  messageErrorLoadAddress: null,
  messageErrorLoadsAddress: null,
  messageErrorCreateAddress: null,
  messageErrorDeleteAddress: null,
  messageErrorPatchAddress: null,
  totalElements: null,
  totalPages: null,
  page: 0,
  empty: true,
  address: null,
  addresss: [],
});

export const addressReducer = createReducer(
  initialState,

  on(addressActionTypes.loadListAddress, (state, res) => ({
    ...state,
    isLoadingList: true,
    filters: res.filters,
    messageErrorLoadsAddress: null,
  })),

  on(addressActionTypes.addressListLoadedDone, (state, action) => ({
    ...state,
    isLoadingList: false,
    totalElements: action.addresss.totalElements,
    totalPages: action.addresss.totalPages,
    empty: action.addresss.empty,
    page: action.addresss.number,
    addresss: action.addresss.content,
  })),

  on(addressActionTypes.addressListLoadedFail, (state, action) => ({
      ...state,
      isLoadingList: false,
      isLoad: true,
      messageErrorDeleteRecipe: action.message
    }
  )),

  on(addressActionTypes.loadOneAddress, (state) => ({
    ...state,
    isLoadingOne: true,
    messageErrorLoadAddress: null,
  })),

  on(addressActionTypes.addressOneLoadedDone, (state, action) => ({
    ...state,
    address: action.address,
    isLoadingOne: false,
  })),

  on(addressActionTypes.addressOneLoadedFail, (state, action) => ({
    ...state,
    isLoadingOne: false,
    messageErrorLoadAddress: action.message
  })),

  on(addressActionTypes.createAddress, (state) => ({
    ...state,
    isLoadingCreate: true,
    messageErrorCreateAddress: null,
  })),

  on(addressActionTypes.addressCreatedDone, (state, action) => ({
    ...state,
    address: action.address,
    isLoadingCreate: false
  })),

  on(addressActionTypes.addressCreatedFail, (state, action) => ({
    ...state,
    isLoadingCreate: false,
    messageErrorCreateAddress: action.message
  })),

  on(addressActionTypes.deleteAddress, (state) => ({
    ...state,
    isLoadingDelete: true,
    messageErrorDeleteAddress: null,
  })),

  on(addressActionTypes.addressDeletedDone, (state) => ({
    ...state,
    isLoadingDelete: false
  })),

  on(addressActionTypes.addressDeletedFail, (state, action) => ({
    ...state,
    isLoadingDelete: false,
    messageErrorDeleteAddress: action.message
  })),

  on(addressActionTypes.patchAddress, (state) => ({
    ...state,
    isLoadingPatch: true,
    messageErrorPatchAddress: null,
  })),

  on(addressActionTypes.addressPatchDone, (state, action) => ({
    ...state,
    address: action.address,
    isLoadingPatch: false,
  })),

  on(addressActionTypes.addressPatchFail, (state, action) => ({
    ...state,
    isLoadingPatch: false,
    messageErrorPatchAddress: action.message
  })),

  on(addressActionTypes.clearAddress, () => ({
    ...initialState
  })),
);
