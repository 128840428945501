<div class="content-categories {{addClass}}" *ngIf="categories | async as currentCategories">
  <div class="bloc-category" *ngFor="let typeCategory of getTypeCategory(currentCategories); let index = index;">
    <div class="title-type-category {{selectedIndexTypeCategory == index ? 'selected' : ''}}">
      <span (click)="chooseCategory(getCategoriesByTypeCategory(currentCategories, typeCategory)[0])">{{typeCategory}}</span>
      <span (click)="toggleMobile(index)" class="arrow"><</span>
    </div>

    <div
      [className]="selectedIndexTypeCategory == index ? 'bloc-sub-category show-mobile' : 'bloc-sub-category hide-mobile'">
      <a class="link-category close {{isSelectedSubCategory(category, currentCategory | async)}}"
         *ngFor="let category of getCategoriesByTypeCategory(currentCategories, typeCategory)"
         [queryParams]="{ sousCategorie :  category.subCategory, categorie :  category.type}"
         [routerLink]="'/recette/liste/' + titleDomain"
         (click)="chooseSubCategory(category)">
        {{category.subCategoryLabel}}
      </a>
    </div>

    <div
      [className]="selectedIndexTypeCategory == index ? 'bloc-sub-category show-mobile' : 'bloc-sub-category hide-mobile'">
      <a class="link-category show-all {{isSelectedCategory(currentCategories, typeCategory, currentCategory | async)}}"
         [queryParams]="{categorie : getCategoriesByTypeCategory(currentCategories, typeCategory)[0].type}"
         [routerLink]="'/recette/liste/' + titleDomain"
         (click)="chooseCategory(getCategoriesByTypeCategory(currentCategories, typeCategory)[0])">
        <b class="close">> Voir tout</b>
      </a>
    </div>

  </div>
</div>
