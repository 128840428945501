import {createAction, props} from '@ngrx/store';
import {MessageFilter} from '../../model/message/message-filter.model';
import {Message} from '../../model/message/message.model';


export const loadMessages = createAction(
  '[Messages List] Load Messages via Service',
  props<{ messageFilter: MessageFilter }>()
);

export const messagesLoadedDone = createAction(
  '[Messages Effect] Messages Loaded Successfully',
  props<{ messages: Message[] }>()
);

export const messagesLoadedFail = createAction(
  '[Messages Effect] Messages Loaded failed'
);

export const clearMessage = createAction(
  '[Messages Effect] Clear all  store Messages'
);

export const addMessage = createAction(
  '[Messages Effect] Add Message',
  props<{ message: Message }>()
);

export const stateChangeMessage = createAction(
  '[Messages Effect] State change Message',
  props<{ key: string }>()
);

export const messageActionTypes = {
  loadMessages,
  messagesLoadedDone,
  messagesLoadedFail,
  clearMessage,
  stateChangeMessage,
  addMessage,
};
