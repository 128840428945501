import {FilterAlgoliaRequest} from './algolia-filter-request.model';

export interface IAlgoliaFilter {
  requests?: FilterAlgoliaRequest[];
  strategy?: string;
}

export class AlgoliaFilter implements IAlgoliaFilter {
  constructor(
    public requests?: FilterAlgoliaRequest[],
    public strategy?: string,
  ) {
  }
}
