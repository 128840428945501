<form #autoComplete (ngSubmit)="search()" [formGroup]="form" class="auto-complete {{classShowHeader}} {{classHeader | async}}" appAutoComplete>

  <div class="icon-search">
    <img src="/assets/images/search/search-black.svg?v=2" alt="Afficher la barre de rechercher" loading="lazy"/>
  </div>

  <div class="input-search">
    <div class="icon-return close" (click)="clear()">
      <img src="/assets/images/search/arrow.svg?v=2" alt="Ferme la bar de recherche" (click)="blur()"/>
    </div>

    <div class="bloc-input">
      <label>
        <input #inputSearch class="input" placeholder="Je recherche une recette etc..." autocomplete="off" formControlName="search">
      </label>
    </div>

    <div class="element-menu center-vertically">
      <div class="cross {{form.get('search').value ? 'show' : 'hide'}}" (click)="clear()">x</div>
      <button type="submit" class="link">
        <img src="/assets/images/search/search.svg?v=2" width="45" height="45" loading="lazy" class="close" alt="rechercher"/>
      </button>
    </div>
  </div>

  <div class="auto-complete-content">
    <div *ngIf="!form.get('search').value">
      <a class="item close search" *ngFor="let history of histories" [routerLink]="'recherche'"
         (click)="clear()"
         [queryParams]="{search : history}">
        <div class="img"><img src="/assets/images/search/history_icon.svg?v=2" alt="History icon"></div>
        <div class="title">{{history}}</div>
        <div class="cross" (click)="deleteHistory($event, history)">x</div>
      </a>
    </div>

    <a class="item close" *ngFor="let hit of (hitsObservable | async)" [routerLink]="hit.url" (click)="setHistorySearchLocalStorage(hit.title)">
      <div class="img-search-item">
        <img *ngIf="hit.picture && hit.picture != ''" class="logo-search {{hit.index}}" [src]="hit.picture"
             alt="Picture {{hit.index}}"/>
        <div *ngIf="!hit.picture || hit.picture == ''"
             class="logo-search {{hit.index}} content color-{{hit.title.charCodeAt(0) % 5}}">
          {{hit.title ? hit.title.charAt(0).toUpperCase() : 'A'}}
        </div>
      </div>

      <div class="title">{{hit.title}}</div>
      <div class="icon"><img src="/assets/images/search/icon-{{hit.index}}.svg?v=2" alt="Icone {{hit.index}}"></div>
    </a>

    <a class="item close search" (click)="setHistorySearchLocalStorage(form.get('search').value)" *ngIf="form.get('search').value" [routerLink]="'recherche'"
       [queryParams]="{search : form.get('search').value}">
      <div class="img"><img src="/assets/images/search/search-black.svg?v=2" alt="Search icon" loading="lazy"></div>
      <div class="title">Rechercher {{form.get('search').value}}</div>
    </a>
  </div>
</form>
