import {Component, Input} from '@angular/core';
import {Notif} from '../../../../core/model/notification/notif.model';
import {NotificationsService} from '../../../services/util/notifications.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() notification: Notif;

  constructor(private _notificationsService: NotificationsService) {
  }

  pause(): void {
    this.notification.paused.next(true);
  }

  unPause(): void {
    this.notification.paused.next(false);
  }

  remove(): void {
    this._notificationsService.removeNotification(this.notification.id);
  }

}
