import {Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

import {ModalService} from '../../services/util/modal.service';
import {SsrContext} from '../../services/util/ssr-context.service';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() lock = false;
  private readonly element: any;
  classCss = '';

  constructor(private modalService: ModalService,
              private el: ElementRef,
              private ssrContext: SsrContext) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (this.ssrContext.isServer()) {
      return;
    }

    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    document.body.appendChild(this.element);

    this.element.addEventListener('mousedown', el => {
      if (el.target.className === 'app-modal' && el.button === 0 && !this.lock) {
        this.close();
      }
    });

    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  open(size?: number): void {
    this.element.style.display = 'block';
    document.body.classList.add('app-modal-open');
    this.classCss = '';
    if (size) {
      this.classCss = 'width-' + size;
    }
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('app-modal-open');
  }
}
