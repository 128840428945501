<div class="streamer" *ngIf="showStreamer | async">
  <div class="left">
    <img src="/assets/images/footer/registration.svg" width="44" height="28" alt="Icone La communauté de Tooda t'attends !">
    <div class="text">
      <b>La communauté de Tooda t'attends !</b> Découvre de nouvelles recettes fait maison et créer tes propres recettes !
    </div>
  </div>
  <div class="right">
    <a class="btn btn-raised-white" [routerLink]="'/connexion'">Je  m'inscris</a>
    <img src="/assets/images/footer/cross.svg" width="35" height="35" alt="Fermer le bandeau" (click)="closeStreamer()">
  </div>
</div>

<footer>
  <picture *ngIf="showBg | async">
    <source srcset="/assets/images/footer/footer-bg.webp?v=2" type="image/webp">
    <source srcset="/assets/images/footer/footer-bg.png?v=2" type="image/png">
    <img src="/assets/images/footer/footer-bg.webp?v=2" class="footer-bg" loading="lazy" alt="Background footer" width="1920" height="428">
  </picture>
  <div class="socials-network">
    <a href="https://www.facebook.com/toodadiy" target="_blank">
      <img src="/assets/images/footer/facebook.svg?v=2" loading="lazy" alt="Facebook">
    </a>
    <a href="https://www.instagram.com/tooda_diy" target="_blank">
      <img src="/assets/images/footer/instagram.svg?v=2" loading="lazy" alt="Instagram">
    </a>
    <a href="https://twitter.com/tooda_diy" target="_blank">
      <img src="/assets/images/footer/twitter.svg?v=2" loading="lazy" alt="Twitter">
    </a>
  </div>

  <div class="pages">
    <div class="bloc-link-dot">
      <a [routerLink]="'information-monetisation'">
        Monétisation
      </a>
      <div class="dot"></div>
    </div>

    <div class="bloc-link-dot">
      <a [routerLink]="'blog/liste'">
        Blog
      </a>
      <div class="dot"></div>
    </div>

    <div class="bloc-link-dot">
      <a [routerLink]="'/ingredient'">
        Répertoire des ingrédients
      </a>
      <div class="dot"></div>
    </div>

    <div class="bloc-link-dot">
      <a [routerLink]="'conditions-generales-utilisation'">
        Conditions Générales d'Utilisation
      </a>
      <div class="dot"></div>
    </div>

    <div class="bloc-link-dot">
      <a [routerLink]="'mentions-legales'">
        Mentions Légales
      </a>
      <div class="dot"></div>
    </div>

    <div class="bloc-link-dot">
      <a [routerLink]="'politique-de-confidentialite'">
        Politique de confidentialité
      </a>
      <div class="dot"></div>
    </div>

<!--    <div class="bloc-link-dot">-->
<!--      <a href="https://tooda.atlassian.net/servicedesk/customer/portals" target="_blank">-->
<!--        Nous contacter <img src="/assets/images/footer/mail.svg?v=2" width="15" height="12" loading="lazy" class="icon-contact" loading="lazy" alt="Nous contacter">-->
<!--      </a>-->
<!--    </div>-->
  </div>

  <div class="copyright">
    © {{getCurrentYear()}} - Tooda
  </div>
</footer>

